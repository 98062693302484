import React, { useState, useMemo } from "react";
import Text from "../../../languages/Text";
import { H1, H3 } from "../../../components/TextStyles";
import { useAuth } from "../../../contexts/AuthContext";
import { Grid } from "../../../components/Grid";
import { inviteSupplier } from "../../../firebase";
import { Button } from "../../../components/Button";
import InviteModal from "../../../components/InviteModal";
import useFetch from "../../../hooks/useFetch";
import { fetchRepresentatives, fetchInvites, addInvitee } from "./data";
import SupplierCard from "./SupplierCard";
import { Loader } from "../../../components/Loader";
import { CircleButton } from "../../../components/CircleButton";
import Modal, { ModalHeader, ModalContent, ModalFooter } from "../../../components/Modal";
import { Body, UtilBar, Page, Header } from "../../../components/Layout/Layout";

const Suppliers = () => {
  const { currentUser } = useAuth();
  const { uid: userID } = currentUser;
  const args = useMemo(() => ({ userID }), [userID]);
  const [isInviting, setInviting] = useState(false);
  const [supplierEmail, setSupplierEmail] = useState("");
  const { data: representatives, isLoading } = useFetch(fetchRepresentatives, args);
  const { data: invitesSent } = useFetch(fetchInvites, args);

  const [isSendingInvite, setIsSendingInvite] = useState(false);
  const [isShowingInvitations, setIsShowingInvitations] = useState(false);

  // function onInvite() {
  //   // console.log("sending invitie");
  //   setIsSendingInvite(true);
  //   inviteRepresentative({ supplierID: userID, supplierEmail }).then(() => {
  //     setInviting(false);
  //     setSupplierEmail("");
  //     setIsSendingInvite(false);
  //   });
  // }

  function onInvite() {
    handleInviteSupplier()
      .then(() => addInvitee({ userID, supplierEmail }))
      .then(() => window.location.reload());
    // setIsSendingInvite(true);
    // inviteRepresentative({ supplierID: userID, restaurantEmail }).then(() => {
    //   setInviting(false);
    //   setRestaurantEmail("");
    //   setIsSendingInvite(false);
    // });
  }

  function handleInviteSupplier() {
    const restaurantID = currentUser.uid;
    return inviteSupplier({ restaurantID, supplierEmail }).then((smth) => {
      setInviting(false);
      setSupplierEmail("");
    });
  }

  function openInvitationsPopup() {
    setIsShowingInvitations(true);
  }

  return (
    <Page>
      <Header>
        <div>
          <H1>
            <Text vid={"common"} tid={"suppliers"} capitalize={true} />
          </H1>
        </div>
        <div style={{ display: "flex" }}>
          <Button onClick={() => setInviting(true)}>
            <Text vid={"suppliers"} tid={"addSupplier"} />
          </Button>
          <div
            style={{ display: "flex", alignItems: "center", marginLeft: "2rem", cursor: "pointer" }}
            onClick={openInvitationsPopup}
          >
            <CircleButton style={{ color: "var(--orange)" }}>
              <H3>{invitesSent.length}</H3>
            </CircleButton>
            <H3 color="white" style={{ marginLeft: "0.5rem" }}>
              <Text vid={"common"} tid={"invited"} capitalize={true} />
            </H3>
          </div>
        </div>
      </Header>
      <UtilBar></UtilBar>
      <Body>
        {isLoading ? (
          <Loader />
        ) : (
          <Grid midSize>
            {representatives.representativeIDs.map((repID) => (
              <SupplierCard
                key={repID}
                supplierData={{ ...representatives[repID], representativeID: repID }}
              />
            ))}
            {isInviting && (
              <InviteModal
                inviteType="supplier"
                closeModal={() => setInviting(false)}
                input={supplierEmail}
                setInput={setSupplierEmail}
                onInvite={onInvite}
                isLoading={isSendingInvite}
              />
            )}
            {isShowingInvitations && (
              <Modal onClose={() => setIsShowingInvitations(false)} width="36rem">
                <ModalHeader>
                  <H1>Invitations Sent</H1>
                </ModalHeader>
                <ModalContent>
                  {invitesSent &&
                    invitesSent.map((invite) => (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <H3 color="var(--blue)">{invite.supplierEmail}</H3>
                        <p>{invite.timestamp.toDate().toLocaleDateString(navigator.language)}</p>
                        {/* <Icon width="1.25rem" height="1.25rem">
                          {trashIcon}
                        </Icon> */}
                        {/* <IconButton width="1.25rem" height="1.25rem">
                          {trashIcon}
                        </IconButton> */}
                      </div>
                    ))}
                </ModalContent>
                <ModalFooter></ModalFooter>
              </Modal>
            )}
          </Grid>
        )}
      </Body>
    </Page>
  );
};

export default Suppliers;
