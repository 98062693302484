import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Orders from "../Orders";
import Order from "../Order";
import Analytics from "../Analytics";
import Settings from "../Settings";
import Restaurants from "../Restaurants";
import CatalogueRepresentative from "./Catalogue";
import Notifications from "../../components/Notifications/index.js";
import { useProfile } from "../../contexts/ProfileContext";
import NavbarEl, { NavLinks, StyledLink } from "../../components/Layout/Navbar";
import { Layout } from "../../components/Layout/Layout";
import { MobileNav } from "../../components/Layout/MobileNav";
import { MobileNavBottom } from "../../components/Layout/MobileNavBottom";
import Text from "../../languages/Text";
import { Icon } from "../../components/Icon";
import {
  ordersIcon,
  catalogueIcon,
  restaurantsIcon,
  analyticsIcon,
  settingsIcon,
  fuduramaLogo,
} from "../../assets/icons";
import { useMediaQuery } from "react-responsive";
import PrivateRoute from "../../components/PrivateRoute";

const RepresentativeApp = () => {
  const { profileData } = useProfile();
  const isMobile = useMediaQuery({
    query: "(max-width: 860px)",
  });

  return (
    <Layout>
      {!isMobile ? (
        <NavbarEl>
          <StyledLink to="/orders">
            {ordersIcon}
            <p>
              <Text vid={"common"} tid={"orders"} capitalize={true} />
            </p>
          </StyledLink>
          <StyledLink to="/restaurants">
            {restaurantsIcon}
            <p>
              <Text vid={"common"} tid={"restaurants"} capitalize={true} />
            </p>
          </StyledLink>
          <StyledLink to={`/catalogue/${profileData.supplierID}`}>
            {catalogueIcon}
            <p>Catalogue</p>
          </StyledLink>
          <StyledLink to="/analytics">
            {analyticsIcon}
            <p>
              <Text vid={"common"} tid={"analytics"} capitalize={true} />
            </p>
          </StyledLink>
          <StyledLink to="/settings">
            {settingsIcon}
            <p>
              <Text vid={"common"} tid={"settings"} capitalize={true} />
            </p>
          </StyledLink>
        </NavbarEl>
      ) : (
        <MobileNav>
          {fuduramaLogo}
          <Notifications />
        </MobileNav>
      )}
      {!isMobile && <Notifications />}

      <Switch>
        <Route exact path="/orders">
          <Orders />
        </Route>
        <Route path="/restaurants">
          <Restaurants />
        </Route>
        <Route path="/analytics">
          <Analytics />
        </Route>
        <Route path="/settings">
          <Settings />
        </Route>
        <Route path="/catalogue/:supplierID">
          <CatalogueRepresentative isMobile={isMobile} />
        </Route>
        <Route path="/orders/order/:orderID">
          <Order />
        </Route>
        <Route>
          <Redirect to="/orders" />
        </Route>
      </Switch>

      {isMobile && (
        <MobileNavBottom>
          <NavLinks isOpen>
            <StyledLink to="/orders">
              <Icon width="1.75rem" height="1.75rem">
                {ordersIcon}
              </Icon>
              <p>
                <Text vid={"common"} tid={"orders"} capitalize={true} />
              </p>
            </StyledLink>
            <StyledLink to="/restaurants">
              <Icon width="1.75rem" height="1.75rem">
                {restaurantsIcon}
              </Icon>
              <p>
                <Text vid={"common"} tid={"restaurants"} capitalize={true} />
              </p>
            </StyledLink>
            <StyledLink to={`/catalogue/${profileData.supplierID}`}>
              <Icon width="1.75rem" height="1.75rem">
                {catalogueIcon}
              </Icon>
              <p>Catalogue</p>
            </StyledLink>
            <StyledLink to="/analytics">
              <Icon width="1.75rem" height="1.75rem">
                {analyticsIcon}
              </Icon>
              <p>
                <Text vid={"common"} tid={"analytics"} capitalize={true} />
              </p>
            </StyledLink>
            <StyledLink to="/settings">
              <Icon width="1.75rem" height="1.75rem">
                {settingsIcon}
              </Icon>
              <p>
                <Text vid={"common"} tid={"settings"} capitalize={true} />
              </p>
            </StyledLink>
          </NavLinks>
        </MobileNavBottom>
      )}
    </Layout>
  );
};

export default RepresentativeApp;
