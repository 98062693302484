import React, { useContext } from "react";
import { languageOptions } from "../languages";
import { LanguageContext } from "../contexts/LanguageContext";
import { Select } from "./Select";

export default function LanguageSelector() {
  const { userLanguage, userLanguageChange } = useContext(LanguageContext);

  // set selected language by calling context method
  const handleLanguageChange = (e) => userLanguageChange(e.target.value);

  return (
    <Select onChange={handleLanguageChange} value={userLanguage} style={{ marginRight: "1rem" }}>
      {Object.entries(languageOptions).map(([id, name]) => (
        <option key={id} value={id}>
          {name}
        </option>
      ))}
    </Select>
  );
}
