import React, { useState, useEffect } from "react";
import Modal, { ModalHeader, ModalFooter } from "../../../components/Modal";
import styled from "styled-components";
import { Button } from "../../../components/Button";
import { H1 } from "../../../components/TextStyles";
import { TextInput, TextareaInput } from "../../../components/TextInput";
import Text from "../../../languages/Text";
import { v4 as uuidv4 } from "uuid";

const ModalContent = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  @media (max-width: 600px) {
    flex-direction: column;
  }

  > div {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`;

export default function FileUploadModal({ onClose, setUploadedCatalogue }) {
  const [tempUploadedCatalogue, setTempUploadedCatalogue] = useState({});

  const convertCSVToCatalogue = (str, delim = ",") => {
    const tempCatalogue = {};
    const rows = str.slice(str.indexOf("\n") + 1).split("\r\n");
    rows.forEach((row) => {
      row = row.split(",");
      const ID = uuidv4();
      const item = {
        id: row[0],
        name: row[1],
        description: "",
        price: row[2],
        unit: row[3],
        category: row[4],
        promotion: false,
        active: "",
      };
      tempCatalogue[ID] = item;
    });
    console.log("temp", tempCatalogue);
    setTempUploadedCatalogue(tempCatalogue);
  };

  function processFile(e) {
    const file = e.target.files[0];
    console.log("file", file);
    const reader = new FileReader();
    reader.onload = function (e) {
      const text = e.target.result;
      convertCSVToCatalogue(text);
    };
    reader.readAsText(file);
  }

  return (
    <Modal width="30rem" onClose={onClose}>
      <ModalHeader>
        <H1>Upload file</H1>
      </ModalHeader>
      <ModalContent>
        <div>
          <input type="file" onChange={(e) => processFile(e)} />
        </div>
      </ModalContent>
      <ModalFooter>
        <Button
          onClick={() => {
            setUploadedCatalogue(tempUploadedCatalogue);
            onClose();
          }}
        >
          <Text vid={"common"} tid={"parse file"} />
        </Button>
        <Button color="var(--orange)" onClick={onClose}>
          <Text vid={"common"} tid={"cancel"} />
        </Button>
      </ModalFooter>
    </Modal>
  );
}
