import React, { useState } from "react";
import { withRouter, Link, useHistory, useLocation } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import { RegisterModal } from "./RegisterModal";
import { Form } from "./Form";
import { fuduramaLogo } from "../../assets/icons";
import { RegisterButton } from "./RegisterButton";
import { createSupplierDB, addRestaurant } from "../../firestore";
import { addUserType } from "../../firebase";
import { SignupLinks, StyledLink } from "./SignupLinks";
import { H1, H2 } from "../../components/TextStyles";
import { Icon } from "../../components/Icon";
import { TextInput, TextareaInput, NumberInput } from "../../components/TextInput";

export default function SignupSupplier(props) {
  const [fullname, setFullname] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [supplierName, setSupplierName] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  function findFieldErrors() {
    let tempErrors = {};
    if (fullname.length === 0) tempErrors.fullname = "Cannot be empty";
    if (supplierName.length === 0) tempErrors.supplierName = "Cannot be empty";
    if (password.length === 0) tempErrors.password = "Cannot be empty";
    if (email.length === 0) tempErrors.email = "Cannot be empty";

    setErrors(tempErrors);
    if (Object.keys(tempErrors).length === 0) return false;
    else return true;
  }

  const { signup } = useAuth();
  const history = useHistory();
  const query = new URLSearchParams(useLocation().search);

  function handleSubmit(e) {
    e.preventDefault();
    if (!findFieldErrors()) {
      setLoading(true);
      const restaurantID = query.get("resID");
      // if (!supplierID) alert("unable to create account");

      const userData = {
        userEmail: email,
        userFullName: fullname,
        userField: supplierName,
      };
      let resData = {};
      signup(email, password)
        .then((cred) => {
          const { uid: userID } = cred.user;
          userData.userID = userID;
          resData = {
            representativeID: userID,
            restaurantID,
            fullName: fullname,
            email: email,
            supplierID: userID,
            supplierName,
          };
          return createSupplierDB(userData);
        })
        .then(() => {
          if (restaurantID) addRestaurant(resData);
        })
        .catch((error) => {
          if (error.code === "auth/invalid-email") setErrors({ email: "Invalid email" });
          if (error.code === "auth/email-already-in-use")
            setErrors({ email: "Email already registered" });
          if (error.code === "auth/weak-password")
            setErrors({ password: "Password must be at least 6 characters" });
          setLoading(false);
          return Promise.reject(error);
        })
        .then(() => addUserType({ email, userType: "type-sup" }))
        .then(() => history.push("/signin"))
        .catch((err) => console.error("no good", err));
    }
  }

  return (
    <>
      <RegisterModal>
        <SignupLinks>
          <StyledLink to="/signup-restaurant">
            <H2>Restaurant</H2>
          </StyledLink>
          <StyledLink to="/signup-supplier">
            <H2>Supplier</H2>
          </StyledLink>
        </SignupLinks>
        <div style={{ position: "absolute", top: "2rem" }}>
          <a href="https://www.fudurama.com/">
            {/* <Icon width="8rem" style={{ margin: "-4rem auto 8rem auto" }}> */}
            <Icon width="8rem" style={{ margin: "auto" }}>
              {fuduramaLogo}
            </Icon>
          </a>
        </div>
        <div style={{ height: "5rem" }}></div>
        <Form id="signup-form" className="auth-form" onSubmit={handleSubmit}>
          <H1>Sign up</H1>
          <TextInput
            sharp
            error={errors.fullname}
            label="Full Name"
            value={fullname}
            placeholder="Full Name"
            onChange={(e) => {
              setFullname(e.target.value);
            }}
          />
          <TextInput
            sharp
            error={errors.supplierName}
            label="Supplier Name"
            value={supplierName}
            placeholder="Supplier Name"
            onChange={(e) => {
              setSupplierName(e.target.value);
            }}
          />
          <TextInput
            sharp
            error={errors.email}
            label="Email"
            value={email}
            placeholder="Email"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          <TextInput
            sharp
            password
            error={errors.password}
            label="Password"
            value={password}
            placeholder="Password"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />

          <RegisterButton type="submit" value="Sign Up" disabled={isLoading} />
          <p style={{ fontSize: "14px" }}>
            Already have an account?
            <Link style={{ fontWeight: "bold" }} to="/signin">
              {" "}
              Sign in
            </Link>
          </p>
        </Form>
      </RegisterModal>
    </>
  );
}
