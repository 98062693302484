import styled from "styled-components";

export const Layout = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;

  @media (max-width: 860px) {
    /* padding: 4rem 0; */
    /* margin: 4rem 0; */
    flex-direction: column;
  }
`;

export const Header = styled.div`
  padding: 2rem;
  /* min-height: 6rem; */
  grid-area: header;
  display: flex;
  align-items: center;
  color: var(--blue);
  flex-wrap: wrap;
  > :first-child {
    margin-right: 2rem;
  }
  @media (max-width: 860px) {
    min-height: fit-content;
    flex-direction: column;
    align-items: flex-start;
    padding: 1.5rem 1rem;
    > :nth-child(2) {
      margin-top: 1rem;
    }
  }
`;

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  /* padding: 0 2rem; */
  @media (max-width: 860px) {
    padding: 4rem 0;
  }
`;

export const Body = styled.div`
  overflow: scroll;
  height: 100%;
`;

export const UtilBar = styled.div`
  margin: 0 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px inset white;
  /* box-shadow: 0px 3px 1px var(--super-faded-blue); */

  > :last-child {
    margin-bottom: 1.5rem;
  }

  @media (max-width: 860px) {
    margin: 0 1rem;
    flex-direction: column;
    > :nth-child(2) {
      margin-top: 1rem;
    }
  }
`;

export const BodyFooter = styled.div`
  padding: 1rem;
  padding-top: 0;
  /* height: 4rem; */
  @media (max-width: 600px) {
    margin-bottom: 1rem;
  }
`;
