import styled from "styled-components";

export const MobileNav = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  position: fixed;
  top: 0;
  width: 100%;
  height: 4rem;
  padding: 0 1rem;
  z-index: 1;
  box-shadow: 0 2px 2px #000e663d;
  > svg {
    width: 4rem;
    height: 4rem;
  }
  /* grid-area: navBottom; */
  /* height: 4rem; */
  /* height: 100vh; */
`;
