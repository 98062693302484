import styled from "styled-components";
import { NavLink } from "react-router-dom";

export const SignupLinks = styled.nav`
  display: flex;
  position: absolute;
  top: -3rem;
  > * {
    margin: 0 2rem;
  }
  /* margin: 2rem auto;
  width: fit-content;
  > * {
    margin: 12px 48px;
  }

  @media (max-width: 600px) {
    width: 100%;
    margin: auto;
    > * {
      margin: auto;
    }
  } */
`;

export const StyledLink = styled(NavLink)`
  > h2 {
    font-weight: 400;
  }

  &.active {
    > h2 {
      font-weight: bold;
    }
    > svg {
      fill: #3a54b1;
    }
  }
`;
