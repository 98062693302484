import { useState, useEffect } from "react";

export default function useFetch(fetchFunction, functionArgs) {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    fetchFunction(functionArgs)
      .then((data) => {
        setData(data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.error("Error getting data", err);
      });
  }, [fetchFunction, functionArgs]);
  return { isLoading, data };
}

// getDoc(`suppliers/${currentUser.uid}/private/representatives`)
//   .then((doc) => {
//     const representatives = doc.data();
//     const representativeIDs = doc.get("representativeIDs");
//     return Promise.all(
//       representativeIDs.map(async (repID) => {
//         const repName = representatives[repID].fullName;
//         const repDoc = await getDoc(`representatives/${repID}/private/orders`);
//         const representativeOrdersData = repDoc.data();
//         delete representativeOrdersData["orderIDs"];
//         const repOrdersArr = Object.keys(representativeOrdersData).map((orderID) => {
//           let temp = representativeOrdersData[orderID];
//           temp.representativeName = repName;
//           return temp;
//         });
//         return repOrdersArr;
//       })
//     );
//   })
// .catch((err) => console.log("err", err))

// getOrders(typeTranslate(userType), currentUser.uid)
//   .then((doc) => {
//     const data = doc.data();
//     let temp = { ...data };
//     delete temp["orderIDs"];
//     let newOrders = Object.keys(temp).map((ID) => {
//       return { orderID: ID, ...data[ID] };
//     });
//     setOrders(sortArray(newOrders, "timestamp"));
//     setIsLoading(false);
//   })
// .then((repOrders) => {
//   const allOrders = repOrders.reduce((acc, repDoc) => acc.concat(repDoc));
//   setOrders(sortArray(allOrders, "timestamp"));
//   setIsLoading(false);
// })
// .catch((err) => {
//   setIsLoading(false);
//   console.error("Error getting restaurants", err);
// });

// if (userType === "type-sup") {
//   fetchOrdersSupplier(currentUser.uid)
//     .then((data) => {
//       setData(data);
//       setIsLoading(false);
//     })
//     .catch((err) => {
//       setIsLoading(false);
//       console.error("Error getting data", err);
//     });
// } else {
//   fetchOrders(currentUser.uid, userType)
//     .then((orders) => {
//       setData(orders);
//       setIsLoading(false);
//     })
//     .catch((err) => {
//       console.error("Error getting data", err);
//       setIsLoading(false);
//     });
// }

// function typeTranslate(userType) {
//   if (userType === "type-res") return "restaurants";
//   if (userType === "type-sup") return "suppliers";
//   if (userType === "type-rep") return "representatives";
// }

// function fetchOrdersSupplier(userID) {
//   return getDoc(`suppliers/${userID}/private/representatives`).then((doc) => {
//     const representatives = doc.data();
//     const representativeIDs = doc.get("representativeIDs");
//     return Promise.all(
//       representativeIDs.map(async (repID) => {
//         const repName = representatives[repID].fullName;
//         const repDoc = await getDoc(`representatives/${repID}/private/orders`);
//         const representativeOrdersData = repDoc.data();
//         delete representativeOrdersData["orderIDs"];
//         const repOrdersArr = Object.keys(representativeOrdersData).map((orderID) => {
//           let temp = representativeOrdersData[orderID];
//           temp.representativeName = repName;
//           return temp;
//         });
//         return repOrdersArr;
//       })
//     )
//       .then((repOrdersArr) => repOrdersArr.reduce((acc, repDoc) => acc.concat(repDoc)))
//       .then((rawOrders) => sortArray(rawOrders, "timestamp"));
//   });
// }

// function fetchOrders(userID, userType) {
//   getOrders(typeTranslate(userType), userID)
//     .then((doc) => {
//       const data = doc.data();
//       let temp = { ...data };
//       delete temp["orderIDs"];
//       let newOrders = Object.keys(temp).map((ID) => {
//         return { orderID: ID, ...data[ID] };
//       });
//       return newOrders;
//     })
//     .then((rawOrders) => sortArray(rawOrders, "timestamp"));
// }
