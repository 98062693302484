import styled from "styled-components";

export const Icon = styled.i`
  cursor: pointer;
  display: flex;
  align-items: center;
  width: ${(props) => props.width || "1.5rem"};
  height: ${(props) => props.height || "1.5rem"};
  > svg {
    margin: auto;
    width: ${(props) => props.width || "auto"};
    height: ${(props) => props.height || "auto"};
    fill: ${(props) => props.fill || "auto"};
  }
`;
