import React, { useState, useEffect } from "react";
import Modal, { ModalHeader, ModalContent, ModalFooter } from "../../../components/Modal";
import { Button } from "../../../components/Button";
import { H1 } from "../../../components/TextStyles";
import { useAuth } from "../../../contexts/AuthContext";
import { useProfile } from "../../../contexts/ProfileContext";
import { createCampaign } from "../../../firestore";
import { TextInput, TextareaInput } from "../../../components/TextInput";
import Text from "../../../languages/Text";

export default function NewCampaignModal({ restaurants, onClose }) {
  const { currentUser } = useAuth();
  const { uid: userID } = currentUser;
  const { profileData } = useProfile();
  const { supplierName } = profileData;

  const [campaignName, setCampaignName] = useState("");
  const [campaignText, setCampaignText] = useState("");
  const [recipients, setRecipients] = useState({});

  function onCreateCampaign() {
    const campaignData = {
      supplierID: userID,
      campaignName,
      campaignText,
      supplierName,
      recipients,
    };
    createCampaign(campaignData)
      .then(() => onClose())
      .then(() => window.location.reload());
  }

  function generateRestaurants() {
    const { restaurantIDs } = restaurants;
    const allRestaurantsSelected = Object.keys(recipients).length === restaurantIDs.length;
    const allRestaurants = [
      <div
        style={{
          borderBottom: "1px solid var(--super-faded-blue)",
          padding: "0.5rem 0",
          display: "flex",
        }}
      >
        <input
          type="checkbox"
          checked={Object.keys(recipients).length === restaurantIDs.length}
          id="all"
          onChange={(e) => {
            if (allRestaurantsSelected) {
              setRecipients({});
            } else {
              let temp = {};
              restaurantIDs.forEach((resID) => {
                temp[resID] = restaurants[resID].restaurantName;
              });
              setRecipients(temp);
            }
          }}
        />
        <label
          style={{ marginLeft: "1rem", fontWeight: "700", fontSize: "1rem", lineHeight: "1rem" }}
          htmlFor="all"
        >
          <Text vid={"campaign"} tid={"selectAll"} capitalize />
        </label>
      </div>,
    ];
    return allRestaurants.concat(
      restaurantIDs.map((resID) => {
        const restaurant = restaurants[resID];
        const { restaurantName } = restaurant;
        return (
          <div
            key={resID}
            style={{
              borderBottom: "1px solid var(--super-faded-blue)",
              padding: "0.5rem 0",
              display: "flex",
            }}
          >
            <input
              type="checkbox"
              checked={resID in recipients}
              id={resID}
              onChange={(e) => {
                if (resID in recipients) {
                  setRecipients((prev) => {
                    const p = { ...prev };
                    delete p[resID];
                    return p;
                  });
                } else {
                  setRecipients((prev) => {
                    const p = { ...prev };
                    p[resID] = restaurantName;
                    return p;
                  });
                }
              }}
            />
            <label
              style={{
                marginLeft: "1rem",
                fontWeight: "700",
                fontSize: "1rem",
                lineHeight: "1rem",
              }}
              htmlFor={resID}
            >
              {restaurantName}
            </label>
          </div>
        );
      })
    );
  }

  return (
    <Modal onClose={onClose}>
      <ModalHeader>
        <H1 capitalize>
          <Text vid={"campaign"} tid={"createNewCampaign"} />
        </H1>
      </ModalHeader>
      <ModalContent>
        <TextInput
          // error={errors.id}
          label={Text({ vid: "campaign", tid: "campaignName", capitalize: true })}
          value={campaignName}
          // placeholder="Product ID"
          onChange={(e) => setCampaignName(e.target.value)}
        />
        <TextareaInput
          // error={errors.description}
          label={Text({ vid: "campaign", tid: "campaignText", capitalize: true })}
          value={campaignText}
          onChange={(e) => setCampaignText(e.target.value)}
        />

        <p>
          <Text vid={"campaign"} tid={"selectRestaurants"} capitalize />:
        </p>

        <div
          style={{
            height: "12rem",
            overflow: "scroll",
            border: "1px solid #0000001f",
            borderRadius: "1rem",
            padding: "1rem",
          }}
        >
          {restaurants && generateRestaurants()}
        </div>
      </ModalContent>

      <ModalFooter>
        <Button type="submit" onClick={onCreateCampaign} disabled={!campaignName || !campaignText}>
          <Text vid={"campaign"} tid={"createCampaign"} />
        </Button>
      </ModalFooter>
    </Modal>
  );
}
