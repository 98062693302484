// import sortArray from "../../utils/sort";
import { getDoc, updateField, addInvitedSupplier } from "../../../firestore";
// import { inviteRepresentative } from "../../firebase";

export function fetchRepresentatives({ userID }) {
  return getDoc(`restaurants/${userID}/private/representatives`).then((doc) => doc.data());
}

export function fetchInvites({ userID }) {
  return getDoc(`restaurants/${userID}/private/invites`).then((doc) => doc.data().invites);
}

export function addInvitee({ userID, supplierEmail }) {
  return addInvitedSupplier({ restaurantID: userID, supplierEmail });
}
