import React from "react";
import { Card, CardFooter, CardHeader } from "../../components/Layout/Card";
import Text from "../../languages/Text";
import { H2, H3, H4 } from "../../components/TextStyles";
import styled from "styled-components";
import { formatPrice } from "../../utils/misc";
import { useMediaQuery } from "react-responsive";

const OrderTableCard = styled(Card)`
  padding: 1.5rem;
  padding-bottom: 0;
  /* height: 46rem; */
  height: 73vh;
  min-height: 40rem;
  /* height: 40%; */
  /* height: 90%; */
  flex: 2;
  /* overflow-x: scroll; */

  @media (max-width: 860px) {
    flex: none;
    height: fit-content;
    min-height: 0;
  }
`;

const OrderHeaderStyled = styled.div`
  color: var(--blue);
  border-bottom: 1px solid var(--blue);
  padding-bottom: 1rem;
  display: flex;
  min-width: 40rem;
  @media (max-width: 860px) {
    min-width: 0;
  }
`;

const OrderTableStyled = styled.div`
  color: var(--blue);
  overflow-y: scroll;
  border-bottom: 1px solid var(--blue);
  height: 100%;

  @media (max-width: 860px) {
    height: auto;
    /* overflow: auto; */
    /* padding: 1rem 0; */
  }
`;

const OrderTableRow = styled.div`
  display: flex;
  padding: 1rem 0;
  min-width: 40rem;
  border-bottom: 1px solid var(--super-faded-blue);

  @media (max-width: 860px) {
    min-width: 0;
  }
`;

const OrderTableCell = styled.div`
  display: flex;
  justify-content: ${(props) => props.align};
  width: ${(props) => props.width};
  min-width: ${(props) => props.minWidth};
`;

const OrderHeader = ({ isMobile }) => {
  if (isMobile) {
    return (
      <OrderHeaderStyled>
        <OrderTableCell width="35%">
          <H3 accent>
            <Text vid={"common"} tid={"name"} capitalize={true} />
          </H3>
        </OrderTableCell>
        <OrderTableCell width="35%" align="flex-start">
          <H3 accent>Price / Unit</H3>
        </OrderTableCell>

        <OrderTableCell width="10%" align="center">
          <H3 accent>
            <Text vid={"order"} tid={"quantity"} capitalize={true} />
          </H3>
        </OrderTableCell>
        <OrderTableCell width="20%" align="flex-end">
          <H3 accent>Total</H3>
        </OrderTableCell>
      </OrderHeaderStyled>
    );
  } else {
    return (
      <OrderHeaderStyled>
        <OrderTableCell width="10%" minWidth="4rem">
          <H3 accent> ID</H3>
        </OrderTableCell>
        <OrderTableCell width="40%" minWidth="16rem">
          <H3 accent>
            <Text vid={"common"} tid={"name"} capitalize={true} />
          </H3>
        </OrderTableCell>
        <OrderTableCell width="15%" minWidth="6rem" align="flex-start">
          <H3 accent>
            <Text vid={"catalogue"} tid={"price"} capitalize={true} />
          </H3>
        </OrderTableCell>
        <OrderTableCell width="15%" minWidth="6rem" align="flex-start">
          <H3 accent>
            <Text vid={"catalogue"} tid={"unit"} capitalize={true} />
          </H3>
        </OrderTableCell>
        <OrderTableCell width="10%" minWidth="4rem" align="center">
          <H3 accent>
            <Text vid={"order"} tid={"quantity"} capitalize={true} />
          </H3>
        </OrderTableCell>
        <OrderTableCell width="10%" minWidth="4rem" align="flex-end">
          <H3 accent>
            <Text vid={"common"} tid={"total"} capitalize={true} />
          </H3>
        </OrderTableCell>
      </OrderHeaderStyled>
    );
  }
};

const OrderProduct = ({ product, isMobile }) => {
  const { id, name, price, quantity, unit, promotion, promotionPrice } = product;
  const totalPrice = parseFloat(price) * quantity;

  if (isMobile) {
    return (
      <OrderTableRow>
        <OrderTableCell width="35%">
          <H4>{name}</H4>
        </OrderTableCell>
        <OrderTableCell width="35%" align="flex-start">
          <H4>{`${price} / ${unit}`}</H4>
        </OrderTableCell>
        <OrderTableCell width="10%" align="center">
          <H4>{quantity}</H4>
        </OrderTableCell>
        <OrderTableCell width="20%" align="flex-end">
          <H4>{`${formatPrice(totalPrice)}`}</H4>
        </OrderTableCell>
      </OrderTableRow>
    );
  } else {
    return (
      <OrderTableRow>
        <OrderTableCell width="10%" minWidth="4rem">
          <H4>{id}</H4>
        </OrderTableCell>
        <OrderTableCell width="40%" minWidth="12rem">
          <H4>{name}</H4>
        </OrderTableCell>
        <OrderTableCell width="15%" minWidth="6rem" align="flex-start">
          <H4>{`${formatPrice(price)}`}</H4>
        </OrderTableCell>
        <OrderTableCell width="15%" minWidth="6rem" align="flex-start">
          <H4>{unit}</H4>
        </OrderTableCell>
        <OrderTableCell width="10%" minWidth="4rem" align="center">
          <H4>{quantity}</H4>
        </OrderTableCell>
        <OrderTableCell width="10%" minWidth="4rem" align="flex-end">
          <H4>{`${formatPrice(totalPrice)}`}</H4>
        </OrderTableCell>
      </OrderTableRow>
    );
  }
};

export default function OrderTable({ products, cost }) {
  const isMobile = useMediaQuery({
    query: "(max-width: 860px)",
  });
  return (
    <OrderTableCard>
      <OrderHeader isMobile={isMobile} />
      <OrderTableStyled>
        {Object.keys(products).map((prodID) => {
          return <OrderProduct isMobile={isMobile} key={prodID} product={products[prodID]} />;
        })}
      </OrderTableStyled>
      <CardFooter>
        <div></div>
        <H3 style={{ padding: "2.25rem 0", fontWeight: "400" }}>
          <Text capitalize vid={"order"} tid={"totalSum"} />
          <span style={{ fontWeight: "500", marginLeft: "1rem" }}>€{formatPrice(cost)}</span>
        </H3>
      </CardFooter>
    </OrderTableCard>
  );
}

// const OrderHeader = () => {
//   return (
//     <OrderHeaderStyled>
//       <OrderTableColumn width="15%" minWidth="6rem">
//         <H4 accent>Product ID</H4>
//       </OrderTableColumn>
//       <OrderTableColumn width="35%">
//         <H4 accent>
//           <Text vid={"common"} tid={"name"} capitalize={true} />
//         </H4>
//       </OrderTableColumn>
//       <OrderTableColumn width="20%">
//         <H4 accent>
//           <Text vid={"order"} tid={"quantity"} capitalize={true} />
//         </H4>
//       </OrderTableColumn>
//       <OrderTableColumn width="20%" style={{ textAlign: "right" }}>
//         <H4 accent>
//           <Text vid={"order"} tid={"cost"} capitalize={true} />
//         </H4>
//       </OrderTableColumn>
//     </OrderHeaderStyled>
//   );
// };

// const OrderProduct = ({ product }) => {
//   const { id, name, price, quantity, unit, promotion, promotionPrice } = product;

//   return (
//     <div style={{ color: "var(--blue)", padding: "0.5rem 0" }}>
//       <OrderTableColumn width="15%" minWidth="6rem">
//         <H4
//           style={{
//             width: "100%",
//             textOverflow: "ellipsis",
//             overflow: "hidden",
//             whiteSpace: "nowrap",
//           }}
//         >
//           {id}
//         </H4>
//       </OrderTableColumn>
//       <OrderTableColumn width="25%" minWidth="10rem">
//         <H4>{name}</H4>
//       </OrderTableColumn>
//       <OrderTableColumn width="20%">
//         <H4>{`${quantity} ${unit}`}</H4>
//       </OrderTableColumn>
//       <OrderTableColumn width="20%" style={{ textAlign: "right" }}>
//         <H4>{`${formatPrice(parseFloat(price) * quantity)}`}</H4>
//       </OrderTableColumn>
//     </div>
//   );
// };

// export default function OrderTable({ products, quantity, cost }) {
//   return (
//     <OrderTableCard>
//       <OrderHeader />
//       <OrderTableStyled>
//         {Object.keys(products).map((prodID) => {
//           return <OrderProduct key={prodID} product={products[prodID]} />;
//         })}
//       </OrderTableStyled>
//       {/* <CardFooter style={{ borderTop: "1px solid var(--super-faded-blue)" }}>
//         <H2 style={{ margin: "auto", padding: "2rem", fontSize: "2rem" }}>
//           <Text capitalize vid={"order"} tid={"totalSum"} />

//           {`: €${formatPrice(cost)}`}
//         </H2>
//       </CardFooter> */}
//     </OrderTableCard>
//   );
// }
