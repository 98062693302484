import es from "./es.json";
import en from "./en.json";
import it from "./it.json";
import ro from "./ro.json";

export const dictionaryList = { en, es, it, ro };

export const languageOptions = {
  en: "English",
  es: "Español",
  it: "Italiano",
  // ro: "Română",
};
