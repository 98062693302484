import styled from "styled-components";

export const Button = styled.button`
  border-radius: 1.5rem;
  background-color: ${(props) =>
    props.disabled ? "#E9E9E9" : props.backgroundColor ? props.backgroundColor : "white"};
  box-shadow: ${(props) => (props.disabled ? "none" : "0px 1px 3px var(--faded-blue)")};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  color: ${(props) => (props.disabled ? "not-allowed" : props.color ? props.color : "var(--blue)")};
  font-size: 0.875rem;
  line-height: 0.875rem;
  height: 3rem;
  /* padding: 0.7rem 1.5rem; */
  padding: 0rem 1.5rem;
  border: none;
  text-transform: uppercase;
  font-weight: bold;
  outline: none;
  display: flex;
  align-items: center;
  max-width: fit-content;
  white-space: nowrap;

  &:hover {
    box-shadow: ${(props) =>
      props.disabled
        ? "not-allowed"
        : "0px 1px 1px rgba(63, 92, 180, 0.3), 0px 0px 2px rgba(63, 92, 180, 0.3)"};
  }

  &:active {
    box-shadow: inset 0px 0px 4px rgba(63, 92, 180, 0.75);
  }
`;
