import React from "react";
import styled from "styled-components";
import { FlexRow } from "../FlexRow";
import { H3 } from "../TextStyles";
import { Icon } from "../Icon";
import Notification from "./Notification";
import { closeIcon } from "../../assets/icons";
import Text from "../../languages/Text";

const NotificationsPopupStyled = styled.div`
  width: 26rem;
  height: 38rem;
  position: relative;
  top: 2rem;
  right: 0;
  background-color: white;
  overflow: scroll;
  box-shadow: 0px 20px 80px #3f5cb43d;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  z-index: 15;
  /* @media (max-width: 600px) {
    width: 90%;
    right: 1rem;
  } */
`;

const NotificationsContent = styled.div`
  flex: 1;
  overflow: scroll;
  /* width: 30rem;
  height: 40rem;
  position: absolute;
  right: 2rem;
  background-color: white;
  overflow: scroll; */
`;

export default function NotificationsPopup({
  closePopup,
  notifications,
  lastTimeNotificationsOpened,
}) {
  return (
    // <OutsideClickHandler
    //   onOutsideClick={() => {
    //     closePopup();
    //   }}
    // >
    <NotificationsPopupStyled>
      <FlexRow
        spaceBetween
        style={{ padding: "1rem", borderBottom: "2px solid var(--faded-blue)" }}
      >
        <H3 color="var(--faded-blue)">
          <Text vid="notification" tid="notifications" capitalize />
        </H3>
        <Icon fill={"var(--super-faded-blue)"} onClick={closePopup}>
          {closeIcon}
        </Icon>
      </FlexRow>
      <NotificationsContent onClick={closePopup}>
        {notifications &&
          notifications.map((notification) => (
            <Notification
              key={notification.timestamp.toString()}
              notificationData={notification}
              lastTimeNotificationsOpened={lastTimeNotificationsOpened}
            />
          ))}
      </NotificationsContent>
    </NotificationsPopupStyled>
    // </OutsideClickHandler>
  );
}
