import styled from "styled-components";
import React, { useState, useEffect } from "react";
import Text from "../languages/Text";
import { H1, H2, H3, H4, H5 } from "./TextStyles";
import { Icon } from "./Icon";
import { filterIcon } from "../assets/icons";
import { Button } from "./Button";

export const FilterStyled = styled.button`
  background-color: white;
  border-radius: ${(props) => (props.isOpen ? "1.4rem 1.4rem 0 0" : "1.4rem")};
  width: 14rem;
  height: 3rem;
  padding: 0.5rem 1.5rem;
  text-align: center;
  display: flex;
  align-items: center;
  outline: none;
  border: none;
  cursor: pointer;
  position: relative;
  justify-content: space-between;
  box-shadow: var(--basic-shadow);
  /* z-index: 10; */
`;

export const FilterOpened = styled.div`
  position: absolute;
  background-color: white;
  width: 100%;
  top: 2.5rem;
  left: 0;
  border-radius: 0 0 1.4rem 1.4rem;
  /* z-index: 10; */
  box-shadow: var(--basic-shadow);
  text-align: start;
  padding: 0.5rem 1.5rem;
  display: flex;
  flex-direction: column;
  cursor: auto;
  > label {
    margin: 0.5rem 0;
  }
`;

export function Filter({ preClickText, postClickText, options, setOptions }) {
  const [isClicked, setIsClicked] = useState(false);

  const handleClick = () => {
    if (isClicked) {
      setIsClicked(false);
    } else {
      setIsClicked(true);
    }
  };

  return (
    <FilterStyled isOpen={isClicked} onClick={handleClick}>
      <H4 color="var(--faded-blue)">{isClicked ? postClickText : preClickText}</H4>
      <Icon height="1rem" style={{ marginLeft: "1rem" }} fill="var(--faded-blue)">
        {filterIcon}
      </Icon>
      {isClicked && (
        <FilterOpened onClick={(e) => e.stopPropagation()}>
          <label>
            <input
              type="checkbox"
              checked={options["unplaced"]}
              onChange={() =>
                setOptions((current) => {
                  let temp = { ...current };
                  temp["unplaced"] = !current["unplaced"];
                  return temp;
                })
              }
            />{" "}
            <Text vid="order" tid="statusPending" capitalize />
          </label>

          <label>
            <input
              type="checkbox"
              checked={options["placed"]}
              onChange={() =>
                setOptions((current) => {
                  let temp = { ...current };
                  temp["placed"] = !current["placed"];
                  return temp;
                })
              }
            />{" "}
            <Text vid="order" tid="statusPlaced" capitalize />
          </label>

          {/* <label>
            <input
              type="checkbox"
              checked={options["completed"]}
              onChange={() =>
                setOptions((current) => {
                  let temp = { ...current };
                  temp["completed"] = !current["completed"];
                  return temp;
                })
              }
            />{" "}
            Completed
          </label> */}

          <label>
            <input
              type="checkbox"
              checked={options["cancelled"]}
              onChange={() =>
                setOptions((current) => {
                  let temp = { ...current };
                  temp["cancelled"] = !temp["cancelled"];
                  return temp;
                })
              }
            />{" "}
            <Text vid="order" tid="statusCancelled" capitalize />
          </label>
        </FilterOpened>
      )}
    </FilterStyled>
  );
}

export const DateFilter = ({ preClickText, postClickText, removeFilter, applyFilter }) => {
  const [isClicked, setIsClicked] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startDateError, setStartDateError] = useState();
  const [endDateError, setEndDateError] = useState();

  const handleClick = () => {
    if (isClicked) {
      setIsClicked(false);
    } else {
      setIsClicked(true);
    }
  };

  function checkErrors() {
    if (!startDate) {
      setStartDateError("Cannot be empty");
      return false;
    } else if (startDate && endDate && startDate > endDate) {
      setStartDateError("Cannot be later than end date");
      setEndDateError("Cannot be earlier than start date");
      return false;
    } else {
      setStartDateError();
      setEndDateError();
      return true;
    }
  }

  // useEffect(() => {

  // }, [startDate, endDate]);

  return (
    <FilterStyled isOpen={isClicked} onClick={handleClick} style={{ marginRight: "1rem" }}>
      <H4 color="var(--faded-blue)">{isClicked ? postClickText : preClickText}</H4>
      <Icon height="1rem" style={{ marginLeft: "1rem" }} fill="var(--faded-blue)">
        {filterIcon}
      </Icon>
      {isClicked && (
        <FilterOpened onClick={(e) => e.stopPropagation()}>
          <div style={{ marginBottom: "1rem" }}>
            <label style={{ fontSize: "0.875rem" }}>
              <Text vid="order" tid="enterStartDate" capitalize />
            </label>

            <input
              type="date"
              style={{
                fontFamily: "Commissioner",
                padding: "0.25rem",
                borderRadius: "8px",
                border: "1px solid var(--faded-blue)",
                maxWidth: "100%",
                fontSize: "0.875rem",
                marginTop: "0.25rem",
              }}
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
            {startDateError && (
              <p style={{ fontSize: "0.75rem", color: "red" }}>{startDateError}</p>
            )}
          </div>
          <div>
            <label style={{ fontSize: "0.875rem" }}>
              <Text vid="order" tid="enterEndDate" capitalize />
            </label>

            <input
              type="date"
              style={{
                fontFamily: "Commissioner",
                padding: "0.25rem",
                borderRadius: "8px",
                border: "1px solid var(--faded-blue)",
                maxWidth: "100%",
                fontSize: "0.875rem",
                marginTop: "0.25rem",
                // marginBottom: "1rem",
              }}
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
            {endDateError && <p style={{ fontSize: "0.75rem", color: "red" }}>{endDateError}</p>}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "0.5rem 0",
              paddingTop: "1rem",
            }}
          >
            <H5
              accent
              style={{ textTransform: "uppercase", color: "var(--orange)", cursor: "pointer" }}
              onClick={() => {
                removeFilter();
                setStartDate();
                setEndDate();
                setIsClicked(false);
                setStartDateError();
                setEndDateError();
              }}
            >
              <Text vid="common" tid="clear" capitalize />
            </H5>
            <H5
              accent
              style={{ textTransform: "uppercase", color: "var(--blue)", cursor: "pointer" }}
              onClick={() => {
                if (checkErrors()) {
                  endDate
                    ? applyFilter({
                        startDate: new Date(startDate + "T00:00:00"),
                        endDate: new Date(endDate + "T00:00:00"),
                      })
                    : applyFilter({ startDate: new Date(startDate + "T00:00:00") });
                  setIsClicked(false);
                }
              }}
            >
              <Text vid="common" tid="apply" capitalize />
            </H5>
          </div>
        </FilterOpened>
      )}
    </FilterStyled>
  );
};
