import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useProfile } from "../../contexts/ProfileContext";
import { useAuth } from "../../contexts/AuthContext";
import { setNotificationRead } from "../../firestore";
import { notificationsIcon } from "../../assets/icons";
import OutsideClickHandler from "react-outside-click-handler";
import NotificationsPopup from "./NotificationsPopup";

const NotificationsWrap = styled.div`
  position: fixed;
  top: 2rem;
  right: 2rem;
  z-index: 10;

  @media (max-width: 860px) {
    /* position: static; */
    top: 0.75rem;
    right: 1rem;
  }
`;

const NotificationIcon = styled.div`
  display: flex;
  justify-content: flex-end;
  /* padding: 0rem 2rem; */
  fill: var(--blue);
  position: relative;
  @media (max-width: 600px) {
    padding: 0;
  }
`;

const NotificationsCounter = styled.div`
  border-radius: 4rem;
  background-color: var(--orange);
  color: white;
  position: absolute;
  top: -3px;
  right: -6px;
  /* left: 0; */
  text-align: center;
  font-size: 0.875rem;
  line-height: 0.875rem;
  font-weight: bold;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default function Notifications() {
  const [isOpen, setOpen] = useState(false);
  const [unread, setUnread] = useState(0);
  const { notificationsData } = useProfile();
  const { currentUser, userType } = useAuth();
  const { notifications, lastTimeNotificationsOpened } = notificationsData;

  useEffect(() => {
    let count = 0;
    if (notifications && lastTimeNotificationsOpened) {
      notifications.forEach((notification) => {
        if (notification.timestamp > lastTimeNotificationsOpened) count += 1;
      });
    }
    setUnread(count);
  }, [notifications, lastTimeNotificationsOpened]);

  return (
    <NotificationsWrap>
      <NotificationIcon
        onClick={(e) => {
          if (!isOpen) {
            e.preventDefault();
            setOpen(true);
            if (unread > 0) {
              setUnread(0);

              const typeTranslation = {
                "type-res": "restaurants",
                "type-sup": "suppliers",
                "type-rep": "representatives",
              };
              setNotificationRead({
                collection: typeTranslation[userType],
                doc: currentUser.uid,
              }).catch((err) => console.error("Problem updating notificaiton", err));
            }
          } else {
            setOpen(false);
          }
        }}
      >
        {unread > 0 && <NotificationsCounter>{unread}</NotificationsCounter>}
        {notificationsIcon}
      </NotificationIcon>
      {isOpen && (
        <NotificationsPopup
          closePopup={() => {
            setOpen(false);
          }}
          notifications={notifications}
          lastTimeNotificationsOpened={lastTimeNotificationsOpened}
        />
      )}
    </NotificationsWrap>
  );
}
