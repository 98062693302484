import React from "react";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
} from "recharts";

export function SuppliersBarGraph({ suppliers_data }) {
  const data = Object.values(suppliers_data).map((supplier_data) => {
    const { name, orders, money } = supplier_data;
    return { name, orders, money };
  });
  return (
    <ResponsiveContainer width="99%" aspect={2}>
      <BarChart
        // width={500}
        // height={300}
        isAnimationActive={false}
        data={data}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <Legend />
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis yAxisId="left" orientation="left" stroke="var(--blue)" />
        <YAxis yAxisId="right" orientation="right" stroke="var(--orange)" />
        <Tooltip />
        <Bar yAxisId="left" dataKey="money" fill="var(--blue)" isAnimationActive={false} />
        <Bar yAxisId="right" dataKey="orders" fill="var(--orange)" isAnimationActive={false} />
      </BarChart>
    </ResponsiveContainer>
  );
}

export function SuppliersPieChart({ suppliers_data }) {
  // suppliers_data
  const supplier_money_data = Object.values(suppliers_data).map((supplier_data) => {
    const { name, money, money_percentage } = supplier_data;
    return { name, money_percentage: parseFloat(money_percentage), money };
  });
  // const supplier_orders_data = Object.values(suppliers_data).map((supplier_data) => {
  //   const { name, orders } = supplier_data;
  //   return { name, orders };
  // });
  // console.log(supplier_orders_data, supplier_money_data);

  return (
    <ResponsiveContainer width="99%" aspect={2}>
      <PieChart>
        <Pie
          data={supplier_money_data}
          dataKey="money_percentage"
          cx="50%"
          cy="50%"
          outerRadius="75%"
          fill="var(--blue)"
          label={({ name }) => name}
          isAnimationActive={false}
        />
        <Tooltip />
      </PieChart>
    </ResponsiveContainer>
  );
}
