import React, { useState } from "react";
import { Grid } from "../../components/Grid";
import { H1, H2, H3 } from "../../components/TextStyles";
import Product from "../../components/Catalogue/Product";
import { caretUp, caretDown } from "../../assets/icons";
import { Icon } from "../../components/Icon";
import styled from "styled-components";

const CategoryGridStyled = styled.div`
  padding: 0 2rem;
  @media (max-width: 860px) {
    padding: 0 1rem;
  }
`;

export default function CategoryGrid({ category, children, isOpen, setOpenCategory }) {
  // const [isOpen, setIsOpen] = useState(true);

  return (
    <CategoryGridStyled id={category}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "2rem 0",
          cursor: "pointer",
        }}
        onClick={() => (isOpen ? setOpenCategory() : setOpenCategory(category))}
      >
        <H2
          style={{
            color: "var(--blue)",
            textTransform: "capitalize",
          }}
        >
          {category}
        </H2>
        <Icon fill="var(--blue)">{isOpen ? caretUp : caretDown}</Icon>
      </div>
      {isOpen ? (
        <Grid noPad>{children}</Grid>
      ) : (
        <div
          style={{
            height: "1px",
            backgroundColor: "var(--super-faded-blue)",
            width: "100%",
          }}
        />
      )}
    </CategoryGridStyled>
  );
}
