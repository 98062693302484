import { getOrders, getDoc } from "../../firestore";
import { sortArray } from "../../utils/sort";
import { typeTranslate } from "../../utils/misc";

export function fetchOrders({ userID, userType }) {
  if (userType === "type-sup") {
    return getDoc(`suppliers/${userID}/private/representatives`).then((doc) => {
      const representatives = doc.data();
      const representativeIDs = doc.get("representativeIDs");
      return Promise.all(
        representativeIDs.map(async (repID) => {
          const repName = representatives[repID].fullName;
          const repDoc = await getDoc(`representatives/${repID}/private/orders`);
          const representativeOrdersData = repDoc.data();
          delete representativeOrdersData["orderIDs"];
          const repOrdersArr = Object.keys(representativeOrdersData).map((orderID) => {
            let temp = representativeOrdersData[orderID];
            temp.representativeName = repName;
            temp.representativeID = repID;
            return temp;
          });
          return repOrdersArr;
        })
      )
        .then((repOrdersArr) => repOrdersArr.reduce((acc, repDoc) => acc.concat(repDoc)))
        .then((rawOrders) => sortArray(rawOrders, "timestamp"));
    });
  } else {
    return getOrders(typeTranslate(userType), userID)
      .then((doc) => {
        const data = doc.data();
        let temp = { ...data };
        delete temp["orderIDs"];
        let newOrders = Object.keys(temp).map((ID) => {
          return { orderID: ID, ...data[ID] };
        });
        return newOrders;
      })
      .then((rawOrders) => sortArray(rawOrders, "timestamp"));
  }
}

// export function fetchOrdersSupplier(userID) {
//   return getDoc(`suppliers/${userID}/private/representatives`).then((doc) => {
//     const representatives = doc.data();
//     const representativeIDs = doc.get("representativeIDs");
//     return Promise.all(
//       representativeIDs.map(async (repID) => {
//         const repName = representatives[repID].fullName;
//         const repDoc = await getDoc(`representatives/${repID}/private/orders`);
//         const representativeOrdersData = repDoc.data();
//         delete representativeOrdersData["orderIDs"];
//         const repOrdersArr = Object.keys(representativeOrdersData).map((orderID) => {
//           let temp = representativeOrdersData[orderID];
//           temp.representativeName = repName;
//           return temp;
//         });
//         return repOrdersArr;
//       })
//     )
//       .then((repOrdersArr) => repOrdersArr.reduce((acc, repDoc) => acc.concat(repDoc)))
//       .then((rawOrders) => sortArray(rawOrders, "timestamp"));
//   });
// }

// export function fetchOrders(userID, userType) {
//   getOrders(typeTranslate(userType), userID)
//     .then((doc) => {
//       const data = doc.data();
//       let temp = { ...data };
//       delete temp["orderIDs"];
//       let newOrders = Object.keys(temp).map((ID) => {
//         return { orderID: ID, ...data[ID] };
//       });
//       return newOrders;
//     })
//     .then((rawOrders) => sortArray(rawOrders, "timestamp"));
// }
