import React, { useState } from "react";
import Modal, {
  ModalHeader,
  ModalContent,
  ModalFooter,
  ModalTextarea,
  ModalInput,
} from "../../components/Modal";
import { H1, H2, H3 } from "../../components/TextStyles";
import { Select } from "../../components/Select";
import { LabeledField } from "../../components/LabeledField";
import { FlexRow } from "../../components/FlexRow";
import { Button } from "../../components/Button";
import Text from "../../languages/Text";

export default function ChangeStatusModal({ onClose, onSubmit }) {
  const [newStatus, setNewStatus] = useState("");
  const [date, setDate] = useState();
  const [note, setNote] = useState("");

  return (
    <Modal width="36rem" onClose={onClose}>
      <ModalHeader>
        <H1 capitalize>
          <Text vid={"order"} tid={"updateStatus"} />
        </H1>
      </ModalHeader>
      <ModalContent>
        <div style={{ marginBottom: "2rem" }}>
          <H3 accent color="var(--blue)">
            <Text vid={"order"} tid={"selectStatus"} capitalize />
          </H3>

          <Select onChange={(e) => setNewStatus(e.target.value)} value={newStatus}>
            <option disabled value=""></option>
            <option value="placed">
              {Text({ vid: "order", tid: "statusPlaced", capitalize: true })}
            </option>
            <option value="cancelled">
              {Text({ vid: "order", tid: "statusCancelled", capitalize: true })}
            </option>
          </Select>
        </div>
        {newStatus !== "cancelled" && (
          <div style={{ marginBottom: "2rem" }}>
            <H3 accent color="var(--blue)">
              <Text vid={"order"} tid={"selectDeliveryDate"} capitalize />
              {" (optional)"}
            </H3>
            <ModalInput
              style={{ width: "fit-content" }}
              type="date"
              value={date}
              onChange={(e) => {
                setDate(e.target.value);
              }}
            />
          </div>
        )}

        <H3 accent color="var(--blue)">
          <Text vid={"order"} tid={"addNote"} capitalize />
          {" (optional)"}
        </H3>
        <ModalTextarea value={note} onChange={(e) => setNote(e.target.value)}></ModalTextarea>
      </ModalContent>
      <ModalFooter>
        <FlexRow style={{ width: "100%", justifyContent: "space-around" }}>
          <Button onClick={onClose} color="var(--orange)">
            <Text vid={"common"} tid={"cancel"} />
          </Button>
          <Button onClick={() => onSubmit({ note, newStatus, date })} disabled={!newStatus}>
            <Text vid={"common"} tid={"save"} />
          </Button>
        </FlexRow>
      </ModalFooter>
    </Modal>
  );
}
