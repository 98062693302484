import React from "react";
import Text from "../../languages/Text";
import {
  CardLink,
  CardHeader,
  CardFooter,
  CardContent,
  LinkText,
} from "../../components/Layout/Card";
import { H3, H4 } from "../../components/TextStyles";
import { useAuth } from "../../contexts/AuthContext";
import { LabeledField } from "../../components/LabeledField";
import { formatPrice, formatTime } from "../../utils/misc";
import { updateHasSeenOrder } from "../../firestore";

export default function OrderCard({ order }) {
  const {
    timestamp,
    supplierName,
    status,
    representativeName,
    representativeID,
    orderID,
    restaurantName,
    cost,
    quantity,
    unopened,
  } = order;
  const { currentUser, userType } = useAuth();
  const formattedTime = formatTime(timestamp);

  const CardContentBasedOnUser = (() => {
    if (userType === "type-res")
      return (
        <div>
          <H3 color="var(--blue)">{supplierName}</H3>
          <p>{representativeName}</p>
        </div>
      );
    else if (userType === "type-sup")
      return (
        <div>
          <H3 color="var(--blue)">{restaurantName}</H3>
          <p>{representativeName}</p>
        </div>
      );
    else if (userType === "type-rep")
      return (
        <div>
          <H3 color="var(--blue)">{restaurantName}</H3>
        </div>
      );
  })();

  const cardStatus = (() => {
    if (status === "unplaced")
      return (
        <H4 accent color="var(--blue)" style={{ textTransform: "uppercase" }}>
          <Text vid={"order"} tid={"statusPending"} />
        </H4>
      );
    else if (status === "placed")
      return (
        <H4 accent color="var(--green)" style={{ textTransform: "uppercase" }}>
          <Text vid={"order"} tid={"statusPlaced"} />
        </H4>
      );
    else if (status === "cancelled")
      return (
        <H4 accent color="var(--orange)" style={{ textTransform: "uppercase" }}>
          <Text vid={"order"} tid={"statusCancelled"} />
        </H4>
      );
  })();

  return (
    <CardLink
      to={{
        pathname: `/orders/order/${orderID}`,
      }}
      onClick={() => {
        if (unopened) {
          if (userType === "type-sup") updateHasSeenOrder(representativeID, orderID);
          else if (userType === "type-rep") updateHasSeenOrder(currentUser.uid, orderID);
          // updateHasSeenOrder(currentUser.uid, orderID);
        }
      }}
    >
      <CardHeader>
        <p>{formattedTime}</p>
        {/* <p style={{ width: "30%" }}>{orderID.substring(0, 5)}</p> */}

        {unopened && userType !== "type-res" ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                height: "0.9rem",
                width: "0.9rem",
                backgroundColor: "var(--orange)",
                borderRadius: "50%",
                marginRight: "0.5rem",
                boxShadow: "0 0 3px var(--orange)",
              }}
            ></div>
            <H4
              accent
              style={{
                textTransform: "uppercase",
                textAlign: "center",
                color: "var(--orange)",
              }}
            >
              <Text vid={"notification"} tid={"newOrder"} />
            </H4>
          </div>
        ) : (
          <>{cardStatus}</>
        )}
      </CardHeader>
      <CardContent>{CardContentBasedOnUser}</CardContent>
      <CardFooter>
        <H3 style={{ fontWeight: "500" }}>{cost ? `€${formatPrice(cost)}` : `€0.00`}</H3>
        {/* <LabeledField>
          <p>price</p>
          {cost ? <H4>€{formatPrice(cost)}</H4> : <H4>€300.00</H4>}
        </LabeledField>
        <LabeledField>
          <p>quantity</p>
          {quantity ? <H4>{quantity} products</H4> : <H4>39 products</H4>}
        </LabeledField>
        <div></div> */}
      </CardFooter>
    </CardLink>
  );
}
