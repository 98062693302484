import React, { useEffect } from "react";
import { useAuth } from "../../../contexts/AuthContext";
import { useHistory } from "react-router-dom";

export default function Logout() {
  const history = useHistory();
  const { signout } = useAuth();
  useEffect(() => {
    signout();
    history.push("/signin");
  });

  return <div></div>;
}
