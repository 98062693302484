import React from "react";
import { Button } from "../../../components/Button";
import Text from "../../../languages/Text";
// import { Row } from "../../../components/Row";
import { useMediaQuery } from "react-responsive";
import MobileButton from "../../../components/MobileButton";
import { Row } from "../../../components/Row";

export default function CatalogueButtons({ isEditable, functions }) {
  const { onSaveCatalogue, onCancelCatalogue, onAddItem, onMakeEditable, onUploadFile } = functions;
  const isMobile = useMediaQuery({
    query: "(max-width: 600px)",
  });
  return (
    <>
      {isEditable ? (
        <Row margin={"0.5em"}>
          <Button onClick={onSaveCatalogue} color="var(--green)">
            <Text vid={"catalogue"} tid={"saveCatalogue"} />
          </Button>
          {isMobile ? (
            <MobileButton onClick={onAddItem} />
          ) : (
            <>
              <Button onClick={onAddItem}>
                <Text vid={"catalogue"} tid={"addProduct"} />
              </Button>
              {/* <Button style={{ marginLeft: "0.5rem" }} onClick={onUploadFile}>
                <Text vid={"catalogue"} tid={"uploadFile"} />
              </Button> */}
            </>
          )}

          <Button color="var(--orange)" onClick={onCancelCatalogue}>
            <Text vid={"common"} tid={"cancel"} />
          </Button>
        </Row>
      ) : (
        <Button onClick={onMakeEditable}>
          <Text vid={"catalogue"} tid={"editCatalogue"} />
        </Button>
      )}
    </>
  );
}
