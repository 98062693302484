import React from "react";
import { FlexRow } from "../../components/FlexRow";
import styled from "styled-components";
import { formatPrice } from "../../utils/misc";
import { SuppliersBarGraph, SuppliersPieChart } from "./Charts";
import { H1, H2, H3 } from "../../components/TextStyles";
import { Card } from "../../components/Layout/Card";
import { GridBase } from "../../components/Grid";
import { Row } from "../../components/Row";
import Text from "../../languages/Text";

const OrdersData = styled.div`
  display: flex;
  /* justify-content: space-between; */
  width: 100%;
  margin-bottom: 1.5rem;
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;

const OrdersDataCard = styled.div`
  box-shadow: var(--card-shadow);
  background-color: white;
  border-radius: 1rem;
  /* width: fit-content; */
  height: 8rem;
  /* margin: 0 1rem; */
  padding: 1rem;
`;

const CardNumber = styled.p`
  color: var(--blue);
  font-weight: 400;
  font-size: 2.5rem;
`;

const CardLabel = styled.p`
  color: var(--faded-blue);
`;

const Table = styled.table`
  flex: 50%;
  background-color: white;
  box-shadow: var(--card-shadow);
  color: var(--blue);
  border-radius: 1rem;
  padding: 1rem;
  border-collapse: separate;
  border-spacing: 0 1rem;
  font-size: 1.125rem;
  thead tr th {
    border-bottom: 1px solid var(--super-faded-blue);
    border-collapse: separate;
    /* border-spacing: 500px 500px; */
  }
  tr {
  }
  th {
    /* font-size: 1.25rem; */
    /* text-align: left; */
  }
  td {
    /* border: 1px solid black; */
    text-align: center;
  }
`;

const ChartsWrap = styled.div`
  display: flex;
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;

function OrdersDashboard({ userType, orders_data }) {
  let {
    total_orders,
    total_money,
    total_quantity,
    pending_orders,
    accepted_orders,
    cancelled_orders,
  } = orders_data;
  total_money = formatPrice(total_money);
  return (
    <OrdersData>
      <Row margin="0.5rem" style={{ flex: "1" }}>
        <OrdersDataCard style={{ flex: 1 }}>
          <CardNumber>{total_orders}</CardNumber>
          <CardLabel>
            <Text vid={"analytics"} tid={"totalOrders"} />
          </CardLabel>
        </OrdersDataCard>
        <OrdersDataCard style={{ flex: 2 }}>
          <CardNumber>€{total_money}</CardNumber>
          <CardLabel>
            <Text vid={"analytics"} tid={"totalRevenue"} />
          </CardLabel>
        </OrdersDataCard>
      </Row>
      <div style={{ width: "1.5rem", height: "1.5rem" }}></div>
      <Row margin="1rem" style={{ flex: "1" }}>
        <OrdersDataCard style={{ flex: 1 }}>
          <CardNumber>{pending_orders}</CardNumber>
          <CardLabel>
            <Text vid={"analytics"} tid={"pendingOrders"} />
          </CardLabel>
        </OrdersDataCard>
        <OrdersDataCard style={{ flex: 1 }}>
          <CardNumber>{accepted_orders}</CardNumber>
          <CardLabel>
            <Text vid={"analytics"} tid={"confirmedOrders"} />
          </CardLabel>
        </OrdersDataCard>
        <OrdersDataCard style={{ flex: 1 }}>
          <CardNumber>{cancelled_orders}</CardNumber>
          <CardLabel>
            <Text vid={"analytics"} tid={"cancelledOrders"} />
          </CardLabel>
        </OrdersDataCard>
      </Row>
    </OrdersData>
  );
}

function SupplierRow({ supplier_data }) {
  const { name, money, orders, orders_percentage, money_percentage } = supplier_data;
  return (
    <tr>
      <td>{name}</td>
      <td>€{formatPrice(money)}</td>
      <td>{money_percentage}%</td>
      <td>{orders}</td>
      <td>{orders_percentage}%</td>
    </tr>
  );
}

function SuppliersDashboard({ userType, users_data }) {
  const tableHeadings = (() => {
    if (userType === "type-res")
      return (
        <tr>
          <th>
            <Text vid={"common"} tid={"supplier"} capitalize />
          </th>
          <th>
            <Text vid={"analytics"} tid={"revenue"} capitalize />
          </th>
          <th>
            <Text vid={"analytics"} tid={"totalRevenue"} capitalize />
            (%)
          </th>
          <th>
            <Text vid={"common"} tid={"orders"} capitalize />
          </th>
          <th>
            <Text vid={"analytics"} tid={"totalOrders"} capitalize />
            (%)
          </th>
        </tr>
      );
    else if (userType === "type-sup" || userType === "type-rep")
      return (
        <tr>
          <th>
            <Text vid={"common"} tid={"restaurant"} capitalize />
          </th>
          <th>
            <Text vid={"analytics"} tid={"revenue"} capitalize />
          </th>
          <th>
            <Text vid={"analytics"} tid={"totalRevenue"} capitalize />
            (%)
          </th>
          <th>
            <Text vid={"common"} tid={"orders"} capitalize />
          </th>
          <th>
            <Text vid={"analytics"} tid={"totalOrders"} capitalize /> (%)
          </th>
        </tr>
      );
  })();
  return (
    <Table>
      <thead>{tableHeadings}</thead>
      <tbody>
        {Object.keys(users_data).map((userID) => {
          return <SupplierRow key={userID} supplier_data={users_data[userID]} />;
        })}
      </tbody>
    </Table>
  );
}

export function Dashboard({ userType, analytics }) {
  const { orders_data, users_data } = analytics;
  // const barGraphTitle = (() => {
  //   if (userType === "type-res") return "Expenditure and Orders by Supplier";
  //   else if (userType === "type-sup" || userType === "type-rep")
  //     return "Revenue and Orders by Restaurant";
  // })();
  // const pieChartTitle = (() => {
  //   if (userType === "type-res") return "Expenditure Proportions by Supplier";
  //   else if (userType === "type-sup" || userType === "type-rep")
  //     return "Revenue proportions by Restaurant";
  // })();

  return (
    <GridBase>
      <OrdersDashboard orders_data={orders_data} />
      <ChartsWrap>
        <Card style={{ flex: 1 }}>
          {/* <H3 style={{ textAlign: "center", margin: "1rem" }}>{barGraphTitle}</H3> */}
          <H3 style={{ textAlign: "center", margin: "1rem" }}>
            {Text({ vid: "analytics", tid: "revenueAndOrders", capitalize: true })}
          </H3>

          <SuppliersBarGraph suppliers_data={users_data} />
        </Card>
        <div style={{ width: "1.5rem", height: "1.5rem" }}></div>
        <Card style={{ flex: 1 }}>
          {/* <H3 style={{ textAlign: "center", margin: "1rem" }}>{pieChartTitle}</H3> */}
          <H3 style={{ textAlign: "center", margin: "1rem" }}>
            {Text({ vid: "analytics", tid: "revenueProportions", capitalize: true })}
          </H3>

          <SuppliersPieChart suppliers_data={users_data} />
        </Card>
      </ChartsWrap>
      <div style={{ display: "flex", marginTop: "1.5rem" }}>
        <SuppliersDashboard userType={userType} users_data={users_data} orders_data={orders_data} />
      </div>
    </GridBase>
  );
}
