import React from "react";
import { CartStyled, CartContent, CartFooter } from "../Cart";
import { Icon } from "../Icon";
import { Button } from "../Button";
import { closeIcon } from "../../assets/icons";
import { H4 } from "../TextStyles";
import { formatPrice } from "../../utils/misc";
import styled from "styled-components";
import { minusIcon, plusIcon } from "../../assets/icons";
import { IconButton } from "../../components/IconButton";
import Text from "../../languages/Text";

const QuantityButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const OrderRowStyled = styled.div`
  color: var(--blue);
  padding: 1.25rem 0;
  display: flex;
  border-bottom: 1px solid var(--super-faded-blue);
  align-items: center;
`;

export default function OrderCart({
  isCartOpen,
  setIsCartOpen,
  order,
  openOrder,
  orderData,
  catalogue,
  productFunctions,
}) {
  const OrderRow = ({ item, quantity, productFunctions }) => {
    const { ID, id, name, price, promotionPrice, promotion, unit } = item;
    return (
      <OrderRowStyled>
        <div style={{ flex: 1 }}>
          <H4 accent>{name}</H4>
        </div>
        <div style={{ flex: 1, textAlign: "center" }}>
          <H4 accent>{unit}</H4>
        </div>
        <div style={{ flex: 1, textAlign: "center" }}>
          {promotion ? (
            <H4 accent>€{formatPrice(parseFloat(promotionPrice) * quantity)}</H4>
          ) : (
            <H4 accent>€{formatPrice(parseFloat(price) * quantity)}</H4>
          )}
        </div>

        <div style={{ flex: 1, textAlign: "right" }}>
          <QuantityButtons>
            <IconButton
              style={{ height: "2rem", width: "2rem" }}
              onClick={() => {
                productFunctions.decreaseQuantity(ID);
              }}
            >
              <Icon width="1rem" height="1rem" fill="white">
                {minusIcon}
              </Icon>
            </IconButton>
            <p
              style={{
                width: "2rem",
                textAlign: "center",
                fontWeight: "bold",
                fontSize: "1.25rem",
              }}
            >
              {quantity}
            </p>
            <IconButton
              style={{ height: "2rem", width: "2rem" }}
              onClick={() => {
                productFunctions.increaseQuantity(ID);
              }}
            >
              <Icon width="1rem" height="1rem" fill="white">
                {plusIcon}
              </Icon>
            </IconButton>
          </QuantityButtons>
        </div>
      </OrderRowStyled>
    );
  };

  const { cost, quantity } = orderData;

  return (
    <CartStyled isOpen={isCartOpen}>
      <div style={{ padding: "1rem", boxShadow: "0 2px 2px #000e663d" }}>
        <Icon style={{ fill: "var(--blue)" }} onClick={() => setIsCartOpen(false)}>
          {closeIcon}
        </Icon>
      </div>
      <CartContent>
        <div
          style={{
            flex: "1",
          }}
        >
          {order &&
            isCartOpen &&
            Object.keys(order).map((ID) => {
              const item = catalogue[ID];
              const itemQuantity = order[ID];
              return (
                <OrderRow
                  productFunctions={productFunctions}
                  item={{ ...item, ID }}
                  key={ID}
                  quantity={itemQuantity}
                />
              );
            })}
        </div>
      </CartContent>
      <CartFooter isOpen={isCartOpen}>
        <div style={{ fontSize: "1.5rem", fontWeight: "500" }}>
          <p>
            {quantity} <Text vid={"common"} tid={"products"} />
          </p>
          <p>
            <Text vid={"common"} tid={"total"} capitalize />: €{formatPrice(cost)}
          </p>
        </div>
        <Button
          onClick={() => {
            setIsCartOpen(false);
            openOrder();
          }}
        >
          <Text vid={"catalogue"} tid={"checkout"} />
        </Button>
      </CartFooter>
    </CartStyled>
  );
}
