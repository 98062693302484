import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { Button } from "../Button";
import { IconButton } from "../IconButton";

export const LinkText = styled.p`
  position: relative;

  &:before {
    content: "";
    position: absolute;
    width: 40%;
    /* height: 3px; */
    bottom: -3px;
    left: 0;
    border-bottom: 3px solid var(--blue);
    transition: 0.3s;
  }
  /* &:hover {
    &:before {
      width: 100%;
    }
  } */
`;

export const Card = styled.div`
  /* width: 40rem; */
  /* max-width: 22rem; */
  background-color: white;
  /* border-radius: 1rem; */
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  padding: 0.75rem 1rem;
  height: 100%;
  /* padding: ${(props) => (props.bigPadding ? "1.5rem 2rem" : " 0.75rem 1rem")}; */
  /* box-shadow: 0px 3px 4px #0022ff1a; */
  /* box-shadow: 0px 1px 6px #000e662d; */
  /* box-shadow: 0 0 0 1px rgb(67 41 163 / 10%), 0 1px 8px 0 rgb(67 41 163 / 10%); */
  /* box-shadow: 0px 0px 1px #000e6629, 0px 1px 6px #000e662d; */
  box-shadow: var(--card-shadow);

  /* > h1 {
    color: var(--faded-blue);
  } */

  /* @media (max-width: 600px) {
    grid-column: span 1;
    padding: 0.75rem 1rem;
  } */
`;

export const CardLink = styled(NavLink)`
  /* min-width: 20%; */
  /* height: 10rem;
  width: 20rem; */
  /* max-width: 40rem; */
  height: 100%;
  background-color: white;
  /* border-radius: 1rem; */
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  padding: 0.75rem 1rem;
  /* box-shadow: 0px 1px 6px #0022ff1a; */
  box-shadow: 0px 0px 1px #000e6629, 0px 1px 6px #000e662d;
  /* box-shadow: 0 0 0 1px rgb(67 41 163 / 10%), 0 1px 8px 0 rgb(67 41 163 / 10%); */
  &:hover {
    /* box-shadow: none; */
    /* box-shadow: 0px 6px 12px #000e662d; */
    box-shadow: 0px 1px 8px #000e6650;

    ${LinkText}:before {
      width: 100%;
    }
  }

  &:active {
    /* box-shadow: none; */
    box-shadow: 0px 0px 1px #000e6629;
    /* box-shadow: 0px 2px 12px #000e663d; */

    ${LinkText}:before {
      width: 100%;
    }
  }

  > h1 {
    color: var(--faded-blue);
  }
  /* @media (max-width: 600px) {
    grid-column: span 1;
  } */
`;

// const Product = styled.div`
//   display: flex;
//   flex-direction: column;
//   /* padding: 10px 16px; */
//   height: 195px;
// `;

export const CardHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: var(--blue);
  /* align-items: center; */
  /* height: 2rem; */
  height: fit-content;
`;

export const CardContent = styled.div`
  flex: 1;
  color: var(--blue);
  overflow: scroll;
  justify-content: space-around;
  display: flex;
  flex-direction: column;
  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; */
`;

export const CardFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: var(--blue);
  align-items: center;
  /* height: 2.25rem; */
  /* min-height: 2.5rem; */
  /* align-items: flex-end; */
  /* height: 40px; */
  /* align-items: center; */
  /* > * {
    margin-top: auto;
  } */
`;

export const CardButton = styled(Button)`
  border-radius: 4px;
`;

export const CardIconButton = styled(IconButton)`
  border-radius: 4px;
`;
