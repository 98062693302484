import React from "react";
// import { useProfile } from '../../contexts/ProfileContext';
import {
  Card,
  CardHeader,
  CardContent,
  CardFooter,
  CardIconButton,
} from "../../../components/Layout/Card";
import styled from "styled-components";
import { editIcon, deleteIcon } from "../../../assets/icons";
import { H3, H5 } from "../../../components/TextStyles";
import { IconButton } from "../../../components/IconButton";
import { Row } from "../../../components/Row";
import { CatalogueCategory } from "../../../components/Catalogue/CatalogueCategory";
import { Icon } from "../../../components/Icon";
import { formatPrice } from "../../../utils/misc";

const PromotionDiscount = styled.p`
  border-radius: 1rem;
  padding: 0.25rem 0.5rem;
  line-height: 0.875rem;
  background-color: var(--orange);
  color: white;
  font-weight: 800;
  font-size: 0.875rem;
  width: fit-content;
  height: fit-content;
`;

const ItemDescription = styled.p`
  /* overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; */
  line-height: 1.25rem;
  height: 2.5rem;
  overflow: hidden;
`;

export default function CatalogueItem({ item, isEditable, functions }) {
  const { id, name, description, price, unit, category, promotion, promotionPrice } = item;
  const formattedPrice = formatPrice(price);
  const formattedPromoPrice = promotion && promotionPrice ? formatPrice(promotionPrice) : null;

  const { onEdit, onDelete } = functions;
  return (
    <Card>
      <CardHeader>
        {/* <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <CatalogueCategory>{category}</CatalogueCategory>
        </div> */}
        <H5 style={{ width: "44%" }}>{id}</H5>
        {promotion && promotionPrice && (
          <PromotionDiscount>
            -{parseInt(((price - promotionPrice) / price) * 100)}%
          </PromotionDiscount>
        )}
      </CardHeader>
      <CardContent>
        <div>
          <H3
            color="var(--blue)"
            style={{
              width: "100%",
              textOverflow: "ellipsis",
              // whiteSpace: "nowrap",
              overflow: "hidden",
            }}
          >
            {name}
          </H3>
          <ItemDescription>{description}</ItemDescription>
        </div>
      </CardContent>
      <CardFooter style={{ height: "2rem" }}>
        {promotion && promotionPrice ? (
          <H3>
            <span
              style={{
                textDecoration: "line-through",
                textDecorationThickness: "2px",
                // textDecorationColor: "var(--orange)",
                marginRight: "0.5rem",
                color: "var(--blue)",
              }}
            >
              €{formattedPrice}
            </span>
            <span style={{ color: "var(--orange)" }}>€{formattedPromoPrice}</span>
            <span style={{ fontWeight: "400" }}> / {unit}</span>
          </H3>
        ) : (
          <H3>
            €{formattedPrice} <span style={{ fontWeight: "400" }}> / {unit}</span>
          </H3>
        )}

        {isEditable && (
          <Row margin={"0.5em"} style={{ display: "flex", alignItems: "center" }}>
            <CardIconButton onClick={onEdit}>
              <Icon width="1.25rem" height="1.25rem">
                {editIcon}
              </Icon>
            </CardIconButton>
            <CardIconButton onClick={onDelete}>
              <Icon width="1.25rem" height="1.25rem">
                {deleteIcon}
              </Icon>
            </CardIconButton>
          </Row>
        )}
      </CardFooter>
    </Card>
  );
}
