import {
  firestoreDb,
  firestoreArr,
  firestore,
  onAddRestaurant,
  onAddRepresentative,
} from "./firebase";

function getRef(path) {
  return firestoreDb.doc(path);
}

function getRestaurantRefs(userID) {
  return {
    restaurant: getRef(`restaurants/${userID}`),
    representatives: getRef(`restaurants/${userID}/private/representatives`),
    notifications: getRef(`restaurants/${userID}/private/notifications`),
    orders: getRef(`restaurants/${userID}/private/orders`),
    invites: getRef(`restaurants/${userID}/private/invites`),
    settings: getRef(`restaurants/${userID}/private/settings`),
  };
}

function getSupplierRefs(userID) {
  return {
    supplier: getRef(`suppliers/${userID}`),
    representatives: getRef(`suppliers/${userID}/private/representatives`),
    notifications: getRef(`suppliers/${userID}/private/notifications`),
    restaurants: getRef(`suppliers/${userID}/private/restaurants`),
    catalogue: getRef(`suppliers/${userID}/public/catalogue`),
    campaigns: getRef(`suppliers/${userID}/private/campaigns`),
    settings: getRef(`suppliers/${userID}/private/settings`),
  };
}

function getRepresentativeRefs(userID) {
  return {
    representative: getRef(`representatives/${userID}`),
    restaurants: getRef(`representatives/${userID}/private/restaurants`),
    notifications: getRef(`representatives/${userID}/private/notifications`),
    orders: getRef(`representatives/${userID}/private/orders`),
    invites: getRef(`representatives/${userID}/private/invites`),
    settings: getRef(`representatives/${userID}/private/settings`),
  };
}

export async function createRestaurantDB(userData, representativeID) {
  const { userFullName, userEmail, userField, userID } = userData;
  const batch = firestoreDb.batch();
  const restaurantRefs = getRestaurantRefs(userID);

  batch.set(restaurantRefs.restaurant, {
    restaurantName: userField,
    fullName: userFullName,
    email: userEmail,
  });
  batch.set(restaurantRefs.invites, {
    invites: [],
  });
  if (representativeID) {
    const representativeRef = firestoreDb.collection("representatives").doc(representativeID);
    const representativeDoc = await representativeRef.get();
    const representativeData = representativeDoc.data();
    const {
      fullName: representativeFullname,
      email: representativeEmail,
      supplierID,
      supplierName,
    } = representativeData;
    batch.set(restaurantRefs.representatives, {
      representativeIDs: [representativeID],
      [representativeID]: {
        fullName: representativeFullname,
        email: representativeEmail,
        supplierID,
        supplierName,
      },
    });
  } else {
    batch.set(restaurantRefs.representatives, {
      representativeIDs: [],
    });
  }

  batch.set(restaurantRefs.notifications, {
    notifications: [],
    lastTimeNotificationsOpened: firestore.Timestamp.now(),
  });
  batch.set(restaurantRefs.orders, {
    orderIDs: [],
  });
  return batch.commit();
}

export function createSupplierDB(userData) {
  const { userFullName, userEmail, userField, userID } = userData;
  const supplierRefs = getSupplierRefs(userID);
  const representativeRefs = getRepresentativeRefs(userID);
  const batch = firestoreDb.batch();

  batch.set(supplierRefs.supplier, {
    supplierName: userField,
    fullName: userFullName,
    email: userEmail,
  });

  batch.set(supplierRefs.catalogue, {
    supplierName: userField,
    fullName: userFullName,
    email: userEmail,
    catalogue: {},
  });

  batch.set(supplierRefs.campaigns, {
    campaigns: [],
  });

  batch.set(supplierRefs.representatives, {
    representativeIDs: [userID],
    [userID]: { fullName: userFullName, email: userEmail },
  });

  batch.set(supplierRefs.notifications, {
    notifications: [],
    lastTimeNotificationsOpened: firestoreArr.serverTimestamp(),
  });

  batch.set(supplierRefs.restaurants, {
    restaurantIDs: [],
  });

  batch.set(representativeRefs.representative, {
    fullName: userFullName,
    email: userEmail,
    supplierName: userField,
    supplierID: userID,
  });

  batch.set(representativeRefs.notifications, {
    notifications: [],
    lastTimeNotificationsOpened: firestoreArr.serverTimestamp(),
  });

  batch.set(representativeRefs.orders, {
    orderIDs: [],
  });

  batch.set(representativeRefs.invites, {
    invites: [],
  });

  batch.set(representativeRefs.settings, {
    emailNotifications: false,
  });

  batch.set(representativeRefs.restaurants, {
    restaurantIDs: [],
  });

  return batch.commit();
}

export async function createRepresentativeDB(userData) {
  const { userFullName, userEmail, userField, userID } = userData;
  const representativeRefs = getRepresentativeRefs(userID);
  const supplierRefs = getSupplierRefs(userField);
  const supplierDoc = await supplierRefs.supplier.get();
  const supplierName = supplierDoc.get("supplierName");

  const batch = firestoreDb.batch();
  batch.set(representativeRefs.representative, {
    fullName: userFullName,
    email: userEmail,
    supplierName,
    supplierID: userField,
  });

  batch.set(representativeRefs.orders, {
    orderIDs: [],
  });

  batch.set(representativeRefs.restaurants, {
    restaurantIDs: [],
  });

  batch.set(representativeRefs.invites, {
    invites: [],
  });

  batch.set(representativeRefs.settings, {
    emailNotifications: false,
  });

  batch.set(representativeRefs.notifications, {
    notifications: [],
    lastTimeNotificationsOpened: firestoreArr.serverTimestamp(),
  });

  batch.update(supplierRefs.representatives, {
    representativeIDs: firestoreArr.arrayUnion(userID),
    [userID]: { fullName: userFullName, email: userEmail },
  });

  return batch.commit();
}

export function changeAddress(data) {
  const { userID, userType, address } = data;

  if (userType === "type-sup") {
    const supplierRefs = getSupplierRefs(userID);
    return supplierRefs.supplier.set(
      {
        address,
      },
      { merge: true }
    );
  } else if (userType === "type-res") {
    const restaurantRefs = getRestaurantRefs(userID);
    return restaurantRefs.restaurant.set(
      {
        address,
      },
      { merge: true }
    );
  }
}

export function changePhone(data) {
  const { userID, userType, phone } = data;

  if (userType === "type-rep") {
    const representativeRefs = getRepresentativeRefs(userID);
    return representativeRefs.representative.set(
      {
        phone,
      },
      { merge: true }
    );
  } else if (userType === "type-res") {
    const restaurantRefs = getRestaurantRefs(userID);
    return restaurantRefs.restaurant.set(
      {
        phone,
      },
      { merge: true }
    );
  } else if (userType === "type-sup") {
    const representativeRefs = getRepresentativeRefs(userID);
    const supplierRefs = getSupplierRefs(userID);

    const batch = firestoreDb.batch();
    batch.set(
      representativeRefs.representative,
      {
        phone,
      },
      { merge: true }
    );
    batch.set(
      supplierRefs.supplier,
      {
        phone,
      },
      { merge: true }
    );

    return batch.commit();

    return representativeRefs.representative.set(
      {
        phone,
      },
      { merge: true }
    );
  }
}

export function addInvitedRestaurant(data) {
  const { representativeID, restaurantEmail } = data;
  const timestamp = firestore.Timestamp.now();

  const representativeRef = firestoreDb
    .collection("representatives")
    .doc(representativeID)
    .collection("private")
    .doc("invites");

  return representativeRef.update({
    invites: firestoreArr.arrayUnion({ restaurantEmail, timestamp }),
  });
}

export function addInvitedSupplier(data) {
  const { restaurantID, supplierEmail } = data;
  const timestamp = firestore.Timestamp.now();

  const restaurantRef = firestoreDb
    .collection("restaurants")
    .doc(restaurantID)
    .collection("private")
    .doc("invites");

  return restaurantRef.update({
    invites: firestoreArr.arrayUnion({ supplierEmail, timestamp }),
  });
}

export async function addRestaurant(data) {
  const {
    representativeID,
    restaurantID,
    supplierID,
    supplierName,
    fullName: representativeFullname,
    email: representativeEmail,
  } = data;

  const restaurantRef = firestoreDb.collection("restaurants").doc(restaurantID);
  const restaurantDoc = await restaurantRef.get();
  const restaurantData = restaurantDoc.data();
  const representativeRestaurantsRef = firestoreDb
    .collection("representatives")
    .doc(representativeID)
    .collection("private")
    .doc("restaurants");
  const { restaurantName, fullName: restaurantFullname, email: restaurantEmail } = restaurantData;
  const httpResponse = await onAddRestaurant({
    fullName: representativeFullname,
    email: representativeEmail,
    representativeID,
    supplierID,
    supplierName,
    restaurantID,
  });
  return representativeRestaurantsRef.update({
    [restaurantID]: {
      restaurantName,
      fullName: restaurantFullname,
      email: restaurantEmail,
    },
    restaurantIDs: firestoreArr.arrayUnion(restaurantID),
  });
}

export async function addRepresentative(data) {
  const {
    representativeID,
    restaurantID,
    fullName: restaurantFullname,
    email: restaurantEmail,
    restaurantName,
  } = data;
  const representativeRef = firestoreDb.collection("representatives").doc(representativeID);

  const representativeDoc = await representativeRef.get();
  const representativeData = representativeDoc.data();
  const restaurantRepresentativeRef = firestoreDb
    .collection("restaurants")
    .doc(restaurantID)
    .collection("private")
    .doc("representatives");
  const {
    fullName: representativeFullname,
    email: representativeEmail,
    supplierID,
    supplierName,
  } = representativeData;

  const httpResponse = await onAddRepresentative({
    fullName: restaurantFullname,
    email: restaurantEmail,
    representativeID,
    restaurantID,
    restaurantName,
  });
  return restaurantRepresentativeRef.update({
    [representativeID]: {
      fullName: representativeFullname,
      email: representativeEmail,
      supplierID,
      supplierName,
    },
    representativeIDs: firestoreArr.arrayUnion(representativeID),
  });
}

export function updateProfile(data) {
  const { collection, doc, newData } = data;
  const privateRef = firestoreDb.collection(collection).doc(doc);

  const batch = firestoreDb.batch();
  batch.update(privateRef, { ...newData });

  if (collection === "suppliers") {
    const supplierRepRef = firestoreDb.collection("representatives").doc(doc);
    batch.update(supplierRepRef, { ...newData });
  }

  if (collection === "representatives") {
    const supplierRepRef = firestoreDb
      .collection("suppliers")
      .doc(data.supplierID)
      .collection("private")
      .doc("representatives");
    batch.update(supplierRepRef, { ...newData });
  }

  return batch.commit();
}

export function updateField(data) {
  const { collection, doc, newData } = data;
  const docRef = firestoreDb.collection(collection).doc(doc);
  return docRef.update(newData);
}

export function updateCatalogue(supplierID, data) {
  const docRef = firestoreDb
    .collection("suppliers")
    .doc(supplierID)
    .collection("public")
    .doc("catalogue");
  return docRef.update({ catalogue: data });
}

export function updateHasSeenOrder(representativeID, orderID) {
  const docRef = firestoreDb
    .collection("representatives")
    .doc(representativeID)
    .collection("private")
    .doc("orders");
  docRef.update({
    [`${orderID}.unopened`]: false,
  });
}

export function getDoc(path) {
  return firestoreDb.doc(path).get();
}

export function getOrders(userCollection, userID) {
  return firestoreDb
    .collection(userCollection)
    .doc(userID)
    .collection("private")
    .doc("orders")
    .get();
}

export async function asyncGetDoc(path) {
  const doc = await firestoreDb.doc(path).get();
  return doc;
}

export function getRestaurant(userID) {
  return firestoreDb.collection("restaurants").doc(userID).onSnapshot();
}

export function getCatalogue(supplierID) {
  return firestoreDb
    .collection("suppliers")
    .doc(supplierID)
    .collection("public")
    .doc("catalogue")
    .get();
}

export function getOrderRef(orderID) {
  return firestoreDb.collection("orders").doc(orderID);
}

export function getOrdersRef(userCollection, userID) {
  return firestoreDb.collection(userCollection).doc(userID).collection("private").doc("orders");
}

export async function sendOrder(order) {
  const {
    restaurantID,
    representativeID,
    representativeName,
    supplierName,
    supplierID,
    status,
    cost,
    quantity,
  } = order;
  const batch = firestoreDb.batch();
  const orderRef = firestoreDb.collection("orders").doc();
  const restaurantOrdersRef = firestoreDb
    .collection("restaurants")
    .doc(restaurantID)
    .collection("private")
    .doc("orders");

  const orderID = orderRef.id;
  const timestamp = firestore.Timestamp.now();
  batch.set(orderRef, { ...order, timestamp });
  batch.update(restaurantOrdersRef, {
    [orderID]: {
      timestamp,
      representativeID,
      representativeName,
      supplierName,
      supplierID,
      orderID,
      status,
      cost,
      quantity,
    },
    orderIDs: firestoreArr.arrayUnion(orderID),
  });
  await batch.commit();
  return orderID;
}

export function sendOrderFromRep(order) {
  const {
    restaurantID,
    restaurantName,
    representativeID,
    supplierName,
    supplierID,
    status,
    cost,
    quantity,
  } = order;
  const batch = firestoreDb.batch();
  const orderRef = firestoreDb.collection("orders").doc();
  const represenatativeOrdersRef = firestoreDb
    .collection("representatives")
    .doc(representativeID)
    .collection("private")
    .doc("orders");

  const orderID = orderRef.id;
  const timestamp = firestore.Timestamp.now();
  batch.set(orderRef, { ...order, timestamp });
  batch.update(represenatativeOrdersRef, {
    [orderID]: {
      timestamp,
      restaurantID,
      restaurantName,
      supplierName,
      orderID,
      supplierID,
      status,
      cost,
      quantity,
      unopened: true,
    },
    orderIDs: firestoreArr.arrayUnion(orderID),
  });
  return batch.commit();
}

export function setNotificationRead(data) {
  const { collection, doc } = data;
  return firestoreDb
    .collection(collection)
    .doc(doc)
    .collection("private")
    .doc("notifications")
    .update({ lastTimeNotificationsOpened: firestoreArr.serverTimestamp() });
}

export function createCampaign(data) {
  const { supplierID, campaignName, campaignText, supplierName, recipients } = data;
  const timestamp = firestore.Timestamp.now();
  const notification = {
    type: "newCampaign",
    timestamp,
    supplierID,
    campaignName,
    campaignText,
    supplierName,
  };
  const campaign = {
    recipients,
    campaignName,
    campaignText,
    timestamp,
  };
  const batch = firestoreDb.batch();
  const supRef = firestoreDb
    .collection("suppliers")
    .doc(supplierID)
    .collection("private")
    .doc("campaigns");
  batch.update(supRef, { campaigns: firestoreArr.arrayUnion(campaign) });

  Object.keys(recipients).forEach((resID) => {
    const resRef = firestoreDb
      .collection("restaurants")
      .doc(resID)
      .collection("private")
      .doc("notifications");
    batch.update(resRef, {
      notifications: firestoreArr.arrayUnion(notification),
    });
  });
  return batch.commit();
}

export function sendNotifications(recipients, data) {
  const { supplierID, campaignText, supplierName } = data;
  const notification = {
    type: "campaign",
    timestamp: firestore.Timestamp.now(),
    supplierID,
    campaignText,
    supplierName,
  };

  const batch = firestoreDb.batch();
  recipients.forEach((resID) => {
    const resRef = firestoreDb
      .collection("restaurants")
      .doc(resID)
      .collection("private")
      .doc("notifications");
    batch.update(resRef, {
      notifications: firestoreArr.arrayUnion(notification),
    });
  });
  return batch.commit();
}
