import React from "react";
import Modal, {
  ModalHeader,
  ModalContent,
  ModalFooter,
  ModalTextarea,
  ModalInput,
} from "../../components/Modal";
import { H1, H2, H3, H5 } from "../../components/TextStyles";
import { formatPrice } from "../../utils/misc";
import { CatalogueCategory } from "./CatalogueCategory";
import { IconButton } from "../../components/IconButton";
import { Button } from "../../components/Button";
import { minusIcon, plusIcon, addToCartIcon } from "../../assets/icons";
import { Icon } from "../../components/Icon";
import styled from "styled-components";

const QuantityButtons = styled.div`
  display: flex;
  align-items: center;
`;

const PromotionDiscount = styled.p`
  border-radius: 1rem;
  padding: 0.225rem 0.5rem;
  line-height: 0.75rem;
  background-color: var(--orange);
  color: white;
  font-weight: 800;
  font-size: 0.75rem;
`;

export default function ProductModal({ product, onClose, isInOrder, functions, quantity }) {
  const { name, category, description, id, price, promotion, unit, promotionPrice, ID } = product;
  return (
    <Modal width="36rem" onClose={onClose}>
      <ModalHeader>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <H5 style={{ width: "44%", textAlign: "left" }}>{id}</H5>
          {promotion && promotionPrice && (
            <PromotionDiscount>
              -{parseInt(((price - promotionPrice) / price) * 100)}%
            </PromotionDiscount>
          )}
          <div style={{ width: "44%", display: "flex", justifyContent: "flex-end" }}>
            <CatalogueCategory>{category}</CatalogueCategory>
          </div>
        </div>
      </ModalHeader>
      <ModalContent>
        <H1 style={{ padding: "1rem 0" }}>{name}</H1>

        <p>{description}</p>
        {promotion && promotionPrice ? (
          <>
            <H3 style={{ paddingTop: "1rem" }}>
              <span
                style={{
                  textDecoration: "line-through",
                  textDecorationThickness: "2px",
                  marginRight: "0.5rem",
                  color: "var(--orange)",
                }}
              >
                €{formatPrice(price)}
              </span>
              €{formatPrice(promotionPrice)} <span style={{ fontWeight: "400" }}> / {unit}</span>
            </H3>
          </>
        ) : (
          <H3 style={{ paddingTop: "1rem" }}>
            €{formatPrice(price)} <span style={{ fontWeight: "400" }}> / {unit}</span>
          </H3>
        )}
      </ModalContent>
      <ModalFooter>
        {isInOrder ? (
          <QuantityButtons>
            <IconButton
              onClick={() => {
                functions.decreaseQuantity(ID);
              }}
            >
              <Icon width="1.25rem" height="1.25rem" fill="var(--faded-blue)">
                {minusIcon}
              </Icon>
            </IconButton>
            <p
              style={{
                width: "2rem",
                textAlign: "center",
                fontWeight: "bold",
                fontSize: "1.25rem",
              }}
            >
              {/* {order[ID]} */}
              {quantity}
            </p>
            <IconButton
              onClick={() => {
                functions.increaseQuantity(ID);
              }}
            >
              <Icon width="1.25rem" height="1.25rem" fill="var(--faded-blue)">
                {plusIcon}
              </Icon>
            </IconButton>
          </QuantityButtons>
        ) : (
          <Button
            onClick={() => {
              functions.addToOrder(ID);
            }}
          >
            add to cart
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
}
