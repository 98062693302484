import React, { useState, useEffect, useMemo } from "react";
import { useAuth } from "../../../contexts/AuthContext";
import { Grid } from "../../../components/Grid";
import Text from "../../../languages/Text";
import { H1 } from "../../../components/TextStyles";
import { SearchBar } from "../../../components/SearchBar";
import { v4 as uuidv4 } from "uuid";
import { Loader } from "../../../components/Loader.js";
import useFetch from "../../../hooks/useFetch";
import CatalogueButtons from "./CatalogueButtons";
import CatalogueItem from "./CatalogueItem";
import CatalogueModal from "./CatalogueModal";
import { submitCatalogue, fetchCatalogue } from "./data";
import { Body, UtilBar, Page, Header } from "../../../components/Layout/Layout";
import FileUploadModal from "./FileUploadModal";
import CategoryGrid from "../../../components/Catalogue/CategoryGrid";

const Catalogue = ({ isMobile }) => {
  const { currentUser } = useAuth();
  const { uid: userID } = currentUser;
  const [newCatalogue, setNewCatalogue] = useState(null);
  const [filteredCatalogue, setFilteredCatalogue] = useState(null);
  const [isEditable, setIsEditable] = useState(false);
  const [isUploadingFile, setIsUploadingFile] = useState(false);
  const [editingItemId, setEditingItemId] = useState(null);
  const [newItem, setNewItem] = useState(null);
  const [query, setQuery] = useState("");
  // const [sortBy, setSortBy] = useState("");
  // const [isDescending, setDescending] = useState(false);
  const functionArgs = useMemo(() => ({ userID }), [userID]);
  const { data, isLoading } = useFetch(fetchCatalogue, functionArgs);
  const { sortedCatalogue: originalCatalogue, categories } = data;
  const searchText = Text({ vid: "catalogue", tid: "searchProductByName", capitalize: true });
  const [uploadedCatalogue, setUploadedCatalogue] = useState({});
  const [openCategory, setOpenCategory] = useState();

  useEffect(() => {
    setNewCatalogue(originalCatalogue);
  }, [originalCatalogue]);

  useEffect(() => {
    setNewCatalogue({ ...originalCatalogue, ...uploadedCatalogue });
  }, [uploadedCatalogue]);

  useEffect(() => {
    if (newItem) {
      // newItem.price = parseFloat(newItem.price.replace(",", ".")).toFixed(2);
      // if (newItem.promotion)
      //   newItem.promotionPrice = parseFloat(newItem.promotionPrice.replace(",", ".")).toFixed(2);
      setNewCatalogue({ ...newCatalogue, [editingItemId]: newItem });
      setNewItem(null);
      setEditingItemId(null);
      if (!categories.includes(newItem.category)) categories.push(newItem.category);
      setOpenCategory(newItem.category);
    }
  }, [newItem]);

  function filterCatalogue(catalogue, query) {
    let temp = {};
    Object.keys(catalogue).forEach((ID) => {
      const item = catalogue[ID];
      if (item.name.toLowerCase().includes(query.toLowerCase())) temp[ID] = item;
    });
    return temp;
  }

  useEffect(() => {
    if (query && newCatalogue) {
      setFilteredCatalogue(filterCatalogue(newCatalogue, query));
    } else {
      setFilteredCatalogue(null);
    }
  }, [query, newCatalogue]);

  function deleteRow(delId) {
    let tempCat = JSON.parse(JSON.stringify(newCatalogue));
    delete tempCat[delId];
    setNewCatalogue(tempCat);
  }

  function buildCatalogue(catalogue) {
    if (!isMobile) {
      return (
        Object.keys(catalogue)
          // .slice(page * PER_PAGE, page * PER_PAGE + PER_PAGE)
          .map((ID) => {
            const catalogueItemFunctions = {
              onEdit: () => {
                setEditingItemId(ID);
              },
              onDelete: () => {
                deleteRow(ID);
              },
            };
            return (
              <CatalogueItem
                item={catalogue[ID]}
                key={ID}
                isEditable={isEditable}
                functions={catalogueItemFunctions}
              />
            );
          })
      );
    } else {
      return Object.keys(catalogue).map((ID) => {
        const catalogueItemFunctions = {
          onEdit: () => {
            setEditingItemId(ID);
          },
          onDelete: () => {
            deleteRow(ID);
          },
        };
        return (
          <CatalogueItem
            item={catalogue[ID]}
            key={ID}
            isEditable={isEditable}
            functions={catalogueItemFunctions}
          />
        );
      });
    }
  }

  const catalogueButtonsFunctions = {
    onSaveCatalogue: () => {
      submitCatalogue({ userID, catalogue: newCatalogue });
      // submitCatalogue({ userID, catalogue: {} });
    },
    onCancelCatalogue: () => {
      setIsEditable(false);
      setNewCatalogue(originalCatalogue);
    },
    onAddItem: () => {
      const ID = uuidv4();
      setEditingItemId(ID);
    },
    onMakeEditable: () => {
      setIsEditable(true);
    },
    onUploadFile: () => {
      setIsUploadingFile(true);
    },
  };

  return (
    <Page>
      <Header>
        <H1>
          <Text vid={"common"} tid={"catalogue"} capitalize={true} />
        </H1>

        <CatalogueButtons isEditable={isEditable} functions={catalogueButtonsFunctions} />
      </Header>
      <UtilBar>
        <SearchBar
          placeholder={searchText}
          type="search"
          value={query}
          onChange={(e) => {
            setQuery(e.target.value);
          }}
        />
      </UtilBar>
      <Body>
        {isLoading ? (
          <Loader />
        ) : (
          // <Grid>
          //   {filteredCatalogue ? buildCatalogue(filteredCatalogue) : buildCatalogue(newCatalogue)}
          // </Grid>
          <>
            {filteredCatalogue && query ? (
              <Grid>{buildCatalogue(filteredCatalogue)}</Grid>
            ) : (
              <div style={{ paddingBottom: "8rem" }}>
                {categories.map((cat) => (
                  <CategoryGrid
                    category={cat}
                    isOpen={openCategory === cat}
                    setOpenCategory={setOpenCategory}
                  >
                    {Object.keys(newCatalogue)
                      .filter((ID) => newCatalogue[ID].category === cat)
                      .map((ID) => (
                        <CatalogueItem
                          item={newCatalogue[ID]}
                          key={ID}
                          isEditable={isEditable}
                          functions={{
                            onEdit: () => {
                              setEditingItemId(ID);
                            },
                            onDelete: () => {
                              deleteRow(ID);
                            },
                          }}
                        />
                      ))}
                  </CategoryGrid>
                ))}
              </div>
            )}
          </>
        )}

        {editingItemId && (
          <CatalogueModal
            row={newCatalogue[editingItemId]}
            ID={editingItemId}
            setNewItem={setNewItem}
            setEditingItemId={setEditingItemId}
          />
        )}
        {isUploadingFile && (
          <FileUploadModal
            // row={newCatalogue[editingItemId]}
            // ID={editingItemId}
            // setNewItem={setNewItem}
            // setEditingItemId={setEditingItemId}
            setUploadedCatalogue={setUploadedCatalogue}
            onClose={() => setIsUploadingFile(false)}
          />
        )}
      </Body>
    </Page>
  );
};

export default Catalogue;

// useEffect(() => {
//   if (newCatalogue)
//     setNewCatalogue((current) => {
//       const temp = Object.entries(current);
//       if (isDescending)
//         return Object.fromEntries(temp.sort((a, b) => compare(a, b, sortBy)).reverse());
//       return Object.fromEntries(temp.sort((a, b) => compare(a, b, sortBy)));
//     });
// }, [sortBy, isDescending]);
