import styled from "styled-components";

export const SearchBar = styled.input`
  /* grid-column: span 12; */
  /* width: 40%; */
  /* width: 60rem; */
  min-width: 30%;
  background-color: white;
  border-radius: 24px;
  box-shadow: var(--basic-shadow);
  height: 3rem;
  /* margin-left: 1rem; */

  /* margin-bottom: 1rem; */
  border: 0;
  /* width: 100%; */
  padding: 0.75rem;
  padding-left: 2.25rem;
  border: 0;
  outline: none;
  background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2214.722%22%20height%3D%2214.722%22%20viewBox%3D%220%200%2014.722%2014.722%22%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill%3A%237185b4%3B%7D%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20transform%3D%22translate(0)%22%3E%3Cpath%20class%3D%22a%22%20d%3D%22M14.588%2C13.921l-3.8-3.74A6.012%2C6.012%2C0%2C0%2C0%2C12.394%2C6.1%2C6.146%2C6.146%2C0%2C0%2C0%2C6.2%2C0%2C6.145%2C6.145%2C0%2C0%2C0%2C.006%2C6.1a6.145%2C6.145%2C0%2C0%2C0%2C6.194%2C6.1%2C6.229%2C6.229%2C0%2C0%2C0%2C3.9-1.361l3.816%2C3.755a.482.482%2C0%2C0%2C0%2C.674%2C0A.464.464%2C0%2C0%2C0%2C14.588%2C13.921ZM6.2%2C11.253A5.2%2C5.2%2C0%2C0%2C1%2C.959%2C6.1%2C5.2%2C5.2%2C0%2C0%2C1%2C6.2.938%2C5.2%2C5.2%2C0%2C0%2C1%2C11.441%2C6.1%2C5.2%2C5.2%2C0%2C0%2C1%2C6.2%2C11.253Z%22%20transform%3D%22translate(-0.006%200)%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-position: 0.75rem;
`;
