import React, { useState } from "react";
import { withRouter, Link, useHistory, useLocation } from "react-router-dom";
import { RegisterModal } from "./RegisterModal";
import { Form } from "./Form";
import { RegisterButton } from "./RegisterButton";
import { useAuth } from "../../contexts/AuthContext";
import { fuduramaLogo } from "../../assets/icons";
import { Icon } from "../../components/Icon";
import { H1, H2 } from "../../components/TextStyles";
import { TextInput, TextareaInput, NumberInput } from "../../components/TextInput";

const Signin = (props) => {
  const { state } = useLocation();
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [isLoading, toggleLoading] = useState(false);
  const [errors, setErrors] = useState("");
  const { signin } = useAuth();
  const history = useHistory();

  function findFieldErrors() {
    let tempErrors = {};
    if (password.length === 0) tempErrors.password = "Cannot be empty";
    if (email.length === 0) tempErrors.email = "Cannot be empty";

    setErrors(tempErrors);
    if (Object.keys(tempErrors).length === 0) return false;
    else return true;
  }

  async function handleSubmit(e) {
    e.preventDefault();
    if (!findFieldErrors()) {
      toggleLoading(true);
      try {
        toggleLoading(false);
        const currentUser = await signin(email, password);
        if (state)
          history.push({
            pathname: "/add-user",
            search: state.state.urlParams,
          });
        else {
          history.push("/orders");
        }
      } catch (error) {
        console.error("There was an error signing in", error);
        if (error.code === "auth/invalid-email") setErrors({ email: "Invalid email" });
        if (error.code === "auth/user-not-found") setErrors({ email: "User not found" });
        if (error.code === "auth/wrong-password") setErrors({ password: "Incorrect password" });

        toggleLoading(false);
      }
    }
  }

  return (
    // <UtilCenter>
    // <div
    //   style={{
    //     width: "100%",
    //     height: "100%",
    //     position: "absolute",
    //     top: 0,
    //     bottom: 0,
    //     left: 0,
    //     right: 0,
    //     overflow: "auto",
    //     overscrollBehaviorY: "none",
    //   }}
    // >
    <>
      {/* <a href="https://www.fudurama.com/">
        <Icon width="8rem" style={{ margin: "20px ", width: "fit-content" }}>
          {fuduramaLogo}
        </Icon>
      </a> */}

      <RegisterModal>
        <div style={{ position: "absolute", top: "2rem" }}>
          <a href="https://www.fudurama.com/">
            {/* <Icon width="8rem" style={{ margin: "-4rem auto 8rem auto" }}> */}
            <Icon width="8rem" style={{ margin: "auto" }}>
              {fuduramaLogo}
            </Icon>
          </a>
        </div>
        <div style={{ height: "2rem" }}></div>
        <Form id="signin-form" className="auth-form" onSubmit={handleSubmit}>
          <H1>Sign in</H1>

          <TextInput
            sharp
            error={errors.email}
            label="Email"
            value={email}
            placeholder="Email"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          <TextInput
            sharp
            password
            error={errors.password}
            label="Password"
            value={password}
            placeholder="Password"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />

          <RegisterButton type="submit" value="Sign in" disabled={isLoading} />
          <p style={{ fontSize: "14px" }}>
            Forgot password?
            <Link style={{ fontWeight: "bold" }} to="/reset-password">
              {" "}
              Reset password
            </Link>
          </p>
          <p style={{ fontSize: "14px" }}>
            Don't have an account?
            <Link style={{ fontWeight: "bold" }} to="/signup-restaurant">
              {" "}
              Sign up
            </Link>
          </p>
        </Form>
      </RegisterModal>
    </>
    // </div>
  );
};

export default Signin;
