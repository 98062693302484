import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { fuduramaLogo } from "../../assets/icons";
import styled from "styled-components";

export const Navbar = styled.div`
  width: ${(props) => (props.isOpen ? "12rem" : "5rem")};
  min-width: "5rem";
  background-color: white;
  overflow-x: hidden;
  /* box-shadow: 0px 0px 19px #090b5a1c; */
  box-shadow: 0px 4px 8px #000e663d;
  padding: 1.5rem 0.75rem;
  display: flex;
  flex-direction: column;
  > svg {
    /* flex: 0 1 auto; */
    flex-shrink: 0;
    margin: auto;
    max-width: 80px;
  }
`;

export const NavLinks = styled.nav`
  margin: 40px 0;
  flex: 1 1 auto;

  > a {
    font-size: 0.875rem;
    display: flex;
    flex-direction: ${(props) => (props.isOpen ? "row" : "column")};
    align-items: center;
    border-radius: 12px;
    fill: var(--faded-blue);
    padding: 1.5rem 0.2rem;

    > svg {
      height: 1.2rem;
      width: 1.2rem;
      margin: 0 0.5rem;
    }

    > p {
      display: ${(props) => (props.isOpen ? "inherit" : "none")};
    }
  }

  @media (max-width: 860px) {
    margin: 0;
    padding: 0;
    width: 100%;
    align-items: start;
    display: flex;
    /* flex: 1 1 0px;
    width: 100%;
    justify-content: space-around; */
    > a {
      display: flex;
      /* justify-content: space-between; */
      flex-direction: column;
      border-radius: 12px;
      fill: var(--faded-blue);
      padding: 0.25rem;
      height: 100%;
      width: 60%;
      flex: 1 1 0px;
      align-items: center;

      > p {
        font-size: 0.6rem;
        padding-top: 0.5rem;
        white-space: nowrap;
        overflow: hidden;
        text-align: center;
      }
    }
  }
`;

export const StyledLink = styled(NavLink)`
  display: flex;
  flex-direction: column;
  color: var(--faded-blue);
  text-align: center;
  &.active {
    background-color: rgba(45, 101, 255, 0.05);
    fill: var(--blue);
    color: var(--blue);
    font-weight: bold;

    > svg {
      fill: var(--blue);
    }
  }
`;

const NavbarEl = (props) => {
  const [isOpen, toggleOpen] = useState(true);

  return (
    <Navbar isOpen={isOpen}>
      {fuduramaLogo}

      <NavLinks isOpen={isOpen}>{props.children}</NavLinks>
      <svg
        onClick={() => toggleOpen((current) => !current)}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        style={{ display: "block", margin: "auto", fill: "var(--blue)" }}
      >
        <path d="M24 6h-24v-4h24v4zm0 4h-24v4h24v-4zm0 8h-24v4h24v-4z" />
      </svg>
    </Navbar>
  );
};

export default NavbarEl;
