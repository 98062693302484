import styled from "styled-components";
import { caretDown } from "../assets/icons";

export const Select = styled.select`
  border: 1px solid var(--super-faded-blue);
  border-radius: 24px;
  padding: 0.5rem 1rem;
  padding-right: 2rem;
  outline: none;
  height: 3rem;
  /* background-color: white; */
  /* box-shadow: var(--basic-shadow); */
  -webkit-appearance: none;
  /* background-image: ${caretDown}; */
  background: white
    url("data:image/svg+xml;utf8,<svg viewBox='0 0 140 140' width='12' height='12' xmlns='http://www.w3.org/2000/svg'><g><path d='m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z' fill='black'/></g></svg>")
    no-repeat;

  /* background-repeat: no-repeat; */
  background-position: top 1rem right 0.5rem;

  /* width: 100%;
  height: 35px;
  background: white;
  color: gray;
  padding-left: 5px;
  font-size: 14px;
  border: none;
  margin-left: 10px; */
  option {
    /* color: black;
    background: white;
    display: flex;
    white-space: pre;
    min-height: 20px;
    padding: 0px 2px 1px; */
  }
`;
