import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";

const app = firebase.initializeApp({
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
});

const appProduction = firebase.initializeApp(
  {
    // For Firebase JS SDK v7.20.0 and later, measurementId is optional
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY_PRODUCTION,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN_PRODUCTION,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID_PRODUCTION,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET_PRODUCTION,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID_PRODUCTION,
    appId: process.env.REACT_APP_FIREBASE_APP_ID_PRODUCTION,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID_PRODUCTION,
  },
  "secondary"
);

const firebaseFunctions = firebase.functions();

export const addUserType = firebaseFunctions.httpsCallable("addUserType");
export const onAddRestaurant = firebaseFunctions.httpsCallable("onAddRestaurant");
export const onAddRepresentative = firebaseFunctions.httpsCallable("onAddRepresentative");
export const inviteRestaurant = firebaseFunctions.httpsCallable("inviteRestaurant");
export const inviteRepresentative = firebaseFunctions.httpsCallable("inviteRepresentative");
export const inviteSupplier = firebaseFunctions.httpsCallable("inviteSupplier");

export const auth = app.auth();
export const firestoreDb = firebase.firestore();
firestoreDb.enablePersistence();
export const firestoreArr = firebase.firestore.FieldValue;
export const { firestore } = firebase;
export default app;
