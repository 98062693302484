import styled from "styled-components";

export const CircleButton = styled.button`
  border-radius: 2rem;
  background-color: #f9f9f9;
  border: none;
  box-shadow: 0px 3px 6px #2d65ff2b;
  padding: 0.5rem;
  height: 2rem;
  width: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;

  > svg {
    height: 0.75rem;
    width: 0.75rem;
    fill: var(--faded-blue);
  }
`;
