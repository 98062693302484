import { getCatalogue, updateCatalogue } from "../../../firestore";
import { sortObject } from "../../../utils/sort";

export function fetchCatalogue({ userID }) {
  return getCatalogue(userID)
    .then((doc) => doc.get("catalogue"))
    .then((catalogue) => {
      console.log("catalogue", catalogue);

      const sortedCatalogue = sortObject(catalogue, "category", "name");
      // let categories = new Set(["all", "promotion"]);
      let categories = new Set();

      Object.values(sortedCatalogue).forEach(({ category }) => {
        categories.add(category);
      });
      categories = Array.from(categories);
      return { sortedCatalogue, categories };
    });
}

// export function fetchCatalogue({ supplierID }) {
//   return getCatalogue(supplierID)
//     .then((doc) => doc.data())
//     .then((data) => {
//       const { catalogue, supplierName } = data;
//       const sortedCatalogue = sortObject(catalogue, "category", "name");
//       // let categories = new Set(["all", "promotion"]);
//       let categories = new Set();

//       Object.values(sortedCatalogue).forEach(({ category }) => {
//         categories.add(category);
//       });
//       categories = Array.from(categories);
//       return { catalogue: sortedCatalogue, categories, supplierName };
//     });
// }

export function submitCatalogue({ userID, catalogue }) {
  return updateCatalogue(userID, catalogue).then(() => {
    window.location.reload();
  });
}
