import React, { useState, useMemo } from "react";
import Text from "../../../languages/Text";
import { H1 } from "../../../components/TextStyles";
import { useAuth } from "../../../contexts/AuthContext";
import { GridSmall } from "../../../components/Grid";
import { inviteRepresentative } from "../../../firebase";
import { Button } from "../../../components/Button";
import InviteModal from "../../../components/InviteModal";
import useFetch from "../../../hooks/useFetch";
import { fetchRepresentatives } from "./data";
import RepresentativeCard from "./RepresentativeCard";
import { Loader } from "../../../components/Loader";
import { Body, Page, Header } from "../../../components/Layout/Layout";

const Representatives = () => {
  const { currentUser } = useAuth();
  const { uid: userID } = currentUser;
  const args = useMemo(() => ({ userID }), [userID]);
  const [isInviting, setInviting] = useState(false);
  const [representativeEmail, setRepresentativeEmail] = useState("");
  const { data: representatives, isLoading } = useFetch(fetchRepresentatives, args);
  const [isSendingInvite, setIsSendingInvite] = useState(false);

  function onInvite() {
    setIsSendingInvite(true);
    inviteRepresentative({ supplierID: userID, representativeEmail }).then(() => {
      setInviting(false);
      setRepresentativeEmail("");
      setIsSendingInvite(false);
    });
  }

  return (
    <Page>
      <Header>
        <H1>
          <Text vid={"common"} tid={"representatives"} capitalize={true} />
        </H1>
        <Button onClick={() => setInviting(true)}>
          <Text vid={"representatives"} tid={"addRepresentative"} />
        </Button>
      </Header>
      <Body>
        {isLoading ? (
          <Loader />
        ) : (
          <GridSmall>
            {representatives.representativeIDs.map((repID) => (
              <RepresentativeCard key={repID} representativeData={representatives[repID]} />
            ))}
            {isInviting && (
              <InviteModal
                inviteType="representative"
                closeModal={() => setInviting(false)}
                input={representativeEmail}
                setInput={setRepresentativeEmail}
                onInvite={onInvite}
                isLoading={isSendingInvite}
              />
            )}
          </GridSmall>
        )}
      </Body>
    </Page>
  );
};

export default Representatives;
