import { sortArray } from "../../../utils/sort";
import { getDoc } from "../../../firestore";
import { v4 as uuidv4 } from "uuid";

export function fetchCampaigns({ userID }) {
  return getDoc(`suppliers/${userID}/private/campaigns`)
    .then((doc) => doc.data())
    .then((data) => data.campaigns)
    .then((campaigns) => campaigns.map((campaign) => ({ ...campaign, ID: uuidv4() })))
    .then((campaigns) => sortArray(campaigns, "timestamp"));
}

export function fetchRestaurants({ userID }) {
  return getDoc(`suppliers/${userID}/private/restaurants`).then((doc) => doc.data());
}

// export function setCampaign(campaignData) {
//   return createCampaign(campaignData);
// }

//   createCampaign(data)
//     .then(() => console.log("all gucci"))
//     .catch((err) => console.error("not all gucci", err));
//   setCreating(false);
//   setRecipients({});
//   setCampaignName("");
//   setCampaignText("");

//   // sendNotifications(recipients, data)
//   //   .then(() => console.log("all gucci"))
//   //   .catch((err) => console.error("not all gucci", err));

// useEffect(() => {
//   getDoc(`suppliers/${currentUser.uid}/private/restaurants`)
//     .then((doc) => {
//       setRestaurants(doc.data());
//     })
//     .catch((err) => console.error("Error getting restaurants", err));
// }, [currentUser.uid]);
