export function typeTranslate(userType) {
  if (userType === "type-res") return "restaurants";
  if (userType === "type-sup") return "suppliers";
  if (userType === "type-rep") return "representatives";
}

export function formatPrice(price) {
  return parseFloat(price).toLocaleString(navigator.language, { minimumFractionDigits: 2 });
}

export function formatTime(timestamp) {
  const now = new Date(Date.now());
  const dif = (now - timestamp.toDate()) / 1000 / 60;
  if (dif < 60) return dif.toFixed(0) + (dif.toFixed(0) === "1" ? " minute ago" : " minutes ago");
  else if (dif / 60 < 12)
    return (dif / 60).toFixed(0) + ((dif / 60).toFixed(0) === "1" ? " hour ago" : " hours ago");
  else if (dif / 60 / 24 < 5)
    return (
      (dif / 60 / 24).toFixed(0) + ((dif / 60 / 24).toFixed(0) === "1" ? " day ago" : " days ago")
    );
  else return timestamp.toDate().toLocaleDateString(navigator.language);
}
