import React, { useState } from "react";
import { withRouter, Link, useHistory, useLocation } from "react-router-dom";
import { RegisterModal } from "./RegisterModal";
import { Form } from "./Form";
import { RegisterButton } from "./RegisterButton";
import { useAuth } from "../../contexts/AuthContext";
import { fuduramaLogo } from "../../assets/icons";
import { Icon } from "../../components/Icon";
import { H1, H2 } from "../../components/TextStyles";
import { TextInput, TextareaInput, NumberInput } from "../../components/TextInput";

const ResetPassword = (props) => {
  const [email, setEmail] = useState("");
  const [isLoading, toggleLoading] = useState(false);
  const [errors, setErrors] = useState("");
  const { resetPassword } = useAuth();
  const [isResetComplete, setIsResetComplete] = useState(false);

  function findFieldErrors() {
    let tempErrors = {};
    if (email.length === 0) tempErrors.email = "Cannot be empty";

    setErrors(tempErrors);
    if (Object.keys(tempErrors).length === 0) return false;
    else return true;
  }

  async function handleSubmit(e) {
    e.preventDefault();
    if (!findFieldErrors()) {
      toggleLoading(true);
      try {
        toggleLoading(false);
        resetPassword(email).then((res) => setIsResetComplete(true));
        // const currentUser = await signin(email, password);
      } catch (error) {
        console.error("There was an error signing in", error);
        if (error.code === "auth/invalid-email") setErrors({ email: "Invalid email" });
        if (error.code === "auth/user-not-found") setErrors({ email: "User not found" });
        if (error.code === "auth/wrong-password") setErrors({ password: "Incorrect password" });

        toggleLoading(false);
      }
    }
  }

  return (
    <RegisterModal>
      <div style={{ position: "absolute", top: "2rem" }}>
        <a href="https://www.fudurama.com/">
          {/* <Icon width="8rem" style={{ margin: "-4rem auto 8rem auto" }}> */}
          <Icon width="8rem" style={{ margin: "auto" }}>
            {fuduramaLogo}
          </Icon>
        </a>
      </div>

      <div style={{ height: "2rem" }}></div>
      {isResetComplete ? (
        <div style={{ width: "70%" }}>
          <H2>An email with password reset instructions has been sent to {email} </H2>
          <div style={{ height: "2rem" }}></div>

          <p style={{ fontSize: "14px" }}>
            <Link style={{ fontWeight: "bold" }} to="/signin">
              Return to Sign In
            </Link>
          </p>
        </div>
      ) : (
        <Form id="signin-form" className="auth-form" onSubmit={handleSubmit}>
          <H2>Enter email to reset password</H2>
          <TextInput
            sharp
            error={errors.email}
            label="Email"
            value={email}
            placeholder="Email"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />

          <RegisterButton type="submit" value="Reset password" disabled={isLoading} />
          <p style={{ fontSize: "14px" }}>
            <Link style={{ fontWeight: "bold" }} to="/signin">
              Return to Sign In
            </Link>
          </p>
        </Form>
      )}
    </RegisterModal>
  );
};

export default ResetPassword;
