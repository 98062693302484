import React, { useEffect, useState } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { useProfile } from "../../../contexts/ProfileContext";
import { addRepresentative, addRestaurant } from "../../../firestore";
import { H3 } from "../../../components/TextStyles";
import { useAuth } from "../../../contexts/AuthContext";
import { Loader } from "../../../components/Loader";
import { Button } from "../../../components/Button";
import { RegisterModal } from "../RegisterModal";

export default function AddUser() {
  const { currentUser, userType } = useAuth();
  const history = useHistory();
  const urlParams = useLocation().search;
  const { profileData } = useProfile();
  const [unauthenticated, setUnauthenticated] = useState(false);
  const query = new URLSearchParams(urlParams);
  const representativeID = query.get("repID");
  const restaurantID = query.get("resID");

  function restaurantAddingRepresentative(representativeID) {
    const { fullName, email, restaurantName } = profileData;
    const data = {
      representativeID,
      email,
      restaurantID: currentUser.uid,
      restaurantName,
      fullName,
    };
    addRepresentative(data).then(() => history.push("/orders"));
  }

  function representativeAddingRestaurant(restaurantID) {
    const { fullName, email, supplierID, supplierName } = profileData;
    const data = {
      fullName,
      email,
      supplierID,
      supplierName,
      restaurantID,
      representativeID: currentUser.uid,
    };
    addRestaurant(data).then(() => history.push("/orders"));
  }

  function supplierAddingRestaurant(restaurantID) {
    const { fullName, email, supplierName } = profileData;
    const data = {
      fullName,
      email,
      supplierID: currentUser.uid,
      supplierName,
      restaurantID,
      representativeID: currentUser.uid,
    };
    addRestaurant(data).then(() => history.push("/orders"));
  }

  // useEffect(() => {
  //   if (!representativeID && !restaurantID) history.push("/orders");
  // }, [representativeID, restaurantID, history]);

  useEffect(() => {
    if (!currentUser) {
      setUnauthenticated(true);
    } else {
      if (userType === "type-res" && representativeID)
        restaurantAddingRepresentative(representativeID);
      else if (userType === "type-rep" && restaurantID)
        representativeAddingRestaurant(restaurantID);
      else if (userType === "type-sup" && restaurantID) supplierAddingRestaurant(restaurantID);
      else {
        console.log("going to orders for some reason");
        history.push("/orders");
      }
    }
  }, [currentUser, urlParams, history]);

  return (
    <>
      {unauthenticated ? (
        <RegisterModal small>
          <H3 style={{ marginTop: "1rem" }}>Do you have a Fudurama account?</H3>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "60%",
              height: "100%",
              alignItems: "center",
            }}
          >
            <Link
              to={
                representativeID
                  ? {
                      pathname: `/signup-restaurant`,
                      search: `?repID=${representativeID}`,
                    }
                  : {
                      pathname: `/signup-supplier`,
                      search: `?resID=${restaurantID}`,
                    }
              }
            >
              <Button color="white" backgroundColor="var(--blue)">
                Sign up
              </Button>
            </Link>
            <Link
              to={{
                pathname: `signin`,
                state: {
                  state: { addingUser: true, urlParams },
                },
              }}
            >
              <Button>Log in</Button>
            </Link>
          </div>
        </RegisterModal>
      ) : (
        <Loader />
      )}
    </>
  );
}
