// import sortArray from "../../utils/sort";
import { getDoc, addInvitedRestaurant } from "../../firestore";
import { typeTranslate } from "../../utils/misc";

export function fetchRestaurants({ userID, userType }) {
  const type = typeTranslate(userType);
  return getDoc(`${type}/${userID}/private/restaurants`).then((doc) => doc.data());
}

export function fetchInvites({ userID }) {
  return getDoc(`representatives/${userID}/private/invites`).then((doc) => doc.data().invites);
}

export function addInvitee({ userID, restaurantEmail }) {
  return addInvitedRestaurant({
    representativeID: userID,
    restaurantEmail,
  });
}

// export function sendInvite({ supplierID, representativeEmail }) {
//   inviteRepresentative({ supplierID, representativeEmail });
// }
