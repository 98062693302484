import styled from "styled-components";
import React from "react";
import { plusIcon } from "../assets/icons";
import { Icon } from "./Icon";

export const MobileButtonStyle = styled.button`
  position: fixed;
  bottom: 6rem;
  right: 0.5rem;
  border-radius: 10rem;
  background-color: white;
  padding: 1.5rem;
  box-shadow: 0px 3px 6px #3f5cb469;
  border: none;
`;

export default function MobileButton({ onClick }) {
  return (
    <MobileButtonStyle onClick={onClick}>
      <Icon fill="var(--blue)">{plusIcon}</Icon>
    </MobileButtonStyle>
  );
}
