import React, { useContext, useState, useEffect } from "react";
import { auth } from "../firebase";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);
  const [userType, setUserType] = useState(null);

  function signup(email, password) {
    return auth.createUserWithEmailAndPassword(email, password);
  }

  function signin(email, password) {
    return auth.signInWithEmailAndPassword(email, password);
  }

  // .then((user) => {
  //   user.user.getIdTokenResult().then((idTokenResult) => {
  //     const { userType } = idTokenResult.claims;
  //     console.log("setting user type to:", userType);
  //     setUserType(userType);
  //   });
  // });

  function signout() {
    return auth.signOut();
  }

  function resetPassword(email) {
    return auth.sendPasswordResetEmail(email);
  }

  // function reauthenticate() {
  //   const credential = promptForCredentials();

  //   user
  //     .reauthenticateWithCredential(credential)
  //     .then(() => {
  //       // User re-authenticated.
  //     })
  //     .catch((error) => {
  //       // An error ocurred
  //       // ...
  //     });
  // }

  function updateEmail(email) {
    return currentUser.updateEmail(email);
  }

  function updatePassword(password) {
    return currentUser.updatePassword(password);
  }

  useEffect(() => {
    const unsubscribe = auth.onIdTokenChanged((user) => {
      setLoading(true);
      setCurrentUser(user);
      if (user) {
        user.getIdTokenResult().then((idTokenResult) => {
          const { userType } = idTokenResult.claims;
          setUserType(userType);
          setLoading(false);
        });
      } else {
        setUserType(null);
        setLoading(false);
      }
    });

    return unsubscribe;
  }, []);

  // useEffect(() => {
  //   if (currentUser && !userType) {
  //     setLoading(true);
  //     currentUser.getIdTokenResult().then((idTokenResult) => {
  //       const { userType } = idTokenResult.claims;
  //       console.log("setting user type to:", userType);
  //       setUserType(userType);
  //       setLoading(false);
  //     });
  //   } else if (!currentUser && userType) {
  //     // console.log('setting user type to null');
  //     setLoading(true);
  //     setUserType(null);
  //     setLoading(false);
  //   }
  // }, [userType, currentUser]);

  const value = {
    currentUser,
    signin,
    signup,
    signout,
    resetPassword,
    updateEmail,
    updatePassword,
    userType,
  };

  return <AuthContext.Provider value={value}>{!loading && children}</AuthContext.Provider>;
}
