import React from "react";
import styled from "styled-components";

export const CartStyled = styled.div`
  flex-direction: column;
  display: ${(props) => (props.isOpen ? "flex" : "none")};
  height: 40rem;
  width: 30rem;
  background-color: white;
  position: absolute;
  top: 4rem;
  right: 0;
  border-radius: 1rem;
  box-shadow: 0px 3px 12px var(--faded-blue);
  z-index: 10;
`;

// export const CartPreview = styled.div`
//   display: flex;
//   flex-direction: row;
// `;

export const CartStyledMobile = styled.div`
  height: ${(props) => (props.isOpen ? "60%" : "0%")};
  width: 100%;
  border-radius: 1rem 1rem 0 0;
  background-color: white;
  color: var(--blue);
  position: fixed;
  bottom: 10rem;
  display: flex;
  flex-direction: ${(props) => (props.isOpen ? "column" : "row")};
  justify-content: space-between;
  align-items: ${(props) => (props.isOpen ? "auto" : "center")};
  transition: 0.9s ease;
  box-shadow: 0px 0px 15px var(--faded-blue);
`;

export const CartContent = styled.div`
  overflow-y: scroll;
  flex: 1;
  padding: 0rem 1rem;

  @media (max-width: 860px) {
    padding: 0rem 1rem;
  }
`;

export const CartFooter = styled.div`
  display: ${(props) => (props.isOpen ? "flex" : "none")};
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 12%;
  width: 100%;
  background-color: var(--blue);
  padding: 1rem;
  color: white;
  border-radius: 0 0 1rem 1rem;
`;

export const CartFooterMobile = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 5rem;
  width: 100%;
  background-color: var(--blue);
  padding: 0 1rem;
  color: white;
  border-radius: 0 0 1rem 1rem;
  border-radius: 0;
  position: fixed;
  bottom: 5rem;
`;
