function compare(a, b, field) {
  if (a[field] < b[field]) {
    return -1;
  }
  if (a[field] > b[field]) {
    return 1;
  }
  return 0;
}

function compareTwoVals(a, b, primarySortBy, secondarySortBy) {
  a = a[1];
  b = b[1];
  if (a[primarySortBy] < b[primarySortBy]) {
    return -1;
  }
  if (a[primarySortBy] > b[primarySortBy]) {
    return 1;
  }
  if (a[secondarySortBy] < b[secondarySortBy]) {
    return -1;
  }
  if (a[secondarySortBy] > b[secondarySortBy]) {
    return 1;
  }
  return 0;
}

export function sortArray(array, field) {
  return array.sort((a, b) => compare(b, a, field));
}

export function sortObject(obj, primaryField, secondaryField) {
  const temp = Object.entries(obj);
  const sorted = Object.fromEntries(
    temp.sort((a, b) => compareTwoVals(a, b, primaryField, secondaryField))
  );
  return sorted;
}
