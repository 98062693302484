import styled from "styled-components";

export const IconButton = styled.button`
  border-radius: 2.5rem;
  /* background-color: white;
  fill: var(--blue); */
  background-color: var(--blue);
  fill: white;
  /* color: white; */
  /* box-shadow: 0px 1px 1px rgba(63, 92, 180, 0.5), 0px 2px 2px rgba(63, 92, 180, 0.37),
    0px 0px 4px rgba(63, 92, 180, 0.4); */
  border: none;
  outline: none;
  cursor: pointer;
  width: 2.25rem;
  height: 2.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  /* > svg {
    width: 50%;
    height: 50%;
    display: block;
    margin: auto;
  } */

  /* &:hover {
    box-shadow: 0px 1px 1px rgba(63, 92, 180, 0.3), 0px 0px 2px rgba(63, 92, 180, 0.3);
  }

  &:active {
    box-shadow: inset 0px 0px 2px rgba(63, 92, 180, 0.75);
  } */
`;
