import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import SignupRestaurant from "./pages/Authentication/SignupRestaurant";
import SignupSupplier from "./pages/Authentication/SignupSupplier";
import SignupRepresentative from "./pages/Authentication/SignupRepresentative";
import Signin from "./pages/Authentication/Signin";
import Logout from "./pages/Authentication/Logout";
import AddUser from "./pages/Authentication/AddUser";
// import LandingWrapper from "./components/LandingWrapper";
import { useAuth } from "./contexts/AuthContext";
import PrivateRoute from "./components/PrivateRoute";
import RepresentativeApp from "./pages/RepresentativeApp";
import RestaurantApp from "./pages/RestaurantApp";
import SupplierApp from "./pages/SupplierApp";
import { Redirect } from "react-router-dom";
import ResetPassword from "./pages/Authentication/ResetPassword";

const App = () => {
  const { userType } = useAuth();
  return (
    <Switch>
      <Route exact path="/signin" component={Signin} />
      <Route exact path="/reset-password" component={ResetPassword} />
      <Route exact path="/signup-restaurant" component={SignupRestaurant} />
      <Route exact path="/signup-supplier" component={SignupSupplier} />
      <Route exact path="/signup-representative" component={SignupRepresentative} />
      <Route exact path="/add-user" component={AddUser} />
      <Route exact path="/logout" component={Logout} />
      <PrivateRoute>
        <AppSwitcher userType={userType} />
      </PrivateRoute>
    </Switch>
  );
};

export default App;

const AppSwitcher = ({ userType }) => {
  if (userType === "type-res") {
    return <RestaurantApp />;
  } else if (userType === "type-sup") {
    return <SupplierApp />;
  } else if (userType === "type-rep") {
    return <RepresentativeApp />;
  } else {
    return <Redirect to="/signin" />;
  }
};
