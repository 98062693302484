import React, { useState, useEffect, useMemo } from "react";
import Text from "../../languages/Text";
import { Grid } from "../../components/Grid";
import { H1 } from "../../components/TextStyles";
import { Loader } from "../../components/Loader.js";
import { SearchBar } from "../../components/SearchBar";
import { Filter, DateFilter } from "../../components/Filter";
import useSearch from "../../hooks/useSearch";
import useFetch from "../../hooks/useFetch";
import { fetchOrders } from "../../pages/Orders/data";
import { useAuth } from "../../contexts/AuthContext";
import OrderCard from "./OrderCard";
import { Body, UtilBar, Page, Header } from "../../components/Layout/Layout";

const Orders = ({ isMobile }) => {
  const { userType, currentUser } = useAuth();
  const { uid: userID } = currentUser;
  const [filteredOrders, setFilteredOrders] = useState(null);
  const [options, setOptions] = useState({
    placed: true,
    unplaced: true,
    cancelled: true,
    completed: true,
  });
  const functionArgs = useMemo(() => ({ userID, userType }), [userID, userType]);
  const filterText = <Text vid="common" tid="filterByOrder" capitalize />;
  const filterDateText = <Text vid="common" tid="filterByDate" capitalize />;
  const selectStatusText = <Text vid="order" tid="selectStatus" capitalize />;
  const selectDateText = <Text vid="order" tid="selectDate" capitalize />;
  const searchText = Text({ vid: "common", tid: "searchByName", capitalize: true });
  const search = useSearch();
  const [query, setQuery] = useState(search);
  const { data: originalOrders, isLoading } = useFetch(fetchOrders, functionArgs);
  const [dateFilters, setDateFilters] = useState();

  function filterDates(dateFilters, orderTimestamp) {
    return dateFilters
      ? dateFilters.endDate
        ? orderTimestamp.toDate() > dateFilters.startDate &&
          orderTimestamp.toDate() < dateFilters.endDate
        : orderTimestamp.toDate().toDateString() === dateFilters.startDate.toDateString()
      : true;
  }

  function filterOrders() {
    if (userType === "type-sup") {
      return originalOrders.filter(
        (order) =>
          (order.restaurantName.toLowerCase().includes(query.toLowerCase()) ||
            order.representativeName.toLowerCase().includes(query.toLowerCase())) &&
          options[order.status] &&
          filterDates(dateFilters, order.timestamp)
      );
    } else if (userType === "type-res") {
      return originalOrders.filter(
        (order) =>
          (order.supplierName.toLowerCase().includes(query.toLowerCase()) ||
            order.representativeName.toLowerCase().includes(query.toLowerCase())) &&
          options[order.status] &&
          filterDates(dateFilters, order.timestamp)
      );
    } else if (userType === "type-rep") {
      return originalOrders.filter(
        (order) =>
          order.restaurantName.toLowerCase().includes(query.toLowerCase()) &&
          options[order.status] &&
          filterDates(dateFilters, order.timestamp)
      );
    }
  }

  useEffect(() => {
    if (originalOrders) setFilteredOrders(filterOrders());
  }, [originalOrders, options, query, dateFilters]);

  function generateOrders() {
    return filteredOrders.map((order) => <OrderCard key={order.orderID} order={order} />);

    // if (isMobile) {
    //   return filteredOrders.map((order) => <OrderCard key={order.orderID} order={order} />);
    // } else {
    //   return (
    //     filteredOrders
    //       // .slice(page * PER_PAGE, page * PER_PAGE + PER_PAGE)
    //       .map((order) => <OrderCard key={order.orderID} order={order} />)
    //   );
    // }
  }

  function applyDateFilter(datesObj) {
    setDateFilters(datesObj);
  }

  function removeDateFilter() {
    setDateFilters();
  }

  return (
    <Page>
      <Header>
        <H1>
          <Text vid={"common"} tid={"orders"} capitalize={true} />
        </H1>
      </Header>
      <UtilBar>
        <SearchBar
          placeholder={searchText}
          type="search"
          value={query}
          onChange={(e) => {
            setQuery(e.target.value);
          }}
        />

        <div style={{ display: "flex" }}>
          <DateFilter
            preClickText={filterDateText}
            postClickText={selectDateText}
            applyFilter={applyDateFilter}
            removeFilter={removeDateFilter}
          />

          <Filter
            preClickText={filterText}
            postClickText={selectStatusText}
            options={options}
            setOptions={setOptions}
          />
        </div>
      </UtilBar>
      <Body>{isLoading ? <Loader /> : <Grid>{generateOrders()}</Grid>}</Body>
    </Page>
  );
};

export default Orders;
