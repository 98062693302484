import React from "react";
import { CardLink, CardContent, CardFooter, LinkText } from "../../../components/Layout/Card";
import { H3 } from "../../../components/TextStyles";
import Text from "../../../languages/Text";

export default function SupplierCard({ supplierData }) {
  const {
    fullName: representativeName,
    email: representativeEmail,
    supplierID,
    supplierName,
    representativeID,
  } = supplierData;
  return (
    <CardLink
      colSpan={3}
      to={{
        pathname: `catalogue/${supplierID}`,
        state: { representativeID, representativeName },
      }}
    >
      {/* <CardHeader></CardHeader> */}
      <CardContent>
        <H3>{supplierName}</H3>
        <div>
          <p>{representativeName}</p>
          <p>{representativeEmail}</p>
        </div>
      </CardContent>
      <CardFooter>
        <div></div>
        <LinkText>
          <Text capitalize vid={"order"} tid={"createOrder"} />
        </LinkText>
      </CardFooter>
    </CardLink>
  );
}
