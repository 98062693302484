export const fuduramaLogo = (
  <svg
    className="fudurama-logo"
    xmlns="http://www.w3.org/2000/svg"
    // width="54.848"
    // height="34.881"
    viewBox="0 0 54.848 34.881"
  >
    <path
      d="M42.855.271a10.316,10.316,0,0,0-4.371,2.736L37.17,4.251l-1.528-.924a16.136,16.136,0,0,0-8.173-2.061c-6.29,0-8.031,1.066-17.945,11.051-4.406,4.406-8.208,8.422-8.493,8.99A9.455,9.455,0,0,0,4.8,33.78a7.127,7.127,0,0,0,4.619,1.1c3.056,0,4.335-.533,6.645-2.736l1.492-1.457,1.173.711a16.876,16.876,0,0,0,15.92.746c1.954-.96,3.482-2.274,10.234-8.955,8.386-8.279,9.523-9.772,9.914-12.934A9.494,9.494,0,0,0,46.124.022,11.2,11.2,0,0,0,42.855.271Zm4.939,5.117c1.954.995,2.736,4.051,1.635,6.147a24.769,24.769,0,0,1-2.985,3.411l-2.487,2.416-.426-2.487a18.951,18.951,0,0,0-1.635-4.9c-1.137-2.31-1.173-2.416-.569-3.091A5.419,5.419,0,0,1,47.794,5.388ZM31.875,6.774a5.246,5.246,0,0,1,1.492.853c.107.178-3.411,3.873-7.782,8.244l-7.96,7.96-.817-1.741a12.215,12.215,0,0,1,.178-9.488,13.207,13.207,0,0,1,6.4-5.97A13.875,13.875,0,0,1,31.875,6.774Zm6.538,7.249a11.484,11.484,0,0,1-10.2,14.924c-2.381.178-5.508-.5-6.645-1.457-.355-.284,1.279-2.132,7.427-8.279s8.031-7.853,8.386-7.533A7.089,7.089,0,0,1,38.413,14.023ZM11.3,19.78a14.064,14.064,0,0,0,1.457,4.868l1.315,2.7L12.9,28.556a4.5,4.5,0,0,1-5.224,1.315,4.665,4.665,0,0,1-2.594-5.934c.284-.64,5.4-6.218,5.792-6.254A5.309,5.309,0,0,1,11.3,19.78Z"
      transform="translate(0 0)"
    />
  </svg>
);

export const notificationsIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25.953"
    height="31.144"
    viewBox="0 0 25.953 31.144"
  >
    <path d="M17.047,5.119A2.729,2.729,0,0,1,15.7,2.758v0a2.721,2.721,0,1,0-5.441,0v0A2.724,2.724,0,0,1,8.9,5.119C2.848,8.638,6.329,20.321,0,22.386v2.27H25.953v-2.27C19.624,20.321,23.1,8.638,17.047,5.119ZM12.976,1.3a1.3,1.3,0,1,1-1.3,1.3A1.3,1.3,0,0,1,12.976,1.3Zm3.893,25.953a3.979,3.979,0,0,1-3.855,3.893,4.046,4.046,0,0,1-3.931-3.893Z" />
  </svg>
);

export const burgerMenu = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path d="M24 6h-24v-4h24v4zm0 4h-24v4h24v-4zm0 8h-24v4h24v-4z" />
  </svg>
);

export const restaurantsIcon = (
  <svg
    width="24"
    height="24"
    xmlns="http://www.w3.org/2000/svg"
    fillRule="evenodd"
    clipRule="evenodd"
    viewBox="0 0 24 24"
  >
    <path d="M11.415 12.393l1.868-2.289c.425-.544.224-.988-.055-2.165-.205-.871-.044-1.854.572-2.5 1.761-1.844 5.343-5.439 5.343-5.439l.472.37-3.693 4.728.79.617 3.87-4.59.479.373-3.558 4.835.79.618 3.885-4.58.443.347-3.538 4.85.791.617 3.693-4.728.433.338s-2.55 4.36-3.898 6.535c-.479.771-1.425 1.161-2.334 1.167-1.211.007-1.685-.089-2.117.464l-2.281 2.795c2.445 2.962 4.559 5.531 5.573 6.829.771.987.065 2.421-1.198 2.421-.42 0-.853-.171-1.167-.573l-8.36-10.072s-.926.719-1.944 1.518c-3.172-5.184-6.267-11.661-6.267-13.955 0-.128-.034-.924.732-.924.245 0 .493.116.674.344.509.642 5.415 6.513 10.002 12.049m-2.952 3.617l1.953 2.365-4.115 5.055c-.295.378-.736.576-1.182.576-1.198 0-1.991-1.402-1.189-2.428l4.533-5.568z" />
  </svg>
);

export const ordersIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="20.87" height="20" viewBox="0 0 20.87 20">
    <path d="M1.565,6.957.87,3.478H20l-.7,3.478H17.54l.339-1.739H2.99l.36,1.739ZM17.717,1.739,18.243,0H2.609l.6,1.739ZM20.87,8.7,19.13,20H1.739L0,8.7Zm-6.957,3.478a.869.869,0,0,0-.87-.87H7.826a.87.87,0,0,0,0,1.739h5.217A.869.869,0,0,0,13.913,12.174Z" />
  </svg>
);

export const catalogueIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="23.834" height="26" viewBox="0 0 23.834 26">
    <path d="M23.834,11.8v13.12A1.084,1.084,0,0,1,22.75,26h-13a1.084,1.084,0,0,1-1.083-1.083V8.667A1.084,1.084,0,0,1,9.75,7.583H23.834Zm-11.917,9.87h8.667V22.75H11.917ZM6.5,8.667a3.25,3.25,0,0,1,3.25-3.25h7.314L14.83.626A1.083,1.083,0,0,0,13.846,0a1.067,1.067,0,0,0-.456.1L.626,6.054A1.082,1.082,0,0,0,.1,7.493L6.5,21.215Zm5.416,6.013a5.228,5.228,0,0,0,1.8,3.737,3.93,3.93,0,0,0,5.074,0,5.228,5.228,0,0,0,1.8-3.737,2.867,2.867,0,0,0-2.592-3.071A2.8,2.8,0,0,0,16.6,11.9c-.009-.181-.028-.362-.054-.537a2.607,2.607,0,0,0,1.476-.281A2.139,2.139,0,0,0,18.948,9.8a2.534,2.534,0,0,0-1.559.168,2.525,2.525,0,0,0-.913,1.008A3.722,3.722,0,0,0,16,9.75l-.846.38a3.544,3.544,0,0,1,.832,1.831,3.063,3.063,0,0,0-1.482-.353A2.867,2.867,0,0,0,11.917,14.679Zm3.068,2.5c-1-1.95-.757-3.325.26-4.086a1.638,1.638,0,0,0-1.716,1.643A3.153,3.153,0,0,0,14.985,17.179Z" />
  </svg>
);

export const campaignsIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path d="M4.574 16.989c-1.768.647-3.719-.275-4.365-2.06-.647-1.785.262-3.757 2.027-4.404l3.242-1.187 2.338 6.464-3.242 1.187zm6.282 3.172c-.437-.151-.814-.43-1.089-.8l-1.635-2.202-3.301 1.209 2.602 3.353c.292.376.79.52 1.237.356l2.216-.81c.229-.084.382-.307.381-.553-.002-.246-.156-.464-.389-.545l-.022-.008zm-4.09-11.294l2.338 6.464c2.155-.417 5.077-.401 8.896.401l-4.675-12.927c-2.476 3.165-4.663 5.004-6.559 6.062zm10.795-3.102c.856.411 1.556 1.149 1.893 2.117.339.967.254 1.98-.157 2.836l1.407.678c.585-1.216.708-2.656.227-4.03-.481-1.375-1.474-2.424-2.689-3.009l-.681 1.408zm1.188-2.465c1.486.715 2.698 1.998 3.286 3.678s.438 3.441-.277 4.927l1.443.696c.893-1.857 1.079-4.055.346-6.153-.735-2.097-2.247-3.698-4.102-4.591l-.696 1.443z" />
  </svg>
);

export const analyticsIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path d="M7 24h-6v-6h6v6zm8-9h-6v9h6v-9zm8-4h-6v13h6v-13zm0-11l-6 1.221 1.716 1.708-6.85 6.733-3.001-3.002-7.841 7.797 1.41 1.418 6.427-6.39 2.991 2.993 8.28-8.137 1.667 1.66 1.201-6.001z" />
  </svg>
);

export const representativesIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path d="M10.644 17.08c2.866-.662 4.539-1.241 3.246-3.682-3.932-7.427-1.042-11.398 3.111-11.398 4.235 0 7.054 4.124 3.11 11.398-1.332 2.455.437 3.034 3.242 3.682 2.483.574 2.647 1.787 2.647 3.889v1.031h-18c0-2.745-.22-4.258 2.644-4.92zm-12.644 4.92h7.809c-.035-8.177 3.436-5.313 3.436-11.127 0-2.511-1.639-3.873-3.748-3.873-3.115 0-5.282 2.979-2.333 8.549.969 1.83-1.031 2.265-3.181 2.761-1.862.43-1.983 1.34-1.983 2.917v.773z" />
  </svg>
);

export const settingsIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path d="M24 13.616v-3.232c-1.651-.587-2.694-.752-3.219-2.019v-.001c-.527-1.271.1-2.134.847-3.707l-2.285-2.285c-1.561.742-2.433 1.375-3.707.847h-.001c-1.269-.526-1.435-1.576-2.019-3.219h-3.232c-.582 1.635-.749 2.692-2.019 3.219h-.001c-1.271.528-2.132-.098-3.707-.847l-2.285 2.285c.745 1.568 1.375 2.434.847 3.707-.527 1.271-1.584 1.438-3.219 2.02v3.232c1.632.58 2.692.749 3.219 2.019.53 1.282-.114 2.166-.847 3.707l2.285 2.286c1.562-.743 2.434-1.375 3.707-.847h.001c1.27.526 1.436 1.579 2.019 3.219h3.232c.582-1.636.75-2.69 2.027-3.222h.001c1.262-.524 2.12.101 3.698.851l2.285-2.286c-.744-1.563-1.375-2.433-.848-3.706.527-1.271 1.588-1.44 3.221-2.021zm-12 2.384c-2.209 0-4-1.791-4-4s1.791-4 4-4 4 1.791 4 4-1.791 4-4 4z" />
  </svg>
);

export const deleteIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16.551"
    height="20.371"
    viewBox="0 0 16.551 20.371"
  >
    <g transform="translate(-48)">
      <path d="M62.96,2.546h-3.5V1.91A1.91,1.91,0,0,0,57.549,0H55a1.91,1.91,0,0,0-1.91,1.91v.637h-3.5A1.591,1.591,0,0,0,48,4.138V5.411a.637.637,0,0,0,.637.637H63.915a.637.637,0,0,0,.637-.637V4.138A1.592,1.592,0,0,0,62.96,2.546ZM54.366,1.91A.637.637,0,0,1,55,1.273h2.546a.637.637,0,0,1,.637.637v.637h-3.82Z" />
      <path
        d="M73.563,184a.2.2,0,0,0-.2.208l.525,11.023A1.907,1.907,0,0,0,75.8,197.05h9.667a1.907,1.907,0,0,0,1.907-1.819l.525-11.023a.2.2,0,0,0-.2-.208Zm9.614,1.591a.637.637,0,1,1,1.273,0v8.276a.637.637,0,1,1-1.273,0Zm-3.183,0a.637.637,0,1,1,1.273,0v8.276a.637.637,0,1,1-1.273,0Zm-3.183,0a.637.637,0,1,1,1.273,0v8.276a.637.637,0,1,1-1.273,0Z"
        transform="translate(-24.355 -176.679)"
      />
    </g>
  </svg>
);

export const editIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17.117"
    height="17.117"
    viewBox="0 0 17.117 17.117"
  >
    <g transform="translate(0 11.811)">
      <path d="M1.712,353.28,0,358.586l5.306-1.712Z" transform="translate(0 -353.28)" />
    </g>
    <g transform="translate(2.914 2.23)">
      <g transform="translate(0 0)">
        <rect width="11.811" height="5.135" transform="translate(0 8.351) rotate(-45)" />
      </g>
    </g>
    <g transform="translate(12.496)">
      <path
        d="M378.125,2.653l-2.4-2.4a.827.827,0,0,0-1.2,0l-.77.77,3.595,3.595.77-.77A.827.827,0,0,0,378.125,2.653Z"
        transform="translate(-373.76)"
      />
    </g>
  </svg>
);

export const plusIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path d="M24 9h-9v-9h-6v9h-9v6h9v9h6v-9h9z" />
  </svg>
);

export const minusIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path d="M0 9h24v6h-24z" />
  </svg>
);

export const caretIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
  </svg>
);

export const caretUp = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g transform="translate(0 24) rotate(270)">
      <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
    </g>
    {/* <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" /> */}
  </svg>
);

export const caretDown = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g transform="translate(24 0) rotate(90)">
      <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
    </g>
    {/* <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" /> */}
  </svg>
);

export const caretLeft = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g transform="translate(24 24) rotate(180)">
      <path d="M1.408,0,0,1.41,4.574,6,0,10.59,1.408,12,7.4,6Z" transform="translate(8.6 6)" />
    </g>
  </svg>
);

export const caretRight = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g>
      <path d="M1.408,0,0,1.41,4.574,6,0,10.59,1.408,12,7.4,6Z" transform="translate(8.6 6)" />
    </g>
  </svg>
);

export const arrowRight = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30.365"
    height="14.708"
    viewBox="0 0 30.365 14.708"
  >
    <path
      d="M30.017,138.515h0l-6.2-6.168a1.186,1.186,0,0,0-1.674,1.681l4.161,4.141H1.186a1.186,1.186,0,0,0,0,2.372h25.12l-4.161,4.141a1.186,1.186,0,0,0,1.674,1.681l6.2-6.168h0A1.187,1.187,0,0,0,30.017,138.515Z"
      transform="translate(0 -132)"
    />
  </svg>
);

export const backIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="9.484" height="17.56" viewBox="0 0 9.484 17.56">
    <g transform="translate(127.226 17.56) rotate(180)">
      <g transform="translate(117.742 0)">
        <path
          d="M127.026,8.3,118.9.2a.681.681,0,0,0-.961.964l7.639,7.618L117.942,16.4a.681.681,0,0,0,.961.964l8.122-8.1a.68.68,0,0,0,0-.964Z"
          transform="translate(-117.742 0)"
        />
      </g>
    </g>
  </svg>
);

export const searchIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14.722"
    height="14.722"
    viewBox="0 0 14.722 14.722"
  >
    <path d="M14.588,13.921l-3.8-3.74A6.012,6.012,0,0,0,12.394,6.1,6.146,6.146,0,0,0,6.2,0,6.145,6.145,0,0,0,.006,6.1a6.145,6.145,0,0,0,6.194,6.1,6.229,6.229,0,0,0,3.9-1.361l3.816,3.755a.482.482,0,0,0,.674,0A.464.464,0,0,0,14.588,13.921ZM6.2,11.253A5.2,5.2,0,0,1,.959,6.1,5.2,5.2,0,0,1,6.2.938,5.2,5.2,0,0,1,11.441,6.1,5.2,5.2,0,0,1,6.2,11.253Z" />
  </svg>
);

export const trashIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16.551"
    height="20.371"
    viewBox="0 0 16.551 20.371"
  >
    <g transform="translate(-48)">
      <path d="M62.96,2.546h-3.5V1.91A1.91,1.91,0,0,0,57.549,0H55a1.91,1.91,0,0,0-1.91,1.91v.637h-3.5A1.591,1.591,0,0,0,48,4.138V5.411a.637.637,0,0,0,.637.637H63.915a.637.637,0,0,0,.637-.637V4.138A1.592,1.592,0,0,0,62.96,2.546ZM54.366,1.91A.637.637,0,0,1,55,1.273h2.546a.637.637,0,0,1,.637.637v.637h-3.82Z" />
      <path
        d="M73.563,184a.2.2,0,0,0-.2.208l.525,11.023A1.907,1.907,0,0,0,75.8,197.05h9.667a1.907,1.907,0,0,0,1.907-1.819l.525-11.023a.2.2,0,0,0-.2-.208Zm9.614,1.591a.637.637,0,1,1,1.273,0v8.276a.637.637,0,1,1-1.273,0Zm-3.183,0a.637.637,0,1,1,1.273,0v8.276a.637.637,0,1,1-1.273,0Zm-3.183,0a.637.637,0,1,1,1.273,0v8.276a.637.637,0,1,1-1.273,0Z"
        transform="translate(-24.355 -176.679)"
      />
    </g>
  </svg>
);

export const closeIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17.676"
    height="17.676"
    viewBox="0 0 17.676 17.676"
  >
    <path
      d="M10.457,8.974,17.34,2.091A1.145,1.145,0,0,0,15.721.472L8.838,7.355,1.955.472A1.145,1.145,0,0,0,.336,2.091L7.219,8.974.336,15.857a1.145,1.145,0,1,0,1.619,1.619l6.883-6.883,6.883,6.883a1.145,1.145,0,0,0,1.619-1.619Zm0,0"
      transform="translate(0 -0.136)"
    />
  </svg>
);

export const sortIconDown = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path d="M24 11.871l-5-4.871v3h-19v4h19v3z" transform="rotate(90, 12, 12)" />
  </svg>
);

export const sortIconUp = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path d="M24 11.871l-5-4.871v3h-19v4h19v3z" transform="rotate(270, 12, 12)" />
  </svg>
);

export const cartIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <circle cx="2.764" cy="2.764" r="2.764" transform="translate(6.556 18.479)" />
    <path
      d="M269.261,324.1h0a2.764,2.764,0,1,0,2.764,2.764A2.764,2.764,0,0,0,269.261,324.1Z"
      transform="translate(-251.312 -305.619)"
    />
    <path
      d="M29.539,15.595H14.562l-.293-1.7H26.579a.879.879,0,0,0,.821-.556L30.33,5.968a.878.878,0,0,0-.821-1.2H12.745l-.7-4.038A.879.879,0,0,0,11.163,0H7.792a.878.878,0,1,0,0,1.756H10.43l2.52,14.864a.879.879,0,0,0,.879.731H29.539a.878.878,0,1,0,0-1.756Z"
      transform="translate(-6.913 0.001)"
    />
  </svg>
);

export const addToCartIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17.905"
    height="17.216"
    viewBox="0 0 17.905 17.216"
  >
    <path d="M17.805,7.483c-2.881,6.1-3.818,6.1-4.377,6.1H7.919a3.637,3.637,0,0,1-3.767-2.892c-.011-.054-1.1-5.408-1.381-6.9-.137-.733-.485-1.222-1.739-1.222A1.033,1.033,0,1,1,1.033.5,3.448,3.448,0,0,1,4.8,3.409c.276,1.48,1.363,6.816,1.375,6.87a1.6,1.6,0,0,0,1.742,1.24h5.237A29.132,29.132,0,0,0,15.937,6.6a1.033,1.033,0,0,1,1.868.882ZM7.231,14.273a1.722,1.722,0,1,0,1.722,1.722A1.721,1.721,0,0,0,7.231,14.273Zm6.2,0a1.722,1.722,0,1,0,1.722,1.722A1.721,1.721,0,0,0,13.428,14.273ZM10.1,8.656a.385.385,0,0,0,.477,0,25.59,25.59,0,0,0,2.972-3.411.385.385,0,0,0-.318-.6H11.7s-.149-2.955-.247-3.092a1.735,1.735,0,0,0-1.113-.34,1.772,1.772,0,0,0-1.125.34c-.1.136-.226,3.092-.226,3.092H7.431a.385.385,0,0,0-.32.6A25.349,25.349,0,0,0,10.1,8.656Z" />
  </svg>
);

export const filterIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17.562"
    height="15.989"
    viewBox="0 0 17.562 15.989"
  >
    <path
      d="M.975,90.712h9.83V88.761H.975a.975.975,0,0,0,0,1.951Z"
      transform="translate(0 -86.16)"
    />
    <path
      d="M.975,193.269h9.83v-1.951H.975a.975.975,0,0,0,0,1.951Z"
      transform="translate(0 -184.802)"
    />
    <path
      d="M.975,293.874h0a.975.975,0,0,0,0,1.951H7.8a2.41,2.41,0,0,1,.206-1.951Z"
      transform="translate(0 -283.443)"
    />
    <path
      d="M240.387,31.373a1.234,1.234,0,0,0-1.745,0l-1.013,1.013V21.843a1.234,1.234,0,0,0-2.467,0V32.386l-1.013-1.013a1.234,1.234,0,0,0-1.745,1.745l3.119,3.119a1.234,1.234,0,0,0,1.745,0l3.119-3.119A1.234,1.234,0,0,0,240.387,31.373Z"
      transform="translate(-223.186 -20.609)"
    />
  </svg>
);
