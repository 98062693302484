/* eslint-disable import/prefer-default-export */
import styled from "styled-components";

export const GridBase = styled.div`
  padding: 1.5rem 2rem 5rem 2rem;
  width: 100%;
  justify-content: center;
  align-items: center;
  overflow: visible;

  @media (max-width: 860px) {
    padding: 1.5rem 1rem 4rem 1rem;
  }
`;

export const Grid = styled(GridBase)`
  padding: ${(props) => (props.noPad ? "0" : "1.5rem 2rem 5rem 2rem")};
  column-gap: 1.25rem;
  row-gap: 1.25rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(22rem, 1fr));
  grid-auto-rows: 10rem;

  @media (max-width: 860px) {
    padding: ${(props) => (props.noPad ? "0" : "1.5rem 1rem 4rem 1rem")};
  }
`;

export const GridSmall = styled(Grid)`
  grid-template-columns: repeat(auto-fill, minmax(14rem, 1fr));
  grid-auto-rows: 7rem;
`;

export const GridBig = styled(Grid)`
  /* grid-template-columns: repeat(auto-fill, minmax(30rem, 1fr)); */
  /* grid-template-rows: repeat(auto-fit, minmax(20rem, 1fr)); */
  grid-auto-rows: 16rem;
`;
