import { getCatalogue, updateCatalogue, getDoc, sendOrder } from "../../../firestore";
import { sortObject } from "../../../utils/sort";

export function fetchCatalogue({ supplierID }) {
  return getCatalogue(supplierID)
    .then((doc) => doc.data())
    .then((data) => {
      const { catalogue, supplierName } = data;
      const sortedCatalogue = sortObject(catalogue, "category", "name");
      // let categories = new Set(["all", "promotion"]);
      let categories = new Set();

      Object.values(sortedCatalogue).forEach(({ category }) => {
        categories.add(category);
      });
      categories = Array.from(categories);
      return { catalogue: sortedCatalogue, categories, supplierName };
    });
}

export function submitOrder({ finalOrder }) {
  if (!finalOrder.representativeID) {
    return getDoc(`restaurants/${finalOrder.restaurantID}/private/representatives`)
      .then((doc) => {
        const representativesData = doc.data();
        const { representativeIDs } = representativesData;
        representativeIDs.forEach((repID) => {
          const representative = representativesData[repID];
          if (representative.supplierID === finalOrder.supplierID) {
            const { fullName: representativeName } = representative;
            finalOrder = { ...finalOrder, representativeID: repID, representativeName };
          }
        });
        return finalOrder;
      })
      .then((order) => {
        return sendOrder(order);
      })
      .catch((err) => console.error(err));
  } else {
    return sendOrder(finalOrder).catch((err) => console.error(err));
  }
}

// function onOrderSubmit() {
//   const orderInfo = {
//     representativeID: repData.representativeID,
//     representativeName: repData.representativeName,
//     supplierName,
//     supplierID,
//     restaurantID,
//     restaurantName,
//     status: "unplaced",
//     requestedDate,
//     restaurantNote: note,
//     source: "restaurant",
//   };
//   const orderItems = {};
//   Object.keys(order).forEach((ID) => {
//     delete catalogue[ID].quantity;
//     orderItems[ID] = { quantity: order[ID], ...catalogue[ID] };
//   });

//   const finalOrder = { ...orderInfo, products: orderItems };
//   sendOrder(finalOrder)
//     .then(() => {
//       console.log("order submitted");
//       history.push("/orders");
//     })
//     .catch((err) => console.error("there was an error submitting order", err));
// }

export function fetchRepresentativeData(supplierID, restaurantID) {
  return getDoc(`restaurants/${restaurantID}/private/representatives`)
    .then((doc) => {
      const representativesData = doc.data();
      const { representativeIDs } = representativesData;
      representativeIDs.forEach((repID) => {
        const representative = representativesData[repID];
        const { fullName: representativeName } = representative;
        if (representative.supplierID === supplierID) {
          return { representativeID: repID, representativeName };
        }
      });
    })
    .catch((err) => console.error("problem getting representatives", err));
}

// else {
//   getDoc(`restaurants/${restaurantID}/private/representatives`)
//     .then((doc) => {
//       const representativesData = doc.data();
//       const { representativeIDs } = representativesData;
//       representativeIDs.forEach((repID) => {
//         const representative = representativesData[repID];
//         const { fullName: representativeName } = representative;
//         if (representative.supplierID === supplierID) {
//           setRepData({ representativeID: repID, representativeName });
//         }
//       });
//     })
//     .catch((err) => console.error("problem getting representatives", err));
// }
