import React from "react";
import Text from "../languages/Text";
import { Button } from "./Button";
import Modal, { ModalHeader, ModalContent, ModalInput, ModalFooter } from "./Modal";
import { H1 } from "./TextStyles";
import { TextInput } from "./TextInput";
// import { ModalLoader } from "./ModalLoader";

export default function InviteModal({
  input,
  setInput,
  inviteType,
  closeModal,
  onInvite,
  isLoading,
}) {
  const componentsBasedOnUser = (() => {
    if (inviteType === "representative") {
      return {
        title: <Text vid={"representatives"} tid={"addRepresentative"} />,
        buttonText: <Text vid={"representatives"} tid={"addRepresentative"} />,
      };
    } else if (inviteType === "restaurant") {
      return {
        title: <Text vid={"restaurants"} tid={"addRestaurant"} />,
        buttonText: <Text vid={"restaurants"} tid={"addRestaurant"} />,
      };
    } else if (inviteType === "supplier") {
      return {
        title: <Text vid={"suppliers"} tid={"addSupplier"} />,
        buttonText: <Text vid={"suppliers"} tid={"addSupplier"} />,
      };
    }
  })();

  return (
    <Modal single onClose={closeModal} style width="36rem">
      <ModalHeader>
        <H1 capitalize>{componentsBasedOnUser.title}</H1>
      </ModalHeader>
      <ModalContent>
        <TextInput
          // error={errors.category}
          label="Email"
          value={input}
          onChange={(e) => {
            setInput(e.target.value);
          }}
        />
      </ModalContent>
      <ModalFooter>
        <Button type="submit" onClick={onInvite} disabled={isLoading}>
          {componentsBasedOnUser.buttonText}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
