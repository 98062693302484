// import sortArray from "../../utils/sort";
import { getDoc, updateField } from "../../firestore";

export function fetchOrder({ orderID }) {
  return getDoc(`orders/${orderID}`).then((doc) => {
    return doc.data();
  });
  // const orderRef = getOrderRef(orderID);
  // const unsubscribe = orderRef.onSnapshot((doc) => {
  //   let data = doc.data();
  // });
  // return unsubscribe;
}

export function changeOrderStatus(orderID, updatedOrder) {
  return updateField({
    collection: "orders",
    doc: orderID,
    newData: updatedOrder,
  });
}
