import React, { useContext, useState, useEffect } from "react";
import { firestoreDb } from "../firebase";
import { useAuth } from "./AuthContext";

const ProfileContext = React.createContext();

export function useProfile() {
  return useContext(ProfileContext);
}

export function ProfileProvider({ children }) {
  const { currentUser, userType } = useAuth();
  const [loadingProfile, setLoadingProfile] = useState(true);
  const [loadingNotifications, setLoadingNotifications] = useState(true);
  const [profileData, setProfileData] = useState({});
  const [notificationsData, setNotificationsData] = useState({});

  useEffect(() => {
    if (userType && currentUser) {
      setLoadingProfile(true);
      const typeTranslation = {
        "type-res": "restaurants",
        "type-sup": "suppliers",
        "type-rep": "representatives",
      };
      const typeUser = typeTranslation[userType];
      firestoreDb
        .collection(typeUser)
        .doc(currentUser.uid)
        .get()
        .then((doc) => {
          setProfileData(doc.data());
          setLoadingProfile(false);
        })
        .catch((err) => console.error("err", err));
    } else {
      setLoadingProfile(false);
    }
    // setLoadingProfile(false);
  }, []);

  useEffect(() => {
    if (userType && currentUser) {
      setLoadingNotifications(true);
      const typeTranslation = {
        "type-res": "restaurants",
        "type-sup": "suppliers",
        "type-rep": "representatives",
      };
      const typeUser = typeTranslation[userType];
      const unsubscribe = firestoreDb
        .collection(typeUser)
        .doc(currentUser.uid)
        .collection("private")
        .doc("notifications")
        .onSnapshot((doc) => {
          const data = doc.data();
          data.notifications.reverse();
          setNotificationsData(data);
          setLoadingNotifications(false);
        });
      return unsubscribe;
    }
    setLoadingNotifications(false);
  }, []);

  const value = {
    profileData,
    notificationsData,
    isLoading: loadingNotifications && loadingProfile,
  };

  return (
    <>
      {!loadingNotifications && !loadingProfile && (
        <ProfileContext.Provider value={value}>{children}</ProfileContext.Provider>
      )}
    </>
  );
}
