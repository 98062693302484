import styled from "styled-components";

export const RegisterModal = styled.div`
  align-content: center;
  background-color: white;
  border-radius: 10px;
  /* padding: 24px; */
  width: 36rem;
  height: ${(props) => (props.small ? "8rem" : "42rem")};
  box-shadow: 0px 20px 80px #00177442;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: auto;
  position: relative;
  top: 8rem;

  @media (max-width: 600px) {
    top: 10%;
    width: 94%;
  }
`;
