import styled from "styled-components";

export const Row = styled.div`
  display: flex;
  > :first-child {
    margin-left: 0;
    margin-right: ${(props) => props.margin || "1em"};
  }
  > :last-child {
    margin-right: 0;
    margin-left: ${(props) => props.margin || "1em"};
  }
`;
