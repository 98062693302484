import React, { useState, useMemo } from "react";
import Text from "../../../languages/Text";
import { Grid } from "../../../components/Grid";
import { H1 } from "../../../components/TextStyles";
import { Loader } from "../../../components/Loader.js";
import useFetch from "../../../hooks/useFetch";
import { useAuth } from "../../../contexts/AuthContext";
import { fetchCampaigns, fetchRestaurants } from "./data";
import { Button } from "../../../components/Button";
import NewCampaignModal from "./NewCampaignModal";
import Campaign from "./Campaign";
import { Body, UtilBar, Page, Header } from "../../../components/Layout/Layout";

const Campaigns = () => {
  const { currentUser } = useAuth();
  const { uid: userID } = currentUser;
  // const [campaignName, setCampaignName] = useState("");
  // const [campaignText, setCampaignText] = useState("");
  // const [recipients, setRecipients] = useState({});
  const [isCreating, setIsCreating] = useState(false);
  const functionArgs = useMemo(() => ({ userID }), [userID]);
  const { data: campaigns, isLoading } = useFetch(fetchCampaigns, functionArgs);
  const { data: restaurants, isLoading: isLoadingRestaurants } = useFetch(
    fetchRestaurants,
    functionArgs
  );

  // function handleSubmit(e) {
  //   e.preventDefault();
  //   const { uid } = currentUser;
  //   const { supplierName } = profileData;
  //   const data = { supplierID: uid, campaignName, campaignText, supplierName, recipients };
  //   createCampaign(data)
  //     .then(() => console.log("all gucci"))
  //     .catch((err) => console.error("not all gucci", err));
  //   setCreating(false);
  //   setRecipients({});
  //   setCampaignName("");
  //   setCampaignText("");

  //   // sendNotifications(recipients, data)
  //   //   .then(() => console.log("all gucci"))
  //   //   .catch((err) => console.error("not all gucci", err));
  // }

  return (
    <Page>
      <Header>
        <H1>
          <Text vid={"common"} tid={"campaigns"} capitalize={true} />
        </H1>
        <Button onClick={() => setIsCreating(true)}>
          <Text vid="campaign" tid="createNewCampaign" />
        </Button>
      </Header>
      <UtilBar></UtilBar>
      <Body>
        {isLoading ? (
          <Loader />
        ) : (
          <Grid>
            {campaigns.map((campaign) => (
              <Campaign campaignData={campaign} key={campaign.ID} />
            ))}
          </Grid>
        )}
        {isCreating && (
          <NewCampaignModal restaurants={restaurants} onClose={() => setIsCreating(false)} />
        )}
      </Body>
    </Page>
  );
};

export default Campaigns;
