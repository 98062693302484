import { formatPrice } from "../../utils/misc";

export function generateAnalytics(userType, ordersData, dateFilters, timeframeType) {
  let analytics = {
    orders_data: {
      total_orders: 0,
      total_money: 0,
      total_quantity: 0,
      pending_orders: 0,
      accepted_orders: 0,
      cancelled_orders: 0,
    },
    users_data: {},
  };

  const isIncluded = (dateFilters, timestamp, timeframeType) => {
    if (timeframeType === "all-time" || Object.keys(dateFilters).length === 0) return true;
    else if (timeframeType === "date-range")
      return dateFilters.endDate
        ? timestamp.toDate() >= dateFilters.startDate && timestamp.toDate() <= dateFilters.endDate
        : timestamp.toDate().toDateString() === dateFilters.startDate.toDateString();
    else if (timeframeType === "month") {
      const orderDate = timestamp.toDate();
      const filterDate = new Date(`${dateFilters.month}-02`);
      // return dateFilters.month === `${tempDate.getFullYear()}-0${tempDate.getMonth()}`;
      return (
        orderDate.getMonth() === filterDate.getMonth() &&
        orderDate.getFullYear() === filterDate.getFullYear()
      );
    }
    // return dateFilters.month
  };

  ordersData.forEach((order) => {
    const {
      cost,
      quantity,
      status,
      supplierID,
      supplierName,
      restaurantID,
      restaurantName,
      timestamp,
    } = order;

    if (isIncluded(dateFilters, timestamp, timeframeType)) {
      analytics.orders_data.total_orders += 1;
      if (typeof cost !== "undefined") analytics.orders_data.total_money += cost;
      if (typeof quantity !== "undefined") analytics.orders_data.total_quantity += quantity;
      if (status === "unplaced") analytics.orders_data.pending_orders += 1;
      else if (status === "placed") analytics.orders_data.accepted_orders += 1;
      else if (status === "cancelled") analytics.orders_data.cancelled_orders += 1;

      if (userType === "type-res") {
        if (analytics.users_data[supplierID]) {
          analytics.users_data[supplierID].orders += 1;
          if (typeof cost !== "undefined") analytics.users_data[supplierID].money += cost;
        } else {
          analytics.users_data[supplierID] = {
            name: supplierName,
            orders: 1,
            money: cost,
          };
        }
      } else if (userType === "type-sup") {
        if (analytics.users_data[restaurantID]) {
          analytics.users_data[restaurantID].orders += 1;
          if (typeof cost !== "undefined") analytics.users_data[restaurantID].money += cost;
        } else {
          analytics.users_data[restaurantID] = {
            name: restaurantName,
            orders: 1,
            money: cost,
          };
        }
      }
    }
  });
  if (userType === "type-res") {
    Object.keys(analytics.users_data).forEach((supplierID) => {
      analytics.users_data[supplierID].orders_percentage = (
        (analytics.users_data[supplierID].orders / analytics.orders_data.total_orders) *
        100
      ).toFixed(2);
      analytics.users_data[supplierID].money_percentage = (
        (analytics.users_data[supplierID].money / analytics.orders_data.total_money) *
        100
      ).toFixed(2);
    });
  } else if (userType === "type-sup") {
    Object.keys(analytics.users_data).forEach((restaurantID) => {
      analytics.users_data[restaurantID].orders_percentage = (
        (analytics.users_data[restaurantID].orders / analytics.orders_data.total_orders) *
        100
      ).toFixed(2);
      analytics.users_data[restaurantID].money_percentage = (
        (analytics.users_data[restaurantID].money / analytics.orders_data.total_money) *
        100
      ).toFixed(2);
    });
  }

  return analytics;
}
