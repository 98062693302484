import styled from "styled-components";

export const LabeledField = styled.div`
  /* margin: 1rem 0; */
  > p {
    color: var(--super-faded-blue);
    text-transform: uppercase;
    font-size: 0.875rem;
    font-weight: bold;
    /* margin-bottom: 0.5rem; */
  }
  /* > h4 {
    color: var(--faded-blue);
    font-weight: bold;
  } */
`;
