import React from "react";
import {
  CardLink,
  CardHeader,
  CardContent,
  CardFooter,
  LinkText,
} from "../../../components/Layout/Card";
import { H3 } from "../../../components/TextStyles";
import Text from "../../../languages/Text";

export default function RepresentativeCard({ representativeData }) {
  const { fullName: representativeName, email: representativeEmail } = representativeData;
  return (
    <CardLink to={`/orders?search=${representativeName}`}>
      <CardHeader>
        <H3>{representativeName}</H3>
      </CardHeader>
      <CardContent>
        <p>{representativeEmail}</p>
      </CardContent>
      <CardFooter>
        <div></div>
        <LinkText>
          <Text vid={"restaurants"} tid={"seeAllOrders"} capitalize={true} />
        </LinkText>
      </CardFooter>
    </CardLink>
  );
}
