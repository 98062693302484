import React, { useState, useMemo, useContext } from "react";
import Text from "../../languages/Text";
import { H1, H3 } from "../../components/TextStyles";
import { useAuth } from "../../contexts/AuthContext";
import { useProfile } from "../../contexts/ProfileContext";
import { GridSmall } from "../../components/Grid";
import { inviteRestaurant } from "../../firebase";
import { Button } from "../../components/Button";
import Modal, { ModalHeader, ModalContent, ModalFooter } from "../../components/Modal";
import { CircleButton } from "../../components/CircleButton";
import InviteModal from "../../components/InviteModal";
import useFetch from "../../hooks/useFetch";
import { fetchRestaurants, fetchInvites, addInvitee } from "./data";
import RestaurantCard from "./RestaurantCard";
import { Loader } from "../../components/Loader";
import { Body, UtilBar, Page, Header } from "../../components/Layout/Layout";
import { LanguageContext } from "../../contexts/LanguageContext";

const Restaurants = () => {
  const { currentUser, userType } = useAuth();
  const { uid: userID } = currentUser;
  const { profileData, isLoading: isProfileLoading } = useProfile();
  const { supplierName, fullName, email } = profileData;
  const args = useMemo(() => ({ userID, userType }), [userID, userType]);
  const [isInviting, setInviting] = useState(false);
  const [restaurantEmail, setRestaurantEmail] = useState("");
  const { data: restaurants, isLoading } = useFetch(fetchRestaurants, args);
  const { data: invitesSent } = useFetch(fetchInvites, args);
  const [isSendingInvite, setIsSendingInvite] = useState(false);
  const [isShowingInvitations, setIsShowingInvitations] = useState(false);
  const { userLanguage } = useContext(LanguageContext);

  function onInvite() {
    handleInviteRestaurant()
      .then(() => addInvitee({ userID, restaurantEmail }))
      .then(() => window.location.reload());
  }

  function handleInviteRestaurant() {
    const representativeID = currentUser.uid;
    return inviteRestaurant({
      representativeID,
      restaurantEmail,
      language: userLanguage,
      supplierName,
      fullName,
      supplierEmail: email,
    }).then((smth) => {
      setInviting(false);
      setRestaurantEmail("");
    });
  }

  function openInvitationsPopup() {
    setIsShowingInvitations(true);
  }

  return (
    <Page>
      <Header>
        <H1>
          <Text vid={"common"} tid={"restaurants"} capitalize={true} />
        </H1>
        <div style={{ display: "flex" }}>
          <Button onClick={() => setInviting(true)}>
            <Text vid={"restaurants"} tid={"addRestaurant"} />
          </Button>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: "2rem",
              cursor: "pointer",
            }}
            onClick={openInvitationsPopup}
          >
            <CircleButton style={{ color: "var(--orange)" }}>
              <H3>{invitesSent.length}</H3>
            </CircleButton>
            <H3 color="white" style={{ marginLeft: "0.5rem" }}>
              <Text vid={"common"} tid={"invited"} capitalize={true} />
            </H3>
          </div>
        </div>
      </Header>
      <UtilBar></UtilBar>
      <Body>
        {isLoading ? (
          <Loader />
        ) : (
          <GridSmall>
            {restaurants.restaurantIDs.map((resID) => (
              <RestaurantCard key={resID} restaurantData={restaurants[resID]} />
            ))}
            {isInviting && (
              <InviteModal
                inviteType="restaurant"
                closeModal={() => setInviting(false)}
                input={restaurantEmail}
                setInput={setRestaurantEmail}
                onInvite={onInvite}
                isLoading={isSendingInvite}
              />
            )}
            {isShowingInvitations && (
              <Modal onClose={() => setIsShowingInvitations(false)} width="36rem">
                <ModalHeader>
                  <H1>
                    <Text vid={"common"} tid={"invited"} capitalize={true} />
                  </H1>
                </ModalHeader>
                <ModalContent>
                  {invitesSent &&
                    invitesSent.map((invite) => (
                      <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <H3 color="var(--blue)">{invite.restaurantEmail}</H3>
                        <p>{invite.timestamp.toDate().toLocaleDateString(navigator.language)}</p>
                      </div>
                    ))}
                </ModalContent>
                <ModalFooter></ModalFooter>
              </Modal>
            )}
          </GridSmall>
        )}
      </Body>
    </Page>
  );
};

export default Restaurants;
