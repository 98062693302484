import React from "react";
import { Button } from "../Button";
import { Icon } from "../Icon";
import { cartIcon } from "../../assets/icons";
import styled from "styled-components";
import Text from "../../languages/Text";

const CartCounter = styled.div`
  border-radius: 1rem;
  height: 1.25rem;
  width: 1.25rem;
  /* text-align: center; */
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.5rem;
  background-color: var(--orange);
  padding: 0.25rem;
  color: white;
  font-size: 0.75rem;
  position: absolute;
  top: 0.125rem;
  left: 2.5rem;
`;

export default function CartButton({ toggleOpen, quantity }) {
  return (
    <Button onClick={toggleOpen}>
      <Icon fill="var(--blue)" width="1.5rem" style={{ marginRight: "1rem" }}>
        {cartIcon}
      </Icon>
      <p>
        <Text vid={"catalogue"} tid={"viewCart"} />
      </p>
      <CartCounter>{quantity}</CartCounter>
    </Button>
  );
}
