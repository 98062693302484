import styled, { keyframes } from "styled-components";

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const Loader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  /* margin-top: 10%; */

  &:after {
    content: " ";
    display: block;
    width: 7rem;
    height: 7rem;
    border-radius: 50%;
    border: 0.6rem solid;
    border-color: var(--blue) transparent var(--blue) transparent;
    animation: ${rotate} 1.2s linear infinite;
  }
`;

// height: 60%;
// position: absolute;

// /* width: 80%; */
// top: 40%;
// left: 50%;
// /* height: 60%; */

// @media (max-width: 600px) {
//   /* top: 40%;
//   left: 36%;
//   width: 100%; */
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   position: static;
// }
