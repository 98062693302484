import { useLanguage } from "../contexts/LanguageContext";
// get text according to id & current language

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export default function Text({ vid, tid, capitalize }) {
  const languageContext = useLanguage();
  const text = languageContext.dictionary[vid][tid] || tid;
  if (capitalize) return capitalizeFirstLetter(text);
  return text;
}
