import React, { useState } from "react";
import styled from "styled-components";

const Label = styled.label`
  position: absolute;
  pointer-events: none;
  top: 13px;
  left: 1rem;
  /* transform: translate(0, 0.8rem) scale(1);
  transform-origin: top left; */
  transition: 80ms linear 0ms;
  transform-origin: top left;

  color: ${(props) => (props.error ? "red" : "gray")};
  /* color: gray; */
  background-color: ${(props) => props.filled && "#ffffff"};
  padding: 0 2px;
  font-size: 1rem;
  line-height: 1rem;
  transform: ${(props) => props.filled && "translate(-2px, -18px) scale(0.9)"};
`;

const Input = styled.input`
  padding: 1rem 1rem;
  border: ${(props) => (props.error ? "1px solid red" : "1px solid #0000001f")};
  border-radius: ${(props) => (props.sharp ? "4px" : "2rem")};
  width: 100%;
  outline: none;
  transition: 100ms linear 0ms;

  &:disabled {
    background-color: rgb(235, 235, 235);
  }
  /* 
  &:focus {
    border: ${(props) => (props.error ? "1px solid red" : "1px solid var(--blue)")};
  }

  &:focus + ${Label} {
    color: ${(props) => (props.error ? "red" : "var(--blue)")};
  }

  &:disabled {
    background-color: rgb(230, 230, 230);
  }

  &:disabled + ${Label} {
    background-color: rgb(230, 230, 230);
  } */
`;

export const Textarea = styled.textarea`
  resize: none;
  padding: 1rem 1.5rem;
  border: ${(props) => (props.error ? "1px solid red" : "1px solid #0000001f")};
  border-radius: 1.5rem;
  min-height: 9rem;
  width: 100%;
  outline: none;
  transition: 100ms linear 0ms;
  font-family: "Commissioner", sans-serif;

  /* &:focus {
    border: ${(props) => (props.error ? "1px solid red" : "1px solid var(--blue)")};
  }

  &:focus + ${Label} {
    color: ${(props) => (props.error ? "red" : "var(--blue)")};
  }

  &:disabled {
    background-color: rgb(230, 230, 230);
  }

  &:disabled + ${Label} {
    background-color: rgb(230, 230, 230);
  } */
`;

const Error = styled.p`
  position: absolute;
  bottom: -1.2rem;
  /* left: 4px; */
  color: red;
  font-size: 0.875rem;
`;

const DecimalInput = styled.input`
  width: 100%;
  flex: 1;
  border-radius: 0 2rem 2rem 0;
  padding: 0rem 2rem;
  box-sizing: content-box;
  transition: 100ms linear 0ms;
  /* text-align: center; */
  outline: none;
  border: ${(props) => (props.error ? "1px solid red" : "1px solid #0000001f")};
  /* border-left: none; */

  /* &:focus,
  &:focus + #test-id {
    border: ${(props) => (props.error ? "1px solid red" : "1px solid var(--blue)")};
  }

  &:focus + ${Label} {
    color: ${(props) => (props.error ? "red" : "var(--blue)")};
  }

  &:disabled {
    background-color: rgb(230, 230, 230);
  }

  &:disabled + ${Label} {
    background-color: rgb(230, 230, 230);
  } */
`;

const IntegerInput = styled.input`
  flex: 5;
  text-align: right;
  padding: 1rem 1.5rem;
  border: ${(props) => (props.error ? "1px solid red" : "1px solid #0000001f")};
  border-radius: 2rem 0 0 2rem;
  width: 100%;
  outline: none;
  transition: 100ms linear 0ms;
  /* border-right: none; */

  /* &:focus,
  &:focus + ${DecimalInput} {
    border: ${(props) => (props.error ? "1px solid red" : "1px solid var(--blue)")};
  }

  &:focus + ${Label} {
    color: ${(props) => (props.error ? "red" : "var(--blue)")};
  }

  &:disabled {
    background-color: rgb(230, 230, 230);
  }

  &:disabled + ${Label} {
    background-color: rgb(230, 230, 230);
  } */
`;

const InputContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  /* flex-direction: column; */
  margin: 1.5rem 0;
  font-family: "Commissioner", sans-serif;
  /* min-width: 20rem; */
  /* align-items: center; */

  &:focus-within ${Label} {
    background-color: #ffffff;
    transform: translate(-2px, -18px) scale(0.9);
    transform-origin: top left;
    color: ${(props) => (props.error ? "red" : "var(--blue)")};
  }

  &:focus-within > ${Input},&:focus-within > ${Textarea} {
    border: ${(props) => (props.error ? "1px solid red" : "1px solid var(--blue)")};
  }

  &:focus-within > ${IntegerInput} {
    border: ${(props) => (props.error ? "1px solid red" : "1px solid var(--blue)")};
    border-right: none;
  }

  &:focus-within > ${DecimalInput} {
    border: ${(props) => (props.error ? "1px solid red" : "1px solid var(--blue)")};
    border-left: none;
  }

  &:focus + ${Label} {
    color: ${(props) => (props.error ? "red" : "var(--blue)")};
  }

  &:disabled {
    background-color: rgb(230, 230, 230);
  }

  &:disabled + ${Label} {
    background-color: rgb(230, 230, 230);
  }
`;

// ^^^^^^^^^^^

export const NumberInput = ({
  label,
  valueInteger,
  valueDecimal,
  onChangeInteger,
  onChangeDecimal,
  disabled,
  error,
  decimalRef,
}) => {
  return (
    <InputContainer error={error}>
      <IntegerInput
        id="test-id"
        disabled={disabled}
        error={error}
        type="text"
        value={valueInteger}
        onChange={onChangeInteger}
        aria-labelledby="integer-label"
      />
      {/* <div
        style={{
          position: "absolute",
          right: "25%",
          top: "25%",
          height: "50%",
          border: "1px solid #0000001f",
        }}
      ></div> */}
      <DecimalInput
        ref={decimalRef}
        onChange={onChangeDecimal}
        value={valueDecimal}
        className="decimal"
        placeholder=".00"
      />
      <Label id="integer-label" error={error} filled={valueInteger}>
        {label}
      </Label>

      <Error>{error}</Error>
    </InputContainer>
  );
};

export const TextInput = ({ label, value, onChange, disabled, error, password, sharp }) => {
  return (
    <>
      <InputContainer error={error}>
        <Input
          disabled={disabled}
          error={error}
          type={password ? "password" : "text"}
          value={value}
          onChange={onChange}
          sharp={sharp}
        />
        <Label error={error} filled={value}>
          {label}
        </Label>
        <Error>{error}</Error>
      </InputContainer>
    </>
  );
};

export const TextareaInput = ({ label, value, onChange, disabled, error }) => {
  return (
    <>
      <InputContainer error={error}>
        <Textarea disabled={disabled} error={error} type="text" value={value} onChange={onChange} />
        <Label error={error} filled={value}>
          {label}
        </Label>
      </InputContainer>
    </>
  );
};

{
  /* <div className="input-container">
<input disabled={disabled} type="text" value={value} onChange={onChange} />
<label className={value && "filled"}>{label}</label>
</div> */
}
