import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Orders from "../Orders";
import Order from "../Order";
import Analytics from "../Analytics";
import Settings from "../Settings";
import Catalogue from "./Catalogue";
import Representatives from "./Representatives";
import Restaurants from "../Restaurants";
import Campaigns from "./Campaigns";
import Notifications from "../../components/Notifications/index.js";
import NavbarEl, { NavLinks, StyledLink } from "../../components/Layout/Navbar";
import Text from "../../languages/Text";
import { Layout } from "../../components/Layout/Layout";
import { MobileNav } from "../../components/Layout/MobileNav";
import { MobileNavBottom } from "../../components/Layout/MobileNavBottom";
import { Icon } from "../../components/Icon";
import PrivateRoute from "../../components/PrivateRoute";
import {
  ordersIcon,
  catalogueIcon,
  restaurantsIcon,
  campaignsIcon,
  analyticsIcon,
  representativesIcon,
  settingsIcon,
  fuduramaLogo,
} from "../../assets/icons";
import { useMediaQuery } from "react-responsive";

const SupplierApp = () => {
  const isMobile = useMediaQuery({
    query: "(max-width: 860px)",
  });
  return (
    <Layout>
      {!isMobile ? (
        <NavbarEl>
          <StyledLink to="/orders">
            {ordersIcon}
            <p>
              <Text vid={"common"} tid={"orders"} capitalize={true} />
            </p>
          </StyledLink>
          <StyledLink to="/catalogue">
            {catalogueIcon}
            <p>
              <Text vid={"common"} tid={"catalogue"} capitalize={true} />
            </p>
          </StyledLink>
          <StyledLink to="/restaurants">
            {restaurantsIcon}
            <p>
              <Text vid={"common"} tid={"restaurants"} capitalize={true} />
            </p>
          </StyledLink>
          <StyledLink to="/representatives">
            {representativesIcon}
            <p>
              <Text vid={"common"} tid={"representatives"} capitalize={true} />
            </p>
          </StyledLink>
          <StyledLink to="/campaigns">
            {campaignsIcon}
            <p>
              <Text vid={"common"} tid={"campaigns"} capitalize={true} />
            </p>
          </StyledLink>
          <StyledLink to="/analytics">
            {analyticsIcon}
            <p>
              <Text vid={"common"} tid={"analytics"} capitalize={true} />
            </p>
          </StyledLink>
          <StyledLink to="/settings">
            {settingsIcon}
            <p>
              <Text vid={"common"} tid={"settings"} capitalize={true} />
            </p>
          </StyledLink>
        </NavbarEl>
      ) : (
        <MobileNav>
          {fuduramaLogo}
          <Notifications />
        </MobileNav>
      )}
      {!isMobile && <Notifications />}

      <Switch>
        <Route exact path="/orders">
          <Orders isMobile={isMobile} />
        </Route>
        <Route path="/orders/order/:orderID">
          <Order />
        </Route>
        <Route path="/representatives">
          <Representatives />
        </Route>
        <Route path="/restaurants">
          <Restaurants />
        </Route>
        <Route path="/catalogue">
          <Catalogue isMobile={isMobile} />
        </Route>
        <Route path="/campaigns">
          <Campaigns />
        </Route>
        <Route path="/analytics">
          <Analytics />
        </Route>
        <Route path="/settings">
          <Settings />
        </Route>
        <Route>
          <Redirect to="/orders" />
        </Route>
      </Switch>
      {isMobile && (
        <MobileNavBottom>
          <NavLinks isOpen>
            <StyledLink to="/orders">
              <Icon width="1.75rem" height="1.75rem" style={{ paddingTop: "0.25rem" }}>
                {ordersIcon}
              </Icon>
              <p>
                <Text vid={"common"} tid={"orders"} capitalize={true} />
              </p>
            </StyledLink>
            <StyledLink to="/catalogue">
              <Icon width="1.75rem" height="1.75rem" style={{ paddingTop: "0.25rem" }}>
                {catalogueIcon}
              </Icon>
              <p>
                <Text vid={"common"} tid={"catalogue"} capitalize={true} />
              </p>
            </StyledLink>

            <StyledLink to="/campaigns">
              <Icon width="1.75rem" height="1.75rem" style={{ paddingTop: "0.25rem" }}>
                {campaignsIcon}
              </Icon>
              <p>
                <Text vid={"common"} tid={"campaigns"} capitalize={true} />
              </p>
            </StyledLink>
            <StyledLink to="/analytics">
              <Icon width="1.75rem" height="1.75rem" style={{ paddingTop: "0.25rem" }}>
                {analyticsIcon}
              </Icon>
              <p>
                <Text vid={"common"} tid={"analytics"} capitalize={true} />
              </p>
            </StyledLink>

            <StyledLink to="/settings">
              <Icon width="1.75rem" height="1.75rem" style={{ paddingTop: "0.25rem" }}>
                {settingsIcon}
              </Icon>
              <p>
                <Text vid={"common"} tid={"settings"} capitalize={true} />
              </p>
            </StyledLink>
          </NavLinks>
        </MobileNavBottom>
      )}
    </Layout>
  );
};

export default SupplierApp;
