import React from "react";
import { Link } from "react-router-dom";
import { H4 } from "../TextStyles";
import Text from "../../languages/Text";
import { formatTime } from "../../utils/misc";

import styled from "styled-components";

export const NotificationStyled = styled.div`
  border-bottom: 1px solid var(--faded-blue);
  color: black;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  /* background-color: white; */
  /* &:hover {
    background-color: var(--super-faded-blue);
  } */
`;

export const NotificationHeader = styled.div`
  display: flex;
  justify-content: space-between;
  color: var(--blue);
`;

export const NotificationContent = styled.div`
  flex: 1;
`;

export default function Notification({ notificationData, lastTimeNotificationsOpened }) {
  const { type } = notificationData;
  const notificationBasedOnType = (() => {
    if (type === "newOrder") {
      const { orderID, restaurantName, timestamp } = notificationData;
      return (
        <Link
          to={{
            pathname: `/orders/order/${orderID}`,
          }}
        >
          <NotificationStyled>
            <NotificationHeader>
              <H4 accent>
                <Text capitalize vid="notification" tid="newOrder" />
              </H4>
              <p>{formatTime(timestamp)}</p>
            </NotificationHeader>
            <p>{restaurantName}</p>
            <p>{orderID}</p>
          </NotificationStyled>
        </Link>
      );
    }
    if (type === "orderStatusUpdate") {
      const { orderID, representativeName, timestamp, status } = notificationData;
      return (
        <Link
          to={{
            pathname: `/orders/order/${orderID}`,
          }}
        >
          <NotificationStyled>
            <NotificationHeader>
              <H4 accent>
                <Text capitalize vid="notification" tid="statusOfAnOrderHasBeenUpdated" />
              </H4>
              <p>{timestamp.toDate().toLocaleString(navigator.language)}</p>
            </NotificationHeader>
            <NotificationContent>
              {/* <p>{representativeName}</p> */}
              <p>
                Order ID: <span style={{ fontWeight: "700" }}>{orderID.substring(0, 5)}</span>
              </p>

              <p>
                New status: <span style={{ fontWeight: "700" }}>{status}</span>
              </p>
            </NotificationContent>
          </NotificationStyled>
        </Link>
      );
    }
    if (type === "representativeAdded") {
      const { restaurantName, timestamp } = notificationData;
      return (
        <div style={{ border: "1px solid black" }}>
          <h4>New restaurant added you</h4>
          <p>
            Restaurant:
            {restaurantName}
          </p>
          <p>
            Timestamp:
            {timestamp.toDate().toString()}
          </p>
        </div>
      );
    }
    if (type === "restaurantAdded") {
      const { representativeName, timestamp } = notificationData;
      return (
        <div style={{ border: "1px solid black" }}>
          <h4>New representative added you</h4>
          <p>
            Restaurant:
            {representativeName}
          </p>
          <p>
            Timestamp:
            {timestamp.toDate().toString()}
          </p>
        </div>
      );
    }
    if (type === "newCampaign") {
      const {
        supplierID,
        supplierName,
        timestamp,
        campaignText,
        // representativeID,
        // representativeName,
      } = notificationData;
      return (
        <Link
          to={{
            pathname: `/catalogue/${supplierID}`,
            // state: { representativeID, representativeName },
          }}
        >
          <NotificationStyled>
            <NotificationHeader>
              <H4 accent>New campaign</H4>
              <p>{timestamp.toDate().toLocaleString(navigator.language)}</p>
            </NotificationHeader>
            <NotificationContent>
              <p>
                From: <span style={{ fontWeight: "700" }}> {supplierName}</span>
              </p>

              <p>
                Campaign: <span style={{ fontWeight: "700" }}> {campaignText}</span>
              </p>
            </NotificationContent>
          </NotificationStyled>
        </Link>
      );
    }
    return <p>todo type</p>;
  })();

  return <div>{notificationBasedOnType}</div>;
  // return <div>{"some notification"}</div>;
}
