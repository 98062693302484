import React, { useState, useEffect, useMemo } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useAuth } from "../../../contexts/AuthContext";
import { Grid } from "../../../components/Grid";
import Text from "../../../languages/Text";
import { H1 } from "../../../components/TextStyles";
import { SearchBar } from "../../../components/SearchBar";
import { Loader } from "../../../components/Loader.js";
import useFetch from "../../../hooks/useFetch";
import { fetchCatalogue, fetchRestaurants, submitOrder } from "./data";
import Product from "../../../components/Catalogue/Product";
import OrderModal from "../../../components/Catalogue/OrderModal";
import CartButton from "../../../components/Catalogue/CartButton";
import OrderCart from "../../../components/Catalogue/OrderCart";
import { useProfile } from "../../../contexts/ProfileContext";
import { Button } from "../../../components/Button";
import { Body, UtilBar, Page, Header } from "../../../components/Layout/Layout";
import OrderCartMobile from "../../../components/Catalogue/OrderCartMobile";
import CategoryGrid from "../../../components/Catalogue/CategoryGrid";

const Catalogue = ({ isMobile }) => {
  const { state } = useLocation();
  const { supplierID } = useParams();
  const { currentUser } = useAuth();
  const { uid: representativeID } = currentUser;
  const { profileData } = useProfile();
  const { fullName: representativeName } = profileData;
  const [filteredCatalogue, setFilteredCatalogue] = useState(null);
  const [query, setQuery] = useState("");
  const [selectedCategory, setSelectedCategory] = useState();
  const functionArgs = useMemo(() => ({ supplierID }), [supplierID]);
  const restaurantFetchArgs = useMemo(() => ({ representativeID }), [representativeID]);

  const { data, isLoading } = useFetch(fetchCatalogue, functionArgs);
  const { data: restaurants, isLoading: isLoadingRestaurants } = useFetch(
    fetchRestaurants,
    restaurantFetchArgs
  );

  const { catalogue, categories, supplierName } = data;
  const [order, setOrder] = useState({});
  const [isOrderOpen, setIsOrderOpen] = useState(false);
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [orderData, setOrderData] = useState({ quantity: 0, cost: 0 });
  const history = useHistory();
  const [note, setNote] = useState("");
  const [isOrdering, setIsOrdering] = useState(false);
  const [orderOwnerRestaurant, setOrderOwnerRestaurant] = useState({});
  const [openCategory, setOpenCategory] = useState();

  useEffect(() => {
    const quantity = Object.values(order).reduce((a, b) => a + b, 0);
    const cost = parseFloat(
      parseFloat(
        Object.keys(order).reduce(
          (total, ID) =>
            total + parseFloat((catalogue[ID].promotionPrice || catalogue[ID].price) * order[ID]),
          0
        )
      ).toFixed(2)
    );
    setOrderData({ quantity, cost });
  }, [order, catalogue]);

  useEffect(() => {
    if (state && "order" in state && !isLoading) {
      let tempOrder = {};
      Object.entries(state.order).forEach(([key, value]) => {
        if (key in catalogue) tempOrder[key] = value.quantity;
      });
      setOrder(tempOrder);
    }
  }, [catalogue, isLoading, state]);

  function filterCatalogueByCategory(catalogue, category) {
    let temp = {};
    if (category.toLowerCase() === "all") return catalogue;
    if (category.toLowerCase() === "promotion") {
      Object.keys(catalogue).forEach((ID) => {
        const item = catalogue[ID];
        if (item.promotion) temp[ID] = item;
      });
    } else {
      Object.keys(catalogue).forEach((ID) => {
        const item = catalogue[ID];
        if (item.category.toLowerCase() === category.toLowerCase()) temp[ID] = item;
      });
    }
    return temp;
  }

  function filterCatalogueByName(catalogue, query) {
    let temp = {};
    Object.keys(catalogue).forEach((ID) => {
      const item = catalogue[ID];
      if (item.name.toLowerCase().includes(query.toLowerCase())) temp[ID] = item;
    });
    return temp;
  }

  useEffect(() => {
    if (catalogue) {
      let tempCat = { ...catalogue };
      if (selectedCategory) tempCat = filterCatalogueByCategory(tempCat, selectedCategory);
      if (query) tempCat = filterCatalogueByName(tempCat, query);
      setFilteredCatalogue(tempCat);
    }
  }, [query, catalogue, selectedCategory]);

  const productFunctions = {
    addToOrder: (ID) => {
      setOrder({ ...order, [ID]: 1 });
    },
    increaseQuantity: (ID) => {
      setOrder({ ...order, [ID]: order[ID] + 1 });
    },
    decreaseQuantity: (ID) => {
      if (order[ID] === 1) {
        setOrder((current) => {
          const tempOrder = { ...current };
          delete tempOrder[ID];
          return tempOrder;
        });
      } else {
        setOrder({ ...order, [ID]: order[ID] - 1 });
      }
    },
  };

  function buildCatalogue(catalogue) {
    if (!isMobile) {
      return Object.keys(catalogue).map((ID) => {
        return (
          <Product
            item={{ ID, ...catalogue[ID] }}
            key={ID}
            quantity={order[ID]}
            functions={productFunctions}
            isInOrder={ID in order}
            displayOnly={!isOrdering}
          />
        );
      });
    } else {
      return Object.keys(catalogue).map((ID) => {
        return (
          <Product
            item={{ ID, ...catalogue[ID] }}
            key={ID}
            quantity={order[ID]}
            functions={productFunctions}
            isInOrder={ID in order}
            displayOnly={!isOrdering}
          />
        );
      });
    }
  }

  function onOrderSubmit() {
    const { restaurantID, restaurantName } = orderOwnerRestaurant;
    const orderInfo = {
      supplierName,
      supplierID,
      representativeID,
      representativeName,
      restaurantID,
      restaurantName,
      note,
      quantity: parseInt(orderData.quantity),
      cost: orderData.cost,
      status: "unplaced",
      source: "representative",
    };

    const orderItems = {};
    Object.keys(order).forEach((ID) => {
      orderItems[ID] = {
        quantity: order[ID],
        name: catalogue[ID].name,
        id: catalogue[ID].id,
        unit: catalogue[ID].unit,
        price:
          catalogue[ID].promotion && catalogue[ID].promotionPrice
            ? catalogue[ID].promotionPrice
            : catalogue[ID].price,
      };
    });
    const finalOrder = { ...orderInfo, products: orderItems };
    submitOrder({ finalOrder }).then(() => history.push("/orders"));
  }

  return (
    <Page>
      <Header>
        <H1>
          {supplierName} <Text vid={"common"} tid={"catalogue"} capitalize={true} />
        </H1>
        <div>
          <Button onClick={() => setIsOrdering((current) => !current)}>
            {isOrdering ? (
              <Text vid={"common"} tid={"cancel"} />
            ) : (
              <Text vid={"order"} tid={"createOrder"} />
            )}
          </Button>
        </div>
      </Header>
      <UtilBar>
        <SearchBar
          placeholder={Text({ vid: "catalogue", tid: "searchProductByName", capitalize: true })}
          type="search"
          value={query}
          onChange={(e) => {
            setQuery(e.target.value);
          }}
        />

        {!isMobile && isOrdering && (
          <div style={{ position: "relative" }}>
            <CartButton
              toggleOpen={() => setIsCartOpen((current) => !current)}
              quantity={orderData.quantity}
            />
            <OrderCart
              order={order}
              openOrder={() => {
                setIsCartOpen(false);
                setIsOrderOpen(true);
              }}
              isCartOpen={isCartOpen}
              setIsCartOpen={setIsCartOpen}
              orderData={orderData}
              catalogue={catalogue}
              productFunctions={productFunctions}
            />
          </div>
        )}
      </UtilBar>
      <Body>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            {filteredCatalogue && query ? (
              <Grid>{buildCatalogue(filteredCatalogue)}</Grid>
            ) : (
              <div style={{ paddingBottom: "8rem" }}>
                {categories.map((cat) => (
                  <CategoryGrid
                    category={cat}
                    isOpen={openCategory === cat}
                    setOpenCategory={setOpenCategory}
                  >
                    {Object.keys(catalogue)
                      .filter((ID) => catalogue[ID].category === cat)
                      .map((ID) => (
                        <Product
                          item={{ ID, ...catalogue[ID] }}
                          key={ID}
                          quantity={order[ID]}
                          functions={productFunctions}
                          isInOrder={ID in order}
                          displayOnly={!isOrdering}
                          onClick={() => {
                            setIsCartOpen(false);
                          }}
                        />
                      ))}
                  </CategoryGrid>
                ))}
              </div>
            )}
          </>
        )}

        {isOrderOpen && !isLoadingRestaurants && (
          <OrderModal
            onClose={() => setIsOrderOpen(false)}
            orderData={orderData}
            onSubmit={onOrderSubmit}
            note={note}
            setNote={setNote}
            isRepresentativeOrder={true}
            restaurants={restaurants}
            orderOwnerRestaurant={orderOwnerRestaurant}
            setOrderOwnerRestaurant={setOrderOwnerRestaurant}
          />
        )}

        {isMobile && (
          <OrderCartMobile
            order={order}
            openOrder={() => {
              setIsCartOpen(false);
              setIsOrderOpen(true);
            }}
            isCartOpen={isCartOpen}
            setIsCartOpen={setIsCartOpen}
            isMobile={isMobile}
            orderData={orderData}
            catalogue={catalogue}
            productFunctions={productFunctions}
          />
        )}
      </Body>
    </Page>
  );
};

export default Catalogue;
