import styled from "styled-components";

export const RegisterButton = styled.input`
  /* box-shadow: ${(props) => (props.disabled ? "none" : "0px 2px 4px var(--faded-blue)")}; */
  border-radius: 4px;
  color: white;
  outline: none;
  padding: 1rem 1rem;
  font-weight: bold;
  font-size: 14px;

  border: none;
  width: 100%;
  /* margin: 20px 0; */
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  background-color: ${(props) => (props.disabled ? "#E9E9E9" : "var(--blue)")};
  &:hover {
    box-shadow: ${(props) =>
      props.disabled
        ? "not-allowed"
        : "0px 1px 1px rgba(63, 92, 180, 0.3), 0px 0px 2px rgba(63, 92, 180, 0.3)"};
  }

  &:active {
    box-shadow: inset 0px 0px 4px rgba(63, 92, 180, 0.75);
  }
`;
