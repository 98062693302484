import React from "react";
import {
  Page,
  Text as TextPDF,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
  Link,
} from "@react-pdf/renderer";
import fuduramaLogo from "../../assets/fudurama-logo.png";
import CommisionerRegular from "../../assets/Commissioner/static/Commissioner-Regular.ttf";
import CommisionerBold from "../../assets/Commissioner/static/Commissioner-Bold.ttf";
import Text from "../../languages/Text";
import { formatPrice } from "../../utils/misc";

Font.register({
  family: "Commissioner",
  fonts: [
    { src: CommisionerRegular, fontWeight: 400 },
    { src: CommisionerBold, fontWeight: 700 },
  ],
});

// Create styles
const styles = StyleSheet.create({
  page: {
    // flexDirection: "row",
    // backgroundImage: `url(${fuduramaLogo})`,
    // background-image: url("./assets/fudurama-background.png");
    // backgroundImage: `url("../../assets/fudurama-logo.png")`,
    // backgroundSize: "cover",
    // backgroundRepeat: "no-repeat",
    // backgroundAttachment: "fixed",
    // backgroundPosition: "center center",
    margin: "30pt",
    fontFamily: "Commissioner",
    color: "#3f5cb4",
  },
  header: {
    flexDirection: "row",
    // justifyContent: "space-around",
    alignItems: "center",
    fontSize: "22px",
    fontWeight: "700",
    marginBottom: "40pt",

    // width: "75%",
  },
  fuduramaLogo: {
    width: "10%",
    marginRight: "8px",
  },
  title: {
    // lineHeight: "26px",
    // fontWeight: "700",
    marginRight: "26px",
  },
  orderID: {
    color: "#fb633f",
    // fontSize: "20px",
    // lineHeight: "20px",
    // fontWeight: "700",
  },

  orderInfoWrap: {
    flexDirection: "row",
    fontSize: "14px",
    justifyContent: "flex-start",
    marginBottom: "40pt",
  },
  orderInfoField: { marginBottom: "8px" },

  orderInfo: {
    // flex: 1,
    marginRight: "40pt",
  },

  orderNoteWrap: {
    // flex: 1,
    width: "280pt",
  },

  orderNote: {
    // flex: 1,
    padding: "8pt 16pt",
    width: "280pt",
    backgroundColor: "#F4F4FB",
    borderRadius: "10pt",
  },

  watermarkWrap: {
    width: "100vw",
    margin: "0",
    padding: "0",
    position: "absolute",
    bottom: "30pt",
    left: "-30pt",
    zIndex: "-1",
    // textAlign: "center"
  },
  logoWatermark: {
    width: "100vw",
    opacity: "0.1",
  },
  tableWrap: {},
  orderTable: {},
  orderTableHeader: {
    flexDirection: "row",
    fontSize: "12pt",
    fontWeight: "700",
    marginBottom: "12pt",
  },
  orderTableRow: {
    flexDirection: "row",
    fontSize: "12pt",
    marginBottom: "12pt",
  },
});

// Create Document Component
export const OrderPDF = ({ order }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.header}>
        <Image src={fuduramaLogo} style={styles.fuduramaLogo} />
        <TextPDF style={styles.title}>
          <Link style={{ textDecoration: "none", color: "#3f5cb4" }} src="https://www.fudurama.com">
            Fudurama {Text({ vid: "common", tid: "order", capitalize: true })}
            {/* <Text vid="common" tid="order" capitalize /> */}
          </Link>
        </TextPDF>
        <Link
          style={{ textDecoration: "none", color: "#fb633f" }}
          src={`https://app.fudurama.com/orders/order/${order.orderID}`}
        >
          <TextPDF style={styles.orderID}>#{order.orderID}</TextPDF>
        </Link>
      </View>
      <View style={styles.orderInfoWrap}>
        <View style={styles.orderInfo}>
          <TextPDF style={styles.orderInfoField}>
            Restaurant:{"    "}
            <TextPDF style={{ fontWeight: "700" }}>{order.restaurantName}</TextPDF>
          </TextPDF>
          <TextPDF style={styles.orderInfoField}>
            Supplier:{"    "}
            <TextPDF style={{ fontWeight: "700" }}>{order.supplierName}</TextPDF>
          </TextPDF>
          <TextPDF style={styles.orderInfoField}>
            Representative:{"    "}
            <TextPDF style={{ fontWeight: "700" }}>{order.representativeName}</TextPDF>
          </TextPDF>
          <TextPDF style={styles.orderInfoField}>
            Order Date:{"    "}
            <TextPDF style={{ fontWeight: "700" }}>
              {order.timestamp.toDate().toLocaleDateString(navigator.language)}
            </TextPDF>
          </TextPDF>
          <TextPDF style={styles.orderInfoField}>
            Order Cost:{"    "}
            <TextPDF style={{ fontWeight: "700" }}>€{formatPrice(order.cost)}</TextPDF>
          </TextPDF>
        </View>

        <View style={styles.orderNoteWrap}>
          {order.note.length > 0 && (
            <View style={styles.orderNote}>
              <TextPDF style={{ fontWeight: "700" }}>Note</TextPDF>
              <TextPDF style={{ fontWeight: "400", fontSize: "12px" }}>{order.note}</TextPDF>
            </View>
          )}
        </View>
      </View>
      <View style={styles.watermarkWrap}>
        <Image src={fuduramaLogo} style={styles.logoWatermark} />
      </View>
      <View style={styles.tableWrap}>
        <View style={styles.orderTable}>
          <View style={styles.orderTableHeader}>
            <TextPDF style={{ width: "15%" }}>Product ID</TextPDF>
            <TextPDF style={{ width: "35%" }}>Product Name</TextPDF>
            <TextPDF style={{ width: "15%", textAlign: "left" }}>Price (€) / Unit</TextPDF>
            <TextPDF style={{ width: "15%", textAlign: "center" }}>Quantity</TextPDF>
            <TextPDF style={{ width: "10%", textAlign: "right" }}>Cost (€)</TextPDF>
          </View>
          {Object.keys(order.products).map((productID) => (
            <View style={styles.orderTableRow} key={productID}>
              <TextPDF style={{ width: "15%" }}>{order.products[productID].id}</TextPDF>
              <TextPDF style={{ width: "35%" }}>{order.products[productID].name}</TextPDF>
              <TextPDF style={{ width: "15%", textAlign: "left" }}>
                {formatPrice(order.products[productID].price)} / {order.products[productID].unit}
              </TextPDF>
              <TextPDF style={{ width: "15%", textAlign: "center" }}>
                {order.products[productID].quantity}
              </TextPDF>
              <TextPDF style={{ width: "10%", textAlign: "right" }}>
                {formatPrice(order.products[productID].price * order.products[productID].quantity)}
              </TextPDF>
            </View>
          ))}
        </View>
      </View>
    </Page>
  </Document>
);
