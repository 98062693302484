import React, { useState } from "react";
import { Card, CardHeader, CardButton } from "../../components/Layout/Card";
import Text from "../../languages/Text";
import { LabeledField } from "../../components/LabeledField";
import { FlexRow } from "../../components/FlexRow";
import { H2, H3, H4 } from "../../components/TextStyles";
import { changeOrderStatus } from "../../pages/Order/data";
import { useAuth } from "../../contexts/AuthContext";
// import ChangeStatusButtons from "./ChangeStatusButtons";
import { Button } from "../../components/Button";
import styled from "styled-components";

const OrderInfoStyled = styled(Card)`
  padding: 1.5rem;
  height: fit-content;
  flex: 1;
  @media (max-width: 860px) {
    /* flex: none; */
  }
`;

const OrderInfoContent = styled.div`
  /* padding: 2rem 0; */
  > * {
    margin: 2rem 0;
  }
`;

export default function OrderInfo({ order, openModal }) {
  const [newStatus, setNewStatus] = useState(null);
  const { userType } = useAuth();

  let {
    timestamp,
    supplierName,
    representativeName,
    restaurantName,
    status,
    orderID,
    note,
    requestedDate,
    deliveryDate,
    supplierNote,
    cost,
  } = order;

  if (newStatus) status = newStatus;
  if (requestedDate) requestedDate = new Date(requestedDate);
  if (deliveryDate) deliveryDate = new Date(deliveryDate);

  function onChangeStatus(status) {
    setNewStatus(status);
    changeOrderStatus({ status, orderID });
  }

  const cardTitle = (() => {
    if (userType === "type-sup") {
      return restaurantName;
    } else if (userType === "type-res") {
      return supplierName;
    } else if (userType === "type-rep") {
      return restaurantName;
    }
  })();

  const cardStatus = (() => {
    if (status === "unplaced")
      return (
        <H3 accent color="var(--blue)" style={{ textTransform: "uppercase" }}>
          <Text vid={"order"} tid={"statusPending"} />
        </H3>
      );
    else if (status === "placed")
      return (
        <H3 accent color="var(--green)" style={{ textTransform: "uppercase" }}>
          <Text vid={"order"} tid={"statusPlaced"} />
        </H3>
      );
    else if (status === "cancelled")
      return (
        <H3 accent color="var(--orange)" style={{ textTransform: "uppercase" }}>
          <Text vid={"order"} tid={"statusCancelled"} />
        </H3>
      );
  })();

  return (
    <OrderInfoStyled>
      <CardHeader>
        <H2 accent style={{ color: "var(--blue)" }}>
          {cardTitle}
        </H2>

        <H4 accent color="var(--blue)">
          {timestamp.toDate().toLocaleDateString()}
        </H4>
      </CardHeader>
      <OrderInfoContent>
        <LabeledField>
          <p>
            <Text vid={"common"} tid={"representative"} />
          </p>
          <H4 color="var(--blue)">{representativeName}</H4>
        </LabeledField>
        {/* <LabeledField>
            <p>Date Created</p>
            <H4 color="var(--blue)">{timestamp.toDate().toLocaleString()}</H4>
          </LabeledField> */}

        {(requestedDate || deliveryDate) && (
          <FlexRow spaceBetween>
            {requestedDate && (
              <LabeledField style={{ flex: 1 }}>
                <p>
                  <Text vid={"order"} tid={"requestedDeliveryDate"} />
                </p>
                <H4 color="var(--blue)">{requestedDate.toLocaleDateString()}</H4>
              </LabeledField>
            )}
            {deliveryDate && (
              <LabeledField style={{ flex: 1 }}>
                <p>
                  <Text vid={"order"} tid={"actualDeliveryDate"} />
                </p>
                <H4 color="var(--blue)">{deliveryDate.toLocaleDateString()}</H4>
              </LabeledField>
            )}
          </FlexRow>
        )}
        {note && (
          <LabeledField style={{ flex: 1 }}>
            <p>
              <Text vid={"order"} tid={"restaurantNote"} />
            </p>
            <H4 color="var(--blue)">{note}</H4>
          </LabeledField>
        )}
        {supplierNote && (
          <LabeledField style={{ flex: 1 }}>
            <p>
              <Text vid={"order"} tid={"supplierNote"} />
            </p>
            <H4 color="var(--blue)">{supplierNote}</H4>
          </LabeledField>
        )}
      </OrderInfoContent>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        {cardStatus}
        {(userType === "type-sup" || userType === "type-rep") && status === "unplaced" && (
          <CardButton onClick={openModal}>
            <Text vid={"order"} tid={"updateStatus"} />
          </CardButton>
        )}
      </div>
    </OrderInfoStyled>
  );
}
