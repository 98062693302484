import { getCatalogue, updateCatalogue, getDoc, sendOrderFromRep } from "../../../firestore";
import { sortObject } from "../../../utils/sort";

export function fetchCatalogue({ supplierID }) {
  return getCatalogue(supplierID)
    .then((doc) => doc.data())
    .then((data) => {
      const { catalogue, supplierName } = data;
      const sortedCatalogue = sortObject(catalogue, "category", "name");
      let categories = new Set();
      Object.values(sortedCatalogue).forEach(({ category }) => {
        categories.add(category);
      });
      categories = Array.from(categories);
      return { catalogue: sortedCatalogue, categories, supplierName };
    });
}

export function fetchRestaurants({ representativeID }) {
  return getDoc(`representatives/${representativeID}/private/restaurants`)
    .then((doc) => doc.data())
    .then((data) => {
      delete data.restaurantIDs;
      return Object.keys(data).map((restaurantID) => ({
        restaurantName: data[restaurantID].restaurantName,
        restaurantID,
      }));
    });
}

// export function fetchRestaurants({ representativeID }) {
//   return getCatalogue(supplierID)
//     .then((doc) => doc.data())
//     .then((data) => {
//       const { catalogue, supplierName } = data;
//       const sortedCatalogue = sortObject(catalogue, "category", "name");
//       let categories = new Set(["all", "promotion"]);
//       Object.values(sortedCatalogue).forEach(({ category }) => {
//         categories.add(category);
//       });
//       categories = Array.from(categories);
//       console.log("in data", { catalogue: sortedCatalogue, categories });
//       return { catalogue: sortedCatalogue, categories, supplierName };
//     });
// }

export function submitOrder({ finalOrder }) {
  return sendOrderFromRep(finalOrder).catch((err) => console.error(err));
}
