import React from "react";
import {
  CardLink,
  CardHeader,
  CardContent,
  CardFooter,
  LinkText,
} from "../../components/Layout/Card";
import { H3 } from "../../components/TextStyles";
import Text from "../../languages/Text";

export default function RestaurantCard({ restaurantData }) {
  const { fullName, restaurantName } = restaurantData;

  return (
    <CardLink to={`/orders?search=${restaurantName}`}>
      <CardHeader>
        <H3>{restaurantName}</H3>
      </CardHeader>
      <CardContent>
        <div>
          <p>{fullName}</p>
          {/* <p>{email}</p> */}
        </div>
      </CardContent>
      <CardFooter>
        <div></div>
        <LinkText>
          <Text vid={"restaurants"} tid={"seeAllOrders"} capitalize={true} />
        </LinkText>
      </CardFooter>
    </CardLink>
  );
}
