import styled from "styled-components";
import React from "react";
import { closeIcon } from "../assets/icons";
import { Icon } from "./Icon";

const GreyOut = styled.div`
  position: fixed;
  z-index: 4;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalStyled = styled.div`
  position: relative;
  bottom: 0;
  background-color: white;
  border-radius: 1rem;
  width: ${(props) => (props.width ? props.width : "fit-content")};
  /* padding: 4rem; */
  /* width: fit-content; */
  min-width: 36rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem;

  @media (max-width: 860px) {
    border-radius: 1rem 1rem 0 0;
    position: fixed;
    z-index: 2;
    max-height: 80%;
    width: 100%;
    min-width: 0%;
    /* padding-bottom: 5rem; */
    /* max-width: 90%; */
    /* padding: 2rem; */
    overflow: scroll;

    /* grid-area: content; */
    /* width: 96%;
    height: 80%; */
    /* top: 0;
    left: 0; */
  }
`;

export const ModalInput = styled.input`
  padding: 1rem;
  margin: 0.5rem 0;
  border: 1px solid #0000001f;
  border-radius: 2rem;
  /* min-width: 20rem; */
  width: 100%;
  /* @media (max-width: 600px) {
    width: 40%;
    min-width: 1rem;
  } */
`;

export const ModalTextarea = styled.textarea`
  padding: 1rem;
  margin: 0.5rem 0;
  border: 1px solid #0000001f;
  border-radius: 1.5rem;
  resize: none;
  font-family: "Commissioner", sans-serif;
  /* min-width: 21rem; */
  min-height: 7rem;
  width: 100%;

  /* @media (max-width: 600px) {
    width: 40%;
    min-width: 1rem;
  } */
`;

export const ModalHeader = styled.div`
  padding: 2rem 0rem;
  text-align: center;
  @media (max-width: 600px) {
    padding: 2rem;
  }
`;
export const ModalContent = styled.div`
  overflow: scroll;
  /* padding: 0 4rem; */
  flex: 1;
  @media (max-width: 600px) {
    padding: 0 2rem;
  }
`;
export const ModalFooter = styled.div`
  padding: 2rem 4rem;
  width: 100%;
  /* background-color: var(--blue); */
  border-radius: 0 0 1rem 1rem;
  display: flex;
  justify-content: space-around;
  /* color: white; */
  align-items: center;
  > * {
    margin: 0 1rem;
  }
  > :first-child {
    margin-left: 0;
  }
  > :last-child {
    margin-right: 0;
  }
  @media (max-width: 600px) {
    padding: 2rem;
  }
`;

export default function Modal(props) {
  return (
    <GreyOut>
      <ModalStyled width={props.width}>
        <Icon onClick={props.onClose} fill="black">
          {closeIcon}
        </Icon>
        {props.children}
      </ModalStyled>
    </GreyOut>
  );
}
