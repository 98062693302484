import React, { useState, useEffect, useMemo } from "react";
import Text from "../../languages/Text";
import { H1 } from "../../components/TextStyles";
import useFetch from "../../hooks/useFetch";
import { fetchOrders } from "../../pages/Orders/data";
import { useAuth } from "../../contexts/AuthContext";
import { generateAnalytics } from "./analytics";
import { Dashboard } from "./Dashboard";
import { Select } from "../../components/Select";
import { DateFilter } from "../../components/Filter";
import styled from "styled-components";
import { Body, UtilBar, Page, Header } from "../../components/Layout/Layout";

const MonthPickerStyled = styled.input`
  /* border: 1px solid var(--faded-blue); */
  box-shadow: var(--basic-shadow);

  border: none;
  border-radius: 24px;
  padding: 0.5rem;
  outline: none;
  height: 3rem;
  background-color: white;
  margin-left: 1rem;
  width: fit-content;
`;

function MonthPicker({ month, setMonth }) {
  return (
    <MonthPickerStyled
      type="month"
      min="2021-03"
      value={month}
      onChange={(e) => setMonth(e.target.value)}
    />
  );
}

export default function Analytics() {
  const { userType, currentUser } = useAuth();
  const { uid: userID } = currentUser;
  const functionArgs = useMemo(() => ({ userID, userType }), [userID, userType]);
  const { data: originalOrders, isLoading } = useFetch(fetchOrders, functionArgs);
  const [timeframeType, setTimeframeType] = useState("all-time");
  const [timeframe, setTimeframe] = useState([]);
  const [month, setMonth] = useState();
  const [dateFilters, setDateFilters] = useState({});

  const [analytics, setAnalytics] = useState({
    orders_data: {
      total_orders: 0,
      total_expenditure: 0,
      total_quantity: 0,
      pending_orders: 0,
      accepted_orders: 0,
      cancelled_orders: 0,
    },
    users_data: {},
  });

  function applyDateFilter(datesObj) {
    setDateFilters(datesObj);
  }

  function removeDateFilter() {
    setDateFilters({});
  }

  useEffect(() => {
    setDateFilters({ month });
  }, [month]);

  useEffect(() => {
    setAnalytics(generateAnalytics(userType, originalOrders, dateFilters, timeframeType));
  }, [originalOrders, dateFilters, userType]);

  return (
    <Page>
      <Header>
        <H1>
          <Text vid={"common"} tid={"analytics"} capitalize={true} />
        </H1>
        <div style={{ display: "flex" }}>
          <Select
            onChange={(e) => {
              setTimeframeType(e.target.value);
              if (e.target.value === "all-time") {
                setDateFilters({});
              }
            }}
            value={timeframeType}
          >
            <option value="all-time">
              {Text({ vid: "analytics", tid: "allTime", capitalize: true })}
            </option>
            <option value="month">
              {Text({ vid: "analytics", tid: "month", capitalize: true })}
            </option>
            <option value="date-range">
              {Text({ vid: "analytics", tid: "dateRange", capitalize: true })}
            </option>
          </Select>
          {timeframeType === "month" && <MonthPicker month={month} setMonth={setMonth} />}
          {timeframeType === "date-range" && (
            <div style={{ marginLeft: "1rem" }}>
              <DateFilter
                preClickText="Filter by date"
                postClickText="Select date"
                applyFilter={applyDateFilter}
                removeFilter={removeDateFilter}
              />
            </div>
          )}
        </div>
      </Header>
      <UtilBar></UtilBar>
      <Body>
        <Dashboard userType={userType} analytics={analytics} />
      </Body>
    </Page>
  );
}
