import React, { useState } from "react";
import { withRouter, Link, useParams, useLocation, useHistory } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import { RegisterModal } from "./RegisterModal";
import { Form } from "./Form";
import { fuduramaLogo } from "../../assets/icons";
import { RegisterButton } from "./RegisterButton";
import { createRestaurantDB } from "../../firestore";
import { addUserType } from "../../firebase";
import { SignupLinks, StyledLink } from "./SignupLinks";
import { addRepresentative } from "../../firestore";
import { H1, H2 } from "../../components/TextStyles";
import { Icon } from "../../components/Icon";
import { TextInput, TextareaInput, NumberInput } from "../../components/TextInput";

export default function SignupRestaurant(props) {
  const [fullname, setFullname] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [restaurantName, setRestaurantName] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const { signup, signout } = useAuth();
  const query = new URLSearchParams(useLocation().search);
  const history = useHistory();

  function findFieldErrors() {
    let tempErrors = {};
    if (fullname.length === 0) tempErrors.fullname = "Cannot be empty";
    if (restaurantName.length === 0) tempErrors.restaurantName = "Cannot be empty";
    if (password.length === 0) tempErrors.password = "Cannot be empty";
    if (email.length === 0) tempErrors.email = "Cannot be empty";

    setErrors(tempErrors);
    if (Object.keys(tempErrors).length === 0) return false;
    else return true;
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (!findFieldErrors()) {
      setLoading(true);
      const representativeID = query.get("repID");

      const userData = {
        userEmail: email,
        userFullName: fullname,
        userField: restaurantName,
      };
      let repData = {};

      signup(email, password)
        .then((cred) => {
          const { uid: userID } = cred.user;
          userData.userID = userID;
          repData = {
            representativeID,
            restaurantID: userID,
            fullName: fullname,
            email: email,
            restaurantName,
          };
          return createRestaurantDB(userData, representativeID);
        })

        .then(() => {
          if (representativeID) addRepresentative(repData);
        })
        .catch((error) => {
          if (error.code === "auth/invalid-email") setErrors({ email: "Invalid email" });
          if (error.code === "auth/email-already-in-use")
            setErrors({ email: "Email already registered" });
          if (error.code === "auth/weak-password")
            setErrors({ password: "Password must be at least 6 characters" });
          setLoading(false);
          return Promise.reject(error);
        })
        .then(() => {
          addUserType({ email, userType: "type-res" });
        })
        // .then(signout)
        .then(() => {
          history.push({ pathname: "/signin" });
        })
        .catch((err) => console.error("Something went wrong:", err));
    }
  }

  return (
    <>
      <RegisterModal>
        <SignupLinks>
          <StyledLink to="/signup-restaurant">
            <H2>Restaurant</H2>
          </StyledLink>
          <StyledLink to="/signup-supplier">
            <H2>Supplier</H2>
          </StyledLink>
        </SignupLinks>
        <div style={{ position: "absolute", top: "2rem" }}>
          <a href="https://www.fudurama.com/">
            {/* <Icon width="8rem" style={{ margin: "-4rem auto 8rem auto" }}> */}
            <Icon width="8rem" style={{ margin: "auto" }}>
              {fuduramaLogo}
            </Icon>
          </a>
        </div>
        <div style={{ height: "5rem" }}></div>

        <Form id="signup-form" className="auth-form" onSubmit={handleSubmit}>
          <H1>Sign up</H1>
          <TextInput
            sharp
            error={errors.fullname}
            label="Full Name"
            value={fullname}
            placeholder="Full Name"
            onChange={(e) => {
              setFullname(e.target.value);
            }}
          />
          <TextInput
            sharp
            error={errors.restaurantName}
            label="Restaurant Name"
            value={restaurantName}
            placeholder="Restaurant Name"
            onChange={(e) => {
              setRestaurantName(e.target.value);
            }}
          />
          <TextInput
            sharp
            error={errors.email}
            label="Email"
            value={email}
            placeholder="Email"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          <TextInput
            sharp
            password
            error={errors.password}
            label="Password"
            value={password}
            placeholder="Password"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
          {/* <input
            type="text"
            id="name-input"
            placeholder="Full Name"
            name="userFullName"
            value={fullname}
            onChange={(e) => setFullname(e.target.value)}
          />
          <input
            type="text"
            id="restaurant-name-input"
            placeholder="Restaurant Name"
            name="restaurantName"
            value={restaurantName}
            onChange={(e) => setRestaurantName(e.target.value)}
          />
          <input
            type="text"
            id="email-input"
            placeholder="Email"
            value={email}
            name="userEmail"
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="password"
            id="password-input"
            placeholder="Password"
            value={password}
            name="userPassword"
            onChange={(e) => setPassword(e.target.value)}
          /> */}

          <RegisterButton type="submit" value="Sign Up" disabled={isLoading} />
          <p style={{ fontSize: "14px" }}>
            Already have an account?
            <Link style={{ fontWeight: "bold" }} to="/signin">
              {" "}
              Sign in
            </Link>
          </p>
        </Form>
      </RegisterModal>
    </>
  );
}
