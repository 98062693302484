import React, { useState } from "react";
import Modal, { ModalContent, ModalHeader, ModalFooter, ModalInput } from "../../components/Modal";
import { H1, H2, H3, H4 } from "../../components/TextStyles";
import { TextInput } from "../../components/TextInput";
import { Button } from "../../components/Button";
import Text from "../../languages/Text";

export default function ChangePasswordModal({ onClose, updatePassword, signin }) {
  const [currentPassword, setCurrentPassword] = useState("");
  const [password, setPassword] = useState("");
  const [passwordTwo, setPasswordTwo] = useState("");
  const [errors, setErrors] = useState({});

  function findFieldErrors() {
    let tempErrors = {};
    if (currentPassword.length === 0) tempErrors.currentPassword = "Cannot be empty";
    if (password.length < 6) tempErrors.password = "Password must be at least 6 characters";
    if (passwordTwo !== password) tempErrors.passwordTwo = "Passwords must match";
    setErrors(tempErrors);
    if (Object.keys(tempErrors).length === 0) return false;
    else return true;
  }

  return (
    <Modal onClose={onClose}>
      <ModalHeader>
        <H1 capitalize>
          <Text vid={"settings"} tid={"changePassword"} />
        </H1>
      </ModalHeader>
      <ModalContent>
        <TextInput
          error={errors.currentPassword}
          password
          label="Current Password"
          value={currentPassword}
          onChange={(e) => {
            setCurrentPassword(e.target.value);
          }}
          style={{ marginBottom: "1.5rem" }}
        />
        <TextInput
          error={errors.password}
          password
          label="New Password"
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          style={{ marginBottom: "1.5rem" }}
        />
        <TextInput
          error={errors.passwordTwo}
          password
          label="Confirm New Password"
          value={passwordTwo}
          onChange={(e) => {
            setPasswordTwo(e.target.value);
          }}
        />
      </ModalContent>
      <ModalFooter>
        <Button
          onClick={() => {
            if (!findFieldErrors()) {
              signin(currentPassword)
                .then(() => updatePassword(password))
                .catch((err) => {
                  if (err.code === "auth/wrong-password")
                    setErrors({ currentPassword: "Incorrect Password" });
                });
            }
          }}
        >
          <Text vid={"common"} tid={"confirm"} />
        </Button>
        <Button color="var(--orange)" onClick={onClose}>
          <Text vid={"common"} tid={"cancel"} />
        </Button>
      </ModalFooter>
    </Modal>
  );
}
