import React, { useState, useEffect } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { useProfile } from "../../contexts/ProfileContext";
import { Card, CardContent, CardHeader, CardIconButton } from "../../components/Layout/Card";
import LanguageSelector from "../../components/LanguageSelector";
import Text from "../../languages/Text";
import { GridBig } from "../../components/Grid";
import { Button } from "../../components/Button";
import { H1, H2, H3, H4 } from "../../components/TextStyles";
import { Body, Page, Header } from "../../components/Layout/Layout";
import { LabeledField } from "../../components/LabeledField";
import { editIcon } from "../../assets/icons";
import ChangePasswordModal from "./ChangePasswordModal";
import ChangeAddressModal from "./ChangeAddressModal";
import ChangePhoneModal from "./ChangePhoneModal";
import { changeAddress, changePhone } from "../../firestore";

const Settings = () => {
  const { signout, userType, currentUser, updatePassword, signin } = useAuth();
  const { profileData, isLoading } = useProfile();
  const { supplierName, fullName, email, restaurantName, address, phone } = profileData;
  const [isColorful, setIsColorful] = useState(
    document.querySelector("body").classList[0] === "colorful"
  );
  const [isChangingPassword, setIsChagningPassword] = useState(false);
  const [isChangingPhone, setIsChangingPhone] = useState(false);
  const [isChangingAddress, setIsChangingAddress] = useState(false);

  useEffect(() => {
    console.log();

    if (isColorful) {
      document.querySelector("body").classList.remove("colorless");
      document.querySelector("body").classList.add("colorful");
    } else {
      document.querySelector("body").classList.add("colorless");
      document.querySelector("body").classList.remove("colorful");
    }
  }, [isColorful]);

  function handleClick(e) {
    e.preventDefault();
    signout();
  }

  return (
    <Page>
      <Header>
        <H1>
          <Text vid={"common"} tid={"settings"} capitalize={true} />
        </H1>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Button color="var(--orange)" onClick={handleClick}>
            <Text vid={"settings"} tid={"signOut"} />
          </Button>
        </div>
      </Header>
      <Body>
        <GridBig>
          <Card>
            <CardHeader>
              <H2 capitalize>
                <Text vid={"settings"} tid={"accountInfo"} />
              </H2>
            </CardHeader>
            <CardContent>
              {email && (
                <LabeledField>
                  <p color="var(--blue)">Email</p>
                  <H4 color="var(--blue)">{email}</H4>
                </LabeledField>
              )}
              <div
                style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
              >
                <LabeledField>
                  <p color="var(--blue)">
                    <Text vid={"settings"} tid={"password"} />
                  </p>
                  <H4 color="var(--blue)">********</H4>
                </LabeledField>
                <CardIconButton onClick={() => setIsChagningPassword(true)}>
                  {editIcon}
                </CardIconButton>
              </div>
              <div
                style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
              >
                <LabeledField>
                  <p color="var(--blue)">
                    <Text vid={"settings"} tid={"phone"} />
                  </p>
                  <H4 color="var(--blue)">{phone ? phone : "-"}</H4>
                </LabeledField>
                <CardIconButton onClick={() => setIsChangingPhone(true)}>{editIcon}</CardIconButton>
              </div>
            </CardContent>
          </Card>
          <Card>
            <CardContent>
              <CardHeader>
                <H2 capitalize>
                  <Text vid={"settings"} tid={"profileInfo"} />
                </H2>
              </CardHeader>
              <CardContent>
                {fullName && (
                  <LabeledField>
                    <p color="var(--blue)">
                      <Text vid={"common"} tid={"name"} />
                    </p>
                    <H4 color="var(--blue)">{fullName}</H4>
                  </LabeledField>
                )}
                {supplierName && (
                  <LabeledField>
                    <p color="var(--blue)">
                      <Text vid={"settings"} tid={"companyName"} />
                    </p>
                    <H4 color="var(--blue)">{supplierName}</H4>
                  </LabeledField>
                )}
                {restaurantName && (
                  <LabeledField>
                    <p color="var(--blue)">
                      <Text vid={"restaurants"} tid={"restaurantName"} />
                    </p>
                    <H4 color="var(--blue)">{restaurantName}</H4>
                  </LabeledField>
                )}
                {userType === "type-sup" || userType === "type-res" ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <LabeledField>
                      <p color="var(--blue)">
                        <Text vid={"restaurants"} tid={"address"} />
                      </p>
                      <H4 color="var(--blue)">{address ? address : "-"}</H4>
                    </LabeledField>
                    <CardIconButton onClick={() => setIsChangingAddress(true)}>
                      {editIcon}
                    </CardIconButton>
                  </div>
                ) : (
                  <div></div>
                )}
              </CardContent>
            </CardContent>
          </Card>
          <Card>
            <CardHeader>
              <H2 capitalize>
                <Text vid={"settings"} tid={"platformSettings"} />
              </H2>
            </CardHeader>
            <CardContent>
              <LabeledField>
                <p color="var(--blue)">
                  <Text vid={"settings"} tid={"selectLanguage"} />
                </p>
                <LanguageSelector />
              </LabeledField>
              <LabeledField>
                <p color="var(--blue)">
                  <Text vid={"settings"} tid={"toggleTheme"} />
                </p>
                <label class="switch">
                  <input
                    type="checkbox"
                    checked={isColorful}
                    // value={isColorful}
                    onChange={() => setIsColorful((current) => !current)}
                  />
                  <span class="slider round"></span>
                </label>
              </LabeledField>
            </CardContent>
          </Card>
        </GridBig>
        {isChangingPassword && (
          <ChangePasswordModal
            signin={(password) => signin(email, password)}
            updatePassword={updatePassword}
            onClose={() => setIsChagningPassword(false)}
          />
        )}
        {isChangingAddress && (
          <ChangeAddressModal
            updateAddress={(address) =>
              changeAddress({ userType, userID: currentUser.uid, address }).then(() => {
                window.location.reload();
              })
            }
            onClose={() => setIsChangingAddress(false)}
          />
        )}

        {isChangingPhone && (
          <ChangePhoneModal
            updatePhone={(phone) =>
              changePhone({ userType, userID: currentUser.uid, phone }).then(() => {
                window.location.reload();
              })
            }
            onClose={() => setIsChangingPhone(false)}
          />
        )}
      </Body>
    </Page>
  );
};

export default Settings;
