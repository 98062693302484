import React from "react";
import { Card, CardHeader, CardContent, CardFooter } from "../../../components/Layout/Card";
import { H1, H2, H3, H4, H5, H6 } from "../../../components/TextStyles";
import { LabeledField } from "../../../components/LabeledField";

export default function Campaign({ campaignData }) {
  const { campaignName, timestamp, campaignText, recipients } = campaignData;
  const recipientsCount = Object.values(recipients).length;
  return (
    <Card>
      <CardHeader>
        <H3 style={{ marginBottom: "0.5rem" }}>{campaignName || "Campaign Name"}</H3>

        <p>{timestamp.toDate().toLocaleDateString()}</p>
      </CardHeader>
      <CardContent>
        <p
          style={{
            lineHeight: "1.25rem",
            height: "2.5rem",
            overflow: "hidden",
            textOverflow: "ellipsis",
            // whiteSpace: "nowrap",
          }}
        >
          {campaignText}
        </p>
      </CardContent>
      <CardFooter>
        <H4 accent>
          {recipientsCount} {recipientsCount === 1 ? "recipient" : "recipients"}
        </H4>
      </CardFooter>
    </Card>
  );
}
