import React from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import Orders from "../Orders";
import Order from "../Order";
import Analytics from "../Analytics";
import Settings from "../Settings";
import Suppliers from "./Suppliers";
import Catalogue from "./Catalogue";
import Notifications from "../../components/Notifications/index.js";
import PrivateRoute from "../../components/PrivateRoute";
import NavbarEl, { NavLinks, StyledLink } from "../../components/Layout/Navbar";
import { Layout } from "../../components/Layout/Layout";
import { MobileNav } from "../../components/Layout/MobileNav";
import { MobileNavBottom } from "../../components/Layout/MobileNavBottom";
import {
  ordersIcon,
  restaurantsIcon,
  analyticsIcon,
  settingsIcon,
  fuduramaLogo,
} from "../../assets/icons";
import Text from "../../languages/Text";
import { Icon } from "../../components/Icon";
import { useMediaQuery } from "react-responsive";

const RestaurantApp = () => {
  const isMobile = useMediaQuery({
    query: "(max-width: 860px)",
  });
  return (
    <Layout>
      {!isMobile ? (
        <NavbarEl>
          <StyledLink to="/orders">
            {ordersIcon}
            <p>
              <Text vid={"common"} tid={"orders"} capitalize={true} />
            </p>
          </StyledLink>
          <StyledLink to="/suppliers">
            {restaurantsIcon}
            <p>
              <Text vid={"common"} tid={"suppliers"} capitalize={true} />
            </p>
          </StyledLink>
          <StyledLink to="/analytics">
            {analyticsIcon}
            <p>
              <Text vid={"common"} tid={"analytics"} capitalize={true} />
            </p>
          </StyledLink>
          <StyledLink to="/settings">
            {settingsIcon}
            <p>
              <Text vid={"common"} tid={"settings"} capitalize={true} />
            </p>
          </StyledLink>
        </NavbarEl>
      ) : (
        <MobileNav>{fuduramaLogo}</MobileNav>
      )}
      <Notifications />

      <Switch>
        <Route exact path="/orders">
          <Orders isMobile={isMobile} />
        </Route>
        <Route exact path="/suppliers">
          <Suppliers />
        </Route>
        <Route exact path="/analytics">
          <Analytics />
        </Route>
        <Route exact path="/settings">
          <Settings />
        </Route>
        <Route exact path="/catalogue/:supplierID">
          <Catalogue isMobile={isMobile} />
        </Route>
        <Route path="/orders/order/:orderID">
          <Order />
        </Route>
        <Route>
          <Redirect to="/orders" />
        </Route>
      </Switch>

      {isMobile && (
        <MobileNavBottom>
          <NavLinks isOpen>
            <StyledLink to="/orders">
              <Icon width="1.75rem" height="1.75rem">
                {ordersIcon}
              </Icon>
              <p>
                <Text vid={"common"} tid={"orders"} capitalize={true} />
              </p>
            </StyledLink>
            <StyledLink to="/suppliers">
              <Icon width="1.75rem" height="1.75rem">
                {restaurantsIcon}
              </Icon>

              <p>
                <Text vid={"common"} tid={"suppliers"} capitalize={true} />
              </p>
            </StyledLink>
            <StyledLink to="/analytics">
              <Icon width="1.75rem" height="1.75rem">
                {analyticsIcon}
              </Icon>

              <p>
                <Text vid={"common"} tid={"analytics"} capitalize={true} />
              </p>
            </StyledLink>
            <StyledLink to="/settings">
              <Icon width="1.75rem" height="1.75rem">
                {settingsIcon}
              </Icon>

              <p>
                <Text vid={"common"} tid={"settings"} capitalize={true} />
              </p>
            </StyledLink>
          </NavLinks>
        </MobileNavBottom>
      )}
    </Layout>
  );
};

export default RestaurantApp;
