// import sortArray from "../../utils/sort";
import { getDoc, updateField } from "../../../firestore";
import { inviteRepresentative } from "../../../firebase";

export function fetchRepresentatives({ userID }) {
  return getDoc(`suppliers/${userID}/private/representatives`).then((doc) => doc.data());
}

// export function sendInvite({ supplierID, representativeEmail }) {
//   inviteRepresentative({ supplierID, representativeEmail });
// }
