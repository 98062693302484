import styled from "styled-components";

export const H1 = styled.h1`
  font-size: 2.5rem;
  /* line-height: 3rem; */
  font-weight: bold;
  height: fit-content;
  width: fit-content;
  text-transform: ${(props) => (props.capitalize ? "capitalize" : "none")};
`;

export const H2 = styled.h2`
  font-size: 1.75rem;
  line-height: 2rem;
  font-weight: bold;
  height: fit-content;
  text-transform: ${(props) => (props.capitalize ? "capitalize" : "none")};
`;

export const H3 = styled.h3`
  font-size: 1.25rem;
  line-height: 1.5rem;
  font-weight: bold;
  color: ${(props) => props.color || "inherit"};
  height: fit-content;
`;

export const H4 = styled.h4`
  font-size: 1.125rem;
  line-height: 1.25rem;
  font-weight: ${(props) => (props.accent ? "bold" : "400")};
  color: ${(props) => props.color || "inherit"};
  height: fit-content;
`;

export const H5 = styled.h5`
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: ${(props) => (props.accent ? "bold" : "normal")};
  height: fit-content;
`;

export const Label = styled.p`
  text-transform: ${(props) => (props.upper ? "uppercase" : "none")};
  font-size: 0.75rem;
  font-weight: bold;
  height: fit-content;
`;

export const SortLabels = styled.div`
  display: flex;
  flex-direction: row;
  width: ${(props) => (props.width ? props.width : "30rem")};
  padding: 0.75rem;
  justify-content: space-between;
`;

export const SortLabel = styled.button`
  outline: none;
  background: none;
  border: none;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: ${(props) => (props.bold ? "bold" : "normal")};
  display: flex;
  /* align-items: center; */
  color: var(--blue);
  margin: 0 1rem;
  /* margin: 0 2rem; */
  /* position: relative; */
  /* width: 22.5%; */
`;

// h1 {
// font-size: 2.125rem;
// line-height: 2.25rem;
// font-weight: bold;
// }

// h2 {
// font-size: 2.125rem;
// line-height: 2.25rem;
// font-weight: normal;
// }

// h3 {
// font-size: 1.25rem;
// line-height: 1.5rem;
// font-weight: bold;
// }

// h4 {
// font-size: 1rem;
// line-height: 1.25rem;
// font-weight: normal;
// }

// h5 {
// font-size: 0.75rem;
// line-height: 1rem;
// font-weight: bold;
// }

// p {
//   font-size: 1rem;
//   line-height: 1.5rem;
// }
