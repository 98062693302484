import React, { useState, useEffect } from "react";
import Modal, { ModalHeader, ModalContent, ModalFooter, ModalTextarea, ModalInput } from "../Modal";
import styled from "styled-components";
import { Button } from "../Button";
import { H1, H3 } from "../TextStyles";
import { formatPrice } from "../../utils/misc";
import { Select } from "../Select";
import Text from "../../languages/Text";

export default function OrderModal({
  orderData,
  onClose,
  onSubmit,
  note,
  setNote,
  date,
  setDate,
  isRepresentativeOrder,
  restaurants,
  orderOwnerRestaurant,
  setOrderOwnerRestaurant,
}) {
  const { quantity, cost } = orderData;
  return (
    <Modal onClose={onClose}>
      <ModalHeader>
        <H1>
          <Text vid={"catalogue"} tid={"orderReview"} capitalize />
        </H1>
      </ModalHeader>
      <ModalContent>
        <H3 accent color="var(--blue)">
          <Text vid={"catalogue"} tid={"requestDeliveryDate"} capitalize />
        </H3>
        <ModalInput
          type="date"
          value={date}
          onChange={(e) => {
            setDate(e.target.value);
          }}
        ></ModalInput>
        {isRepresentativeOrder && (
          <>
            <H3 accent color="var(--blue)">
              <Text vid={"catalogue"} tid={"selectRestaurant"} capitalize />
            </H3>
            <Select
              style={{ marginBottom: "2rem" }}
              value={JSON.stringify(orderOwnerRestaurant)}
              onChange={(e) => setOrderOwnerRestaurant(JSON.parse(e.target.value))}
            >
              <option disabled value="{}"></option>
              {restaurants.map((restaurant) => (
                <option key={restaurant.restaurantID} value={JSON.stringify(restaurant)}>
                  {restaurant.restaurantName}
                </option>
              ))}
            </Select>
          </>
        )}
        <H3 accent color="var(--blue)">
          <Text vid={"order"} tid={"addNote"} />
        </H3>
        <ModalTextarea value={note} onChange={(e) => setNote(e.target.value)}></ModalTextarea>
      </ModalContent>
      <ModalFooter>
        <div style={{ color: "var(--blue)" }}>
          <H3 accent style={{ marginBottom: "0.5rem" }}>
            {quantity} <Text vid={"common"} tid={"products"} />
          </H3>
          <H3 accent>€{formatPrice(cost)}</H3>
        </div>
        <Button
          disabled={
            (isRepresentativeOrder && Object.keys(orderOwnerRestaurant).length === 0) ||
            orderData.quantity === 0
          }
          onClick={onSubmit}
        >
          <Text vid={"order"} tid={"placeOrder"} capitalize />
        </Button>
      </ModalFooter>
    </Modal>
  );
}
