import styled from "styled-components";

export const MobileNavBottom = styled.div`
  position: fixed;
  z-index: 1;
  bottom: 0;
  width: 100%;
  height: 5rem;
  display: flex;
  justify-content: space-between;
  background-color: white;
  padding: 0.5rem;
  box-shadow: 0 -1px 2px #000e663d;
  /* box-shadow: 0px 4px 8px #000e663d; */
`;
