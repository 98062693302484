import React, { useState } from "react";
import Modal, { ModalContent, ModalHeader, ModalFooter, ModalInput } from "../../components/Modal";
import { H1, H2, H3, H4 } from "../../components/TextStyles";
import { TextInput } from "../../components/TextInput";
import { Button } from "../../components/Button";
import Text from "../../languages/Text";

export default function ChangePhoneModal({ onClose, updatePhone }) {
  const [phone, setPhone] = useState("");
  const [errors, setErrors] = useState({});

  function findFieldErrors() {
    let tempErrors = {};
    if (phone.length === 0) tempErrors.phone = "Cannot be empty";
    setErrors(tempErrors);
    if (Object.keys(tempErrors).length === 0) return false;
    else return true;
  }

  return (
    <Modal onClose={onClose}>
      <ModalHeader>
        <H1 capitalize>
          <Text vid={"settings"} tid={"changePhone"} />
        </H1>
      </ModalHeader>
      <ModalContent>
        <TextInput
          error={errors.phone}
          label="Enter Phone"
          value={phone}
          onChange={(e) => {
            setPhone(e.target.value);
          }}
          style={{ marginBottom: "1.5rem" }}
        />
      </ModalContent>
      <ModalFooter>
        <Button
          onClick={() => {
            if (!findFieldErrors()) {
              updatePhone(phone);
            }
          }}
        >
          <Text vid={"common"} tid={"confirm"} />
        </Button>
        <Button color="var(--orange)" onClick={onClose}>
          <Text vid={"common"} tid={"cancel"} />
        </Button>
      </ModalFooter>
    </Modal>
  );
}
