import React, { useMemo, useState } from "react";
import { useParams, Link } from "react-router-dom";
import Text from "../../languages/Text";
import { H1 } from "../../components/TextStyles";
import { Icon } from "../../components/Icon";
import { backIcon } from "../../assets/icons";
import useFetch from "../../hooks/useFetch";
import { fetchOrder } from "../../pages/Order/data";
import { Loader } from "../../components/Loader.js";
import OrderInfo from "./OrderInfo";
import OrderTable from "./OrderTable";
import { useAuth } from "../../contexts/AuthContext";
import { Button } from "../../components/Button";
import styled from "styled-components";
import { OrderPDF } from "./orderPDF";
import { PDFDownloadLink } from "@react-pdf/renderer";
import ChangeStatusModal from "./ChangeStatusModal";
import { changeOrderStatus } from "./data";
import { Body, UtilBar, Page, Header } from "../../components/Layout/Layout";

const OrderLayout = styled.div`
  display: flex;
  /* flex-direction: column; */
  /* height: 100%; */
  padding: 2rem 2rem 6rem 2rem;
  /* justify-content: space-between; */

  @media (max-width: 860px) {
    flex-direction: column;
    padding: 1rem;
    padding-bottom: 2rem;
  }
`;

const OrderButtons = styled.div`
  display: flex;
  > :first-child {
    margin-right: 1rem;
  }
  /* @media (max-width: 600px) {
    margin: 2rem 0;
  } */
`;

const Order = () => {
  const { orderID } = useParams();
  const args = useMemo(() => ({ orderID }), [orderID]);
  const { data: orderData, isLoading } = useFetch(fetchOrder, args);
  const { userType } = useAuth();
  const [isChangingStatus, setIsChangingStatus] = useState(false);

  function onChangeStatus({ newStatus, note, date }) {
    let orderData = { orderID, status: newStatus, supplierNote: note };
    if (date) orderData.deliveryDate = date;
    changeOrderStatus(orderID, orderData).then((hm) => window.location.reload());
  }

  return (
    <Page>
      <Header>
        <Link to="/orders">
          <H1 style={{ display: "flex" }}>
            <Icon
              width={"0.75rem"}
              fill={"var(--blue)"}
              style={{ display: "inline-block", marginRight: "1rem" }}
            >
              {backIcon}
            </Icon>
            <Text vid={"common"} tid={"order"} capitalize={true} />
          </H1>
        </Link>

        {/* <H2>#{orderID.substring(0, 5)}</H2> */}
        <OrderButtons>
          {userType === "type-res" && (
            <Link
              // to={`/catalogue/${orderData.supplierID}`}
              to={{
                pathname: `/catalogue/${orderData.supplierID}`,
                state: {
                  order: orderData.products,
                  representativeID: orderData.representativeID,
                  representativeName: orderData.representativeName,
                },
              }}
            >
              <Button>
                <Text vid={"order"} tid={"repeatOrder"} />
              </Button>
            </Link>
          )}
          {!isLoading && (
            <PDFDownloadLink
              document={<OrderPDF order={{ ...orderData, orderID }} />}
              fileName={`Fudurama_Order_${orderID}.pdf`}
            >
              {({ blob, url, loading, error }) => {
                console.log("blob", blob);
                return (
                  <Button>
                    <Text vid={"order"} tid={"downloadPDF"} />
                  </Button>
                );
              }}
            </PDFDownloadLink>
          )}
        </OrderButtons>
      </Header>
      <UtilBar></UtilBar>

      <Body>
        {isLoading ? (
          <Loader />
        ) : (
          <OrderLayout>
            <OrderInfo
              order={{ ...orderData, orderID }}
              openModal={() => setIsChangingStatus(true)}
            />
            <div style={{ width: "2rem", height: "2rem" }}></div>

            <OrderTable
              products={orderData.products}
              cost={orderData.cost}
              quantity={orderData.quantity}
            />
          </OrderLayout>
        )}
        {isChangingStatus && (
          <ChangeStatusModal onClose={() => setIsChangingStatus(false)} onSubmit={onChangeStatus} />
        )}
      </Body>
    </Page>
  );
};

export default Order;
