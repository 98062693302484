import styled from "styled-components";

export const Form = styled.form`
  /* align-self: center; */
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* align-items: center; */
  /* flex: 1; */
  > * {
    margin: 0.8rem 0;
    /* margin: 0.7rem 0; */
  }

  @media (max-width: 600px) {
    width: 85%;
  }

  /* > input[type="text"],
  input[type="password"] {
    border: 1px solid #0000001f;
    border-radius: 4px;
    width: 100%;
    padding: 1rem;
    font-size: 1rem;

  }
  @media (max-width: 600px) {
    width: 80%;
  } */
`;
