import styled from "styled-components";

export const CatalogueCategory = styled.div`
  color: var(--green);
  border: 2px solid var(--green);
  border-radius: 12px;
  text-transform: uppercase;
  font-size: 0.75rem;
  line-height: 0.75rem;
  font-weight: bold;
  padding: 0.1rem 0.5rem;
  width: fit-content;
  height: fit-content;
`;
