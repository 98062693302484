import React from "react";
// import { useProfile } from '../../contexts/ProfileContext';
import { Card, CardHeader, CardContent, CardFooter, CardIconButton } from "../Layout/Card";
import styled from "styled-components";
import { minusIcon, plusIcon, addToCartIcon } from "../../assets/icons";
import { H3, H4 } from "../../components/TextStyles";
import { Icon } from "../../components/Icon";
import { IconButton } from "../../components/IconButton";
import { formatPrice } from "../../utils/misc";

const PromotionDiscount = styled.p`
  border-radius: 1rem;
  padding: 0.25rem 0.5rem;
  line-height: 0.875rem;
  background-color: var(--orange);
  color: white;
  font-weight: 800;
  font-size: 0.875rem;
  width: fit-content;
  height: fit-content;
  position: absolute;
  top: 0.75rem;
  right: 1rem;
`;

const ItemDescription = styled.p`
  /* overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  align-items: center; */
  /* overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical; */
  line-height: 1.25rem;
  height: 2.5rem;
  color: var(--faded-blue);
  /* white-space: nowrap; */
  overflow: hidden;
  text-overflow: ellipsis;
`;

const QuantityButtons = styled.div`
  display: flex;
  align-items: center;
  /* position: absolute;
  bottom: 0.75rem;
  right: 1rem; */
`;

export default function Product({ item, quantity, functions, isInOrder, displayOnly, onClick }) {
  const { ID, id, name, description, price, unit, category, promotion, promotionPrice } = item;
  const { increaseQuantity, decreaseQuantity, addToOrder } = functions;
  // const formattedPrice = formatPrice(price);
  // const formattedPromoPrice = formatPrice(promotionPrice);

  return (
    <Card onClick={onClick} style={{ position: "relative" }}>
      {/* <CardHeader></CardHeader> */}
      {promotion && promotionPrice && (
        <PromotionDiscount>
          -{parseInt(((price - promotionPrice) / price) * 100)}%
        </PromotionDiscount>
      )}

      <CardContent>
        <div>
          <H3
            color="var(--blue)"
            style={{
              width: "100%",
              textOverflow: "ellipsis",
              // whiteSpace: "nowrap",
              overflow: "hidden",
              marginBottom: "0.5rem",
            }}
          >
            {name}
          </H3>

          <ItemDescription>{description}</ItemDescription>
        </div>
      </CardContent>
      {/* {!displayOnly &&
        (isInOrder ? (
          <QuantityButtons>
            <CardIconButton
              onClick={(e) => {
                e.stopPropagation();
                decreaseQuantity(ID);
              }}
            >
              <Icon width="1rem" height="1rem" fill="white">
                {minusIcon}
              </Icon>
            </CardIconButton>
            <p
              style={{
                width: "2rem",
                textAlign: "center",
                fontWeight: "bold",
                fontSize: "1.25rem",
              }}
            >
              {quantity}
            </p>
            <CardIconButton
              onClick={(e) => {
                e.stopPropagation();
                increaseQuantity(ID);
              }}
            >
              <Icon width="1rem" height="1rem" fill="white">
                {plusIcon}
              </Icon>
            </CardIconButton>
          </QuantityButtons>
        ) : (
          <div style={{ position: "absolute", bottom: "0.75rem", right: "1rem" }}>
            <CardIconButton
              onClick={(e) => {
                e.stopPropagation();
                addToOrder(ID);
              }}
            >
              <Icon fill="white" width="1.25rem" height="1.25rem">
                {addToCartIcon}
              </Icon>
            </CardIconButton>
          </div>
        ))} */}
      <CardFooter style={{ height: "2.5rem" }}>
        <div>
          {promotion && promotionPrice ? (
            <H3 style={{ fontWeight: "500" }}>
              <span
                style={{
                  textDecoration: "line-through",
                  textDecorationThickness: "2px",
                  // fontWeight: "normal",
                  marginRight: "0.5rem",
                  color: "var(--orange)",
                }}
              >
                €{formatPrice(price)}
              </span>
              €{formatPrice(promotionPrice)} <span style={{ fontWeight: "400" }}> / {unit}</span>
            </H3>
          ) : (
            <H3 style={{ fontWeight: "500" }}>
              €{formatPrice(price)} <span style={{ fontWeight: "400" }}> / {unit}</span>
            </H3>
          )}
        </div>
        {!displayOnly &&
          (isInOrder ? (
            <QuantityButtons>
              <CardIconButton
                onClick={(e) => {
                  e.stopPropagation();
                  decreaseQuantity(ID);
                }}
              >
                <Icon width="1rem" height="1rem" fill="white">
                  {minusIcon}
                </Icon>
              </CardIconButton>
              <p
                style={{
                  width: "2rem",
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: "1.25rem",
                }}
              >
                {quantity}
              </p>
              <CardIconButton
                onClick={(e) => {
                  e.stopPropagation();
                  increaseQuantity(ID);
                }}
              >
                <Icon width="1rem" height="1rem" fill="white">
                  {plusIcon}
                </Icon>
              </CardIconButton>
            </QuantityButtons>
          ) : (
            <CardIconButton
              onClick={(e) => {
                e.stopPropagation();
                addToOrder(ID);
              }}
            >
              <Icon fill="white" width="1.25rem" height="1.25rem">
                {addToCartIcon}
              </Icon>
            </CardIconButton>
          ))}
      </CardFooter>
    </Card>
  );
}
