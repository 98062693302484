import React, { useState, useEffect } from "react";
import Modal, { ModalHeader, ModalFooter } from "../../../components/Modal";
import styled from "styled-components";
import { Button } from "../../../components/Button";
import { H1 } from "../../../components/TextStyles";
import { TextInput, TextareaInput } from "../../../components/TextInput";
import Text from "../../../languages/Text";

const ModalContent = styled.div`
  display: flex;
  flex-direction: row;
  /* padding: 2rem; */
  width: 100%;
  @media (max-width: 600px) {
    flex-direction: column;
    /* overflow: scroll;
    max-height: 80%; */
  }

  > div {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  /* .left {
    flex-grow: 2;
  }
  .right {
    flex-grow: 1;
  } */
`;

const ModalCenter = styled.div`
  /* padding: 32px; */
  text-align: center;
`;

export default function CatalogueModal({ row, setEditingItemId, setNewItem }) {
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [promotionPrice, setPromotionPrice] = useState("");
  const [unit, setUnit] = useState("");
  const [category, setCategory] = useState("");
  const [promotion, setPromotion] = useState(false);
  const [active, setActive] = useState("");
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (row) setStateValues(row);
  }, [row]);

  function findFieldErrors() {
    let tempErrors = {};
    // if (promotion && promotionPrice && parseFloat(price) < parseFloat(promotionPrice))
    //   tempErrors.promotionPrice = "Cannot be more than regular price";
    // if (isNaN(parseFloat(price))) tempErrors.price = "Invalid number";
    // if (promotion && promotionPrice && isNaN(parseFloat(promotionPrice)))
    //   tempErrors.promotionPrice = "Invalid number";
    if (!isPriceValid(price)) tempErrors.price = "Invalid format";
    if (promotion && promotionPrice && !isPriceValid(promotionPrice))
      tempErrors.promotionPrice = "Invalid format";
    if (id.length === 0) tempErrors.id = "Cannot be empty";
    if (name.length === 0) tempErrors.name = "Cannot be empty";
    if (price.length === 0) tempErrors.price = "Cannot be empty";
    if (unit.length === 0) tempErrors.unit = "Cannot be empty";
    if (category.length === 0) tempErrors.category = "Cannot be empty";
    if (promotion && !promotionPrice) tempErrors.promotionPrice = "Cannot be empty";
    if (promotion && promotionPrice && parseFloat(price) < parseFloat(promotionPrice))
      tempErrors.promotionPrice = "Cannot be more than regular price";

    setErrors(tempErrors);
    if (Object.keys(tempErrors).length === 0) return false;
    else return true;
  }

  function isPriceValid(price) {
    const regex = /^(\d+(?:[\.\,]\d{1,2})?)$/;
    return regex.test(price);
  }

  function formatPrice(price) {
    if (/^es\b/.test(navigator.language) || /^it\b/.test(navigator.language))
      return price.replace(".", ",");
    return price;
    // return parseFloat(price.replace(",", ".")).toLocaleString(navigator.language, {
    //   minimumFractionDigits: 2,
    // });
  }

  function setStateValues(values) {
    setId(values.id);
    setName(values.name);
    setDescription(values.description);
    setPrice(formatPrice(values.price));
    setUnit(values.unit);
    setCategory(values.category);
    setPromotion(values.promotion);
    setActive(values.active);
    if (values.promotion && values.promotionPrice)
      setPromotionPrice(formatPrice(values.promotionPrice));
  }

  return (
    <Modal width="50rem" onClose={() => setEditingItemId(null)}>
      <ModalHeader>
        <H1 capitalize>
          <Text vid={"catalogue"} tid={"editProduct"} />
        </H1>
      </ModalHeader>
      <ModalContent>
        <div>
          <TextInput
            error={errors.id}
            label={Text({ vid: "catalogue", tid: "productID", capitalize: true })}
            value={id}
            onChange={(e) => {
              setId(e.target.value);
            }}
          />

          <TextInput
            error={errors.name}
            label={Text({ vid: "catalogue", tid: "productName", capitalize: true })}
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
          <TextareaInput
            error={errors.description}
            label={Text({ vid: "catalogue", tid: "productDescription", capitalize: true })}
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
          />
        </div>
        <div style={{ width: "2rem" }}></div>
        <div>
          <TextInput
            error={errors.unit}
            label={Text({ vid: "catalogue", tid: "productUnit", capitalize: true })}
            value={unit}
            onChange={(e) => {
              setUnit(e.target.value);
            }}
          />

          <TextInput
            error={errors.category}
            label={Text({ vid: "catalogue", tid: "productCategory", capitalize: true })}
            value={category}
            onChange={(e) => {
              setCategory(e.target.value);
            }}
          />

          <TextInput
            error={errors.price}
            label={Text({ vid: "catalogue", tid: "productPrice", capitalize: true })}
            value={price}
            onChange={(e) => {
              let int = e.target.value.replace(/[^0-9.,]/g, "");
              setPrice(int);
            }}
          />

          <label>
            <input
              type="checkbox"
              checked={promotion}
              onChange={() => setPromotion((prev) => !prev)}
            />{" "}
            <Text vid={"catalogue"} tid={"promotion"} capitalize />
          </label>
          <TextInput
            disabled={!promotion}
            label={Text({ vid: "catalogue", tid: "promotionalPrice", capitalize: true })}
            value={promotionPrice}
            error={errors.promotionPrice}
            onChange={(e) => {
              let int = e.target.value.replace(/[^0-9.,]/g, "");
              setPromotionPrice(int);
            }}
          />
        </div>
      </ModalContent>
      <ModalFooter>
        <Button
          onClick={() => {
            if (!findFieldErrors()) {
              setNewItem({
                id,
                name,
                price: parseFloat(price.replace(",", ".")).toFixed(2),
                unit,
                description,
                active,
                promotion,
                category,
                promotionPrice:
                  promotion && promotionPrice
                    ? parseFloat(promotionPrice.replace(",", ".")).toFixed(2)
                    : null,
              });
            }
          }}
        >
          <Text vid={"common"} tid={"save"} />
        </Button>
        <Button
          color="var(--orange)"
          onClick={() => {
            // setStateValues(row);
            setEditingItemId(null);
          }}
        >
          <Text vid={"common"} tid={"cancel"} />
        </Button>
      </ModalFooter>
    </Modal>
  );
}
